import searchIcon from '@/assets/icon/icon_search.svg';
import vectorIcon from '@/assets/icon/icon_vector.svg';
import { useEffect, useState } from 'react';
import { Button as AntButton } from 'antd';
import { useParseRouterQuery } from '@/hooks/router/useParseRouterQuery';
import { Button } from '@/components/Button';
import { NewCoin } from '@/components/NewCoin';
import { TransparentInput } from '@/components/TransparentInput';
import { Menu } from '../Menu';
import classNames from 'classnames';
import { PopoverMenu } from '../PopoverMenu';
import { useNavigate } from 'react-router-dom';

export function SearchBar({
  inputShow,
  checkedName,
  onUpdateInputShow,
  onHandleClickMenu,
}: {
  inputShow: boolean;
  checkedName: string;
  onUpdateInputShow?: (inputShow: boolean) => void;
  onHandleClickMenu?: (name: string) => void;
}) {
  const { query, updateQuery } = useParseRouterQuery({
    search: '',
  });

  const [searched, setSearched] = useState(false);

  const [search, setSearch] = useState('');

  const handleSearch = () => {
    updateQuery({ search });
    setSearched(true);
  };

  const handleClear = () => {
    updateQuery({ search: '' });
    setSearched(false);
    onUpdateInputShow?.(false);
  };

  useEffect(() => {
    setSearch(query.search);
  }, [query.search]);

  const navigate = useNavigate();

  const tabsList = [{ label: 'ALL', path: '/' }, { label: 'G6 LIST', path: '/g6' }, { label: <PopoverMenu /> }];

  return (
    <div className={classNames({ 'w-full': inputShow })}>
      {!inputShow && (
        <div
          className={classNames('flex items-center lg:gap-x-[8px]', {
            'lg:w-[40px] lg:overflow-hidden': !inputShow,
          })}
        >
          <Button
            onClick={() => {
              onUpdateInputShow?.(true);
            }}
            className="font-extrabold group lg:h-9 lg:!w-9 lg:!min-w-9 lg:!p-[6px]"
            icon={<img src={searchIcon} className="group-hover:brightness-0" />}
            shape="circle"
          ></Button>

          {tabsList.map((item, i) => {
            return (
              <Menu
                key={i}
                className={`lg:hidden h-12 px-[24px] py-[12px] ${i === 1 ? 'border-e-2 border-white/20 ' : ''}`}
                name={item.label}
                checkedName={checkedName}
                onClick={() => {
                  if (typeof item.label === 'string') onHandleClickMenu?.(item.label);
                  if (item.path) {
                    navigate(item.path);
                  }
                }}
              />
            );
          })}

          <NewCoin />
        </div>
      )}

      {inputShow && (
        <TransparentInput
          className="min-w-[348px] lg:min-w-full text-[16px] py-[6px] pr-[19.5px] pl-[18px] lg:py-[1px] lg:pr-[16.5px]"
          value={search}
          placeholder="Token name"
          onChange={(e) => setSearch(e.target.value)}
          suffix={
            searched ? (
              <AntButton
                onClick={() => {
                  handleClear();
                }}
                className="font-ClashDisplay-Semibold text-[16px] p-0"
                type="text"
                style={{ color: `rgba(255,255,255,1)` }}
              >
                CLEAR
              </AntButton>
            ) : (
              <AntButton
                className="font-ClashDisplay-Semibold text-[16px] p-0 gap-[3px]"
                onClick={() => {
                  handleSearch();
                }}
                iconPosition="end"
                icon={<img src={vectorIcon} />}
                type="text"
                style={{ color: `rgba(255,255,255,${!search ? '.45' : '1'})` }}
              >
                SEARCH
              </AntButton>
            )
          }
        />
      )}
    </div>
  );
}
