import { useMemo } from 'react';
import { useWindowResize } from './useWindowResize';

export function useResponsive() {
  const { width } = useWindowResize();

  const isMobile = useMemo(() => {
    return width <= 768;
  }, [width]);

  const isPad = useMemo(() => {
    return width <= 1024;
  }, [width]);

  return {
    isMobile,
    isPad,
  };
}
