import { Address } from '@ton/core';
import { G6Config } from '../G6Config';
import { G6Pool } from '../wrappers/G6Pool';
import { G6Router } from '../wrappers/G6Router';
import { JettonWallet } from '../wrappers/JettonWallet';

export const getG6Router = () => {
  const g6Router = new G6Router(G6Config.G6RouterAddress);

  return g6Router;
};

export const getG6Pool = (poolAddress: Address) => {
  const g6Pool = new G6Pool(poolAddress);

  return g6Pool;
};

export const getJettonWallet = (address: Address) => {
  const jettonWallet = new JettonWallet(address);

  return jettonWallet;
};
