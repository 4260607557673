import request from '@/request';
import { Coin } from '@/types/Coin';
import { User } from '@/types/User';

export function fetchUserInfo() {
  return request.get<User>(`/user`, null);
}

export function register(payload: Partial<User>) {
  return request.post<User>(`/user/register`, payload);
}

export function updateInfo(payload: Omit<Partial<User>, 'address'>) {
  return request.put<User>(`/user`, payload);
}

export function updateChatId(payload: { tgChatId: string }) {
  return request.put<User>(`/user/chatId`, payload);
}

export function fetchUserInfoByAddress(address: string) {
  return request.get<User>(`/user/${address}`, null);
}

export function fetchUserCreateCoins(address: string) {
  return request.get<{ docs: Coin[] }>(`/coin/user-created-coins/${address}`, null);
}

export function fetchUserHeldCoins(address: string) {
  return request.get<{ docs: Coin[] }>(`/coin/user-held-coins/${address}`, null);
}
