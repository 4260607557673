import { Button } from '@/components/Button';
import unionIcon from '../../assets/icon/icon-union.svg';
import { Dropdown, MenuProps } from 'antd';
import { HowToWork } from '../HowToWork';
import { useMemo } from 'react';
import { NewCoin } from '../NewCoin';
import { useResponsive } from '@/hooks/useResponsive';

export function AppDropdown() {
  const { isMobile } = useResponsive();

  const items: MenuProps['items'] = useMemo(() => {
    let list = [
      {
        key: 'work',
        label: (
          <HowToWork key="work">
            <div>How it works</div>
          </HowToWork>
        ),
      },
    ];

    if (isMobile) {
      list = [
        {
          key: 'coin',
          label: (
            <NewCoin key="coin">
              <div>Start a new coin</div>
            </NewCoin>
          ),
        },
        ...list,
      ];
    }

    list = [
      ...list,
      {
        key: 'TG',
        label: (
          <a href="https://t.me/G6Club_TON" target="_blank" rel="noreferrer">
            Telegram
          </a>
        ),
      },
      {
        key: 'TWITTER',
        label: (
          <a href="https://x.com/G6onTON" target="_blank" rel="noreferrer">
            Twitter
          </a>
        ),
      },
    ];

    return list;
  }, [isMobile]);

  return (
    <Dropdown
      overlayClassName="g6-app-dropdown"
      menu={{ items }}
      placement="bottomRight"
      overlayStyle={isMobile ? { top: '90px' } : { top: '112px' }}
    >
      <Button
        shape="circle"
        className="group md:!min-w-9 md:!w-9 md:h-9"
        icon={<img src={unionIcon} className="group-hover:brightness-0 md:w-4" />}
      ></Button>
    </Dropdown>
  );
}
