import classNames from 'classnames';
import ellipseIcon from '@/assets/icon/icon_ellipse.svg';

export function Menu({ ...props }: { onClick: () => void; className?: string; name?: any; checkedName?: string }) {
  return (
    <div className={classNames(props.className)} onClick={props.onClick}>
      <div
        className={`text-[18px] cursor-pointer pb-[3.5px] font-[ClashDisplay-Semibold] lg:text-[15px] lg:underline-offset-[6px] whitespace-nowrap flex items-center w-max hover:opacity-100 group ${props.name == props.checkedName && 'border-b-[3px] font-semibold'}`}
      >
        {props.name === 'G6 LIST' && <img src={ellipseIcon} className="mr-[6px]" />}
        <div className={`group-hover:opacity-100 ${props.name !== props.checkedName ? 'opacity-50' : 'opacity-100'}`}>
          {props.name}
        </div>
      </div>
    </div>
  );
}
