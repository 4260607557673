import { toNano } from '@ton/ton';
import { pTON } from '@ston-fi/sdk';
import { useStonFiRouter } from './useStonFiRouter';
import { useWallet } from '../useWallet';
import { getRandomBigInt } from '@/utils';
import { useRequest } from 'ahooks';

export function useSwapJettonToTon({
  offerJettonAddress,
  offerAmount,
  minAskAmount,
}: {
  offerJettonAddress?: string;
  offerAmount: string;
  minAskAmount: string;
}) {
  const { router } = useStonFiRouter();

  const { address, tonConnectUI } = useWallet();

  const getSwapJettonToTonTxParams = async () => {
    const txParams = await router.getSwapJettonToTonTxParams({
      userWalletAddress: address,
      offerJettonAddress: offerJettonAddress!,
      offerAmount: toNano(offerAmount),
      proxyTon: new pTON.v1(),
      minAskAmount,
      queryId: getRandomBigInt(),
    });

    return txParams;
  };

  const hooks = useRequest(
    async () => {
      const txParams = await getSwapJettonToTonTxParams();

      const res = await tonConnectUI.sendTransaction({
        validUntil: Date.now() + 1000000,
        messages: [
          {
            address: txParams.to.toString(),
            amount: txParams.value.toString(),
            payload: txParams.body?.toBoc().toString('base64'),
          },
        ],
      });

      return res;
    },
    { manual: true },
  );

  return {
    ...hooks,
  };
}
