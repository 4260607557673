import { ApiTXResult, fetchTXResult } from '@/service/trade';
import { useRequest } from 'ahooks';

export function useFetchTXResult({
  queryId,
  type,
  onSuccess,
  onFail,
}: {
  queryId?: string;
  type: 'buy' | 'sell';
  onSuccess?: (res: ApiTXResult) => void;
  onFail?: (res: ApiTXResult) => void;
}) {
  const hook = useRequest(
    () => {
      return fetchTXResult(queryId!, type);
    },
    {
      pollingInterval: 1000,
      onSuccess(res) {
        if (res.data.status !== 'not_found') {
          hook.cancel();
        }

        if (res.data.status === 'success') {
          onSuccess?.(res.data);
        }

        if (res.data.status === 'failed') {
          onFail?.(res.data);
        }
      },
      ready: !!queryId,
      refreshDeps: [queryId],
    },
  );

  return hook;
}
