import { Comment } from '@/types/Comment';
import { useRequest } from 'ahooks';
import { useAppMessage } from '@/hooks/useAppMessage';
import { commitComments } from '@/service/comments';

export function useCommitComments({ onCommitSuccess }: { onCommitSuccess?: (data: Comment) => void }) {
  const message = useAppMessage();

  const { run, runAsync, loading } = useRequest(
    (data: Partial<Comment>) => {
      return commitComments(data);
    },
    {
      manual: true,
      onSuccess(data) {
        onCommitSuccess?.(data.data);
      },
      onError(e) {
        console.log(e);

        message.error(e.message || 'Failed to commit');
      },
    },
  );

  return {
    run,
    runAsync,
    loading,
  };
}
