import { defaultConfig, getSymbolConfig, SymbolConf } from './config';
import request from '@/request';
import { IBasicDataFeed, IDatafeedQuotesApi } from 'public/charting_library/charting_library';
import { event } from '@/utils/event';
import { TradeMessage } from '@/types/Message';

export type KlineData = {
  open: number;
  high: number;
  low: number;
  close: number;
  time: number;
};

let lastBar: KlineData | null = null;

function parseValue(item: KlineData) {
  return {
    ...item,
  } as KlineData;
}

export const dataFeed = (payload: SymbolConf): IBasicDataFeed | (IBasicDataFeed & IDatafeedQuotesApi) => {
  return {
    onReady: (callback) => {
      console.log('[onReady]: Method call');
      setTimeout(() => callback(defaultConfig));
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    searchSymbols: (userInput, exchange, symbolType, onResultReadyCallback) => {},
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    resolveSymbol: (symbolName, onSymbolResolvedCallback, onResolveErrorCallback, extension) => {
      onSymbolResolvedCallback(getSymbolConfig(payload));
    },
    getBars: async (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) => {
      try {
        const kline = await request.get<KlineData[]>(`/trade/kline/${symbolInfo.ticker}`, {
          resolution: resolution,
          from: periodParams.from,
          to: periodParams.to,
          countBack: periodParams.countBack,
        });

        const klineData = kline.data.map((item: KlineData) => {
          return parseValue(item);
        });

        if (isAllKlineEmpty(klineData)) {
          throw new Error('No data');
        }

        lastBar = klineData[klineData.length - 1];

        if (klineData.length) {
          klineData.push({
            ...klineData[klineData.length - 1],
            time: +new Date(),
          });
        }

        onHistoryCallback(klineData);
      } catch (error) {
        onHistoryCallback([], {
          noData: true,
        });
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    subscribeBars: (symbolInfo, resolution, onRealtimeCallback, subscribeUID, onResetCacheNeededCallback) => {
      event.onTrade((message: TradeMessage) => {
        const tradeData = message.data;

        if (!tradeData?.jettonSold) return;

        const afterPrice = tradeData.jettonSold * 1;

        handleRealtimeUpdate(
          {
            t: tradeData.timestamp,
            p: afterPrice,
            v: tradeData.jettonAmount,
          },
          onRealtimeCallback,
        );
      });
    },
    unsubscribeBars: (subscriberUID) => {
      console.log('[unsubscribeBars]: Method call with subscriberUID:', subscriberUID);
    },
  };
};

function isKlineEmpty(kline: KlineData) {
  return kline.open === 0 && kline.high === 0 && kline.low === 0 && kline.close === 0;
}

function isAllKlineEmpty(kLines: KlineData[]) {
  return kLines.every(isKlineEmpty);
}

function handleRealtimeUpdate(data: { t: number; p: number; v: number }, onRealtimeCallback: (bar: KlineData) => void) {
  const newBarTime = data.t * 1000;

  if (lastBar && newBarTime <= lastBar.time) {
    console.warn('Time violation: new data time is less than or equal to last bar time');
    return;
  }

  const bar: KlineData = parseValue({
    time: newBarTime,
    open: data.p,
    high: data.p,
    low: data.p,
    close: data.p,
  });

  console.log('Received real-time data:', data);

  if (!lastBar || bar.time > lastBar.time) {
    console.log('New bar created:', bar);

    lastBar = bar;
    onRealtimeCallback(lastBar);
  } else {
    lastBar.high = Math.max(lastBar.high, bar.high);
    lastBar.low = Math.min(lastBar.low, bar.low);
    lastBar.close = bar.close;

    console.log('Updated last bar:', lastBar);
    onRealtimeCallback(lastBar);
  }
}
