import { useResponsive } from '@/hooks/useResponsive';
import { AppDropdown } from '@/components/AppDropdown';
import { ConnectButton } from '@/components/ConnectButton';
import { ConfigProvider, Divider } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
// import { useUserStore } from '@/stores/useUserStore';
// import { Button } from '../Base/Button';
import { SearchBar } from './SearchBar';
import { Menu } from './Menu';
import { PopoverMenu } from './PopoverMenu';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import vectorLeftIcon from '@/assets/icon/icon_vector_left.svg';
import { AudioButton } from '@/components/AudioButton';

export default function Header({ style, className }: { style?: React.CSSProperties; className?: string }) {
  const navigate = useNavigate();
  // const { setProfileModalOpen } = useUserStore();

  const { pathname } = useLocation();

  const [inputShow, setInputShow] = useState(false);

  const { isMobile: isMobileMode, isPad } = useResponsive();

  const isMobile = useMemo(() => {
    return isMobileMode || isPad;
  }, [isMobileMode, isPad]);

  const buttonShow = useMemo(() => {
    if (isMobile && inputShow) {
      return false;
    }

    return true;
  }, [inputShow, isMobile]);

  const tabsList = [{ label: 'ALL' }, { label: 'G6 LIST' }];

  const location = useLocation();

  const pathToName = useMemo(() => {
    if (location.pathname === '/g6') {
      return 'G6 LIST';
    } else {
      return 'ALL';
    }
  }, [location.pathname]);

  const [checkedName, setCheckedName] = useState('ALL');

  useEffect(() => {
    setCheckedName(pathToName);
  }, [pathToName]);

  const handleClickMenu = (name: string) => {
    setCheckedName(name);
    if (name === 'ALL') navigate('/');
    else if (name === 'G6 LIST') navigate('/g6');
  };

  const isBackMode = useMemo(() => {
    return pathname.includes('/profile/') || pathname.startsWith('/coin/');
  }, [pathname]);

  return (
    <ConfigProvider
      theme={{
        components: {
          Input: isMobile
            ? {
                controlHeight: 34,
              }
            : undefined,
        },
        token: isMobile
          ? {
              controlHeight: 36,
            }
          : undefined,
      }}
    >
      <div className={classNames(className)}>
        <div
          className={classNames(
            'flex justify-between items-center px-[90px] py-[24px] lg:px-[12px] lg:py-[9px] lg:h-[60px]',
          )}
          style={style}
        >
          <div
            className={classNames('flex items-center gap-x-[24px] lg:gap-x-[9px]', {
              'lg:w-full': inputShow,
            })}
          >
            <NavLink to="/" className="shrink-0">
              <img src="/static/images/logo.svg" className="w-[45px] lg:w-[37px] shrink-0" />
            </NavLink>

            {isBackMode && (
              <NavLink
                to="/"
                className="lg:hidden flex items-center text-[18px] font-semibold font-ClashDisplay-Semibold hover:text-white"
              >
                <img src={vectorLeftIcon} width={12} className="mx-[6px] group-hover:brightness-0" />
                <span>GO BACK</span>
              </NavLink>
            )}

            {!isBackMode && (
              <SearchBar
                inputShow={inputShow}
                checkedName={checkedName}
                onUpdateInputShow={setInputShow}
                onHandleClickMenu={handleClickMenu}
              ></SearchBar>
            )}
          </div>

          <div
            className={classNames('flex justify-end items-center gap-x-[9px]', {
              hidden: !buttonShow,
            })}
          >
            <ConnectButton></ConnectButton>

            <AudioButton />

            <AppDropdown />
          </div>
        </div>
        <div className="px-[12px] hidden lg:block" style={style}>
          <Divider className="h-0.5 m-0 bg-white/10 rounded-xl" />
          {!isBackMode && (
            <div className="flex justify-between items-center">
              <div className="flex items-center gap-[24px] gap-x-[15px] pt-[18px] pb-[19px] lg:pb-[8px]">
                {tabsList.map((item, i) => {
                  return (
                    <Menu
                      key={i}
                      name={item.label}
                      checkedName={checkedName}
                      onClick={() => handleClickMenu(item.label)}
                    />
                  );
                })}
              </div>
              <PopoverMenu key={pathname} />
            </div>
          )}

          {isBackMode && (
            <NavLink
              to="/"
              className="flex items-center text-[15px] font-semibold font-ClashDisplay-Semibold cursor-pointer py-[18px] hover:text-white"
            >
              <img src={vectorLeftIcon} width={12} className="mx-[6px] group-hover:brightness-0" />
              <span>GO BACK</span>
            </NavLink>
          )}
        </div>
      </div>
    </ConfigProvider>
  );
}

/* <div className="group flex justify-between items-center cursor-pointer">
          <img src={crownIcon} className="w-[19px] h-[18px] mr-[5px]" />
          <div className="text-lg font-semibold text-[#ffffff50] group-hover:text-white">G6 LIST</div>
        </div> */
