import { Button } from '@/components/Button';
import { Input, ConfigProvider } from 'antd';
import { InputTokenType, useG6PoolMethods } from '@/contract/hooks/useG6PoolMethods';
import { useContext, useMemo, useState } from 'react';
import { fromNano, toNano } from '@ton/core';
import { useRequest, useUnmount } from 'ahooks';
import { useAppMessage } from '@/hooks/useAppMessage';
import { TonValue } from '@/components/TonValue';
import { CoinDetailContext } from '../../context/CoinDetailContext';
import { useWallet } from '@/hooks/useWallet';
import { useCalcSellAmount } from '../../hooks/useCalcSellAmount';
import { G6Math } from '@/contract/utils/Math';
import classNames from 'classnames';
import { ConnectButton } from '@/components/ConnectButton';
import { PoolComplete } from './PoolComplete';
import { useFetchTXResult } from '../../hooks/useFetchTXResult';
import { GlobalDataContext } from '@/context/GlobalDataContext';

export function SymbolSuffix({ icon, symbol }: { icon?: string; symbol?: string }) {
  return (
    <div className="inline-flex justify-start items-center gap-x-[5px] min-w-[60px] relative bottom-[-1px]">
      <div className="w-[24px]">
        <img className="w-[24px] h-[24px] rounded-full" src={icon} />
      </div>
      <span className="text-[15px] font-[ClashDisplay-Semibold] font-semibold max-w-[200px] text-ellipsis overflow-hidden">
        {symbol}
      </span>
    </div>
  );
}

const loadingKey = 'LOADING';

export function Sell() {
  const { handleSell, getJettonBalance } = useG6PoolMethods();

  const { isConnect } = useWallet();

  const { detail, isGraduated } = useContext(CoinDetailContext);

  const [count, setCount] = useState('');

  const {
    data: balance = toNano(0),
    loading: balanceLoading,
    refresh: refreshBalance,
  } = useRequest(
    async () => {
      return getJettonBalance(detail!.poolAddress).then((balance) => {
        return balance ? balance : toNano(0);
      });
    },
    {
      ready: isConnect && !!detail?.poolAddress,
      onSuccess(res) {
        console.log('getJettonBalance------->', res);
      },
    },
  );

  const handlePut = (val: any) => {
    if (isGraduated) return;

    const value = new G6Math(balance).multiply(val);

    setCount(fromNano(value.toNumber().toFixed(0)));
  };

  const message = useAppMessage();

  const [queryId, setQueryId] = useState<string | undefined>();

  const { refreshBalanceAsync } = useContext(GlobalDataContext);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCount(e.target.value);
  };

  const onBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/[^\d.]/g, '');

    setCount(value);
  };

  const { data } = useFetchTXResult({
    queryId,
    type: 'sell',
    onSuccess() {
      message.success('Sell success');

      refreshBalanceAsync?.().catch(() => null);

      setQueryId(undefined);

      message.messageInstance.destroy(loadingKey);
    },
    onFail() {
      message.error('Sell failed');

      message.messageInstance.destroy(loadingKey);
    },
  });

  const resultLoading = useMemo(() => {
    return data?.data.status === 'not_found';
  }, [data?.data?.status]);

  const { run: runSell, loading: sellLoading } = useRequest(
    () => {
      const amount = new G6Math(count).subtract('0.0000001');

      if (new G6Math(amount).isGreaterThan(fromNano(balance) || 0n)) {
        return Promise.reject(new Error('Insufficient balance'));
      }

      return handleSell(toNano(amount.toString()), InputTokenType.ByJetton);
    },
    {
      manual: true,
      onSuccess(res) {
        message.success('Transaction sent successfully');

        refreshBalance();

        setCount('');

        setQueryId(res?.queryId.toString());

        message.messageInstance.open({
          type: 'loading',
          key: loadingKey,
          content: 'Waiting for sell result',
          duration: 1000,
        });
      },
      onError(err) {
        message.error(err.message || 'Transaction sent failed');
      },
    },
  );

  const { displayValue: tonAmount } = useCalcSellAmount({
    poolAddress: detail?.poolAddress,
    amount: toNano(count),
    ready: !isGraduated && !!+count,
  });

  useUnmount(() => {
    message.messageInstance.destroy(loadingKey);
  });

  return (
    <ConfigProvider
      wave={{ disabled: true }}
      theme={{
        components: {
          Input: {
            colorBgContainer: '#fff',
            activeBg: '#fff',
            addonBg: '#fff',
            activeBorderColor: '#ffffff00',
            colorSplit: '#ffffff00',
            colorBorder: '#ffffff00',
            hoverBorderColor: '#ffffff00',
            colorText: '#000',
            borderRadius: 12,
            controlHeight: 48,
            fontSize: 16,
          },
        },
      }}
    >
      <div>
        <Input
          className="w-full mt-[18px]"
          readOnly={isGraduated}
          addonAfter={<SymbolSuffix symbol={detail?.symbol} icon={detail?.imageUri} />}
          defaultValue={count}
          value={count}
          onBlur={onBlur}
          onChange={handleChange}
        />

        <div className={classNames('flex items-center mt-3 pl-3')}>
          <ul className="flex items-center text-[15px] leading-[15px] font-semibold gap-[18px] font-[ClashDisplay-Semibold]">
            <li
              className={classNames(isGraduated ? 'cursor-not-allowed' : 'cursor-pointer')}
              onClick={() => {
                handlePut(0.25);
              }}
            >
              25%
            </li>
            <li
              className={classNames(isGraduated ? 'cursor-not-allowed' : 'cursor-pointer')}
              onClick={() => {
                handlePut(0.5);
              }}
            >
              50%
            </li>
            <li
              className={classNames(isGraduated ? 'cursor-not-allowed' : 'cursor-pointer')}
              onClick={() => {
                handlePut(0.75);
              }}
            >
              75%
            </li>
            <li
              className={classNames(isGraduated ? 'cursor-not-allowed' : 'cursor-pointer')}
              onClick={() => {
                handlePut(1);
              }}
            >
              MAX
            </li>
          </ul>

          <div className="text-[14px] font-normal text-[#ffffff50] ml-[6px]">
            <TonValue value={balance} loading={balanceLoading} suffix={detail?.symbol}></TonValue>
          </div>
        </div>

        {!isGraduated && (
          <ConnectButton className="w-full mt-[18px] text-[15px] font-[ClashDisplay-Semibold]">
            <Button
              disabled={isGraduated || !+count}
              loading={sellLoading || resultLoading}
              className="w-full mt-[18px] text-[15px] font-[ClashDisplay-Semibold]"
              onClick={() => {
                runSell();
              }}
            >
              {isGraduated ? 'Complete' : 'PLACE TRADE'}
            </Button>
          </ConnectButton>
        )}

        <PoolComplete />

        {!!+tonAmount && <div className="my-[10px]  pl-3">{tonAmount} TON</div>}
      </div>
    </ConfigProvider>
  );
}
