import { fetchUserHeldCoins } from '@/service/user';
import { useRequest } from 'ahooks';

export function useFetchUserHeldCoins(address: any) {
  const hook = useRequest(
    () => {
      return fetchUserHeldCoins(address);
    },
    {
      debounceWait: 500,
    },
  );

  return hook;
}
