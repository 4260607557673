import { Spin } from 'antd';

export function Loading({
  children,
  loading,
  className,
}: {
  children?: React.ReactNode;
  loading?: boolean;
  className?: string;
}) {
  return (
    <Spin className={className} spinning={loading}>
      {children}
    </Spin>
  );
}
