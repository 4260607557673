import { BrowserRouter, Navigate, Route, Routes as ReactRoutes } from 'react-router-dom';
import Home from '@/views/Home';
import Profile from '@/views/Profile';
import G6List from '@/views/G6List';
import Coin from '@/views/Coin';
import { Layout } from '@/components/Layout';

const Routes = () => {
  return (
    <>
      <BrowserRouter>
        <ReactRoutes>
          <Route path="/" element={<Layout />}>
            <Route path="" element={<Home />} />
            <Route path="/profile/:address" element={<Profile />} />
            <Route path="/g6" element={<G6List />} />
            <Route path="/coin/:address" element={<Coin />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Route>
        </ReactRoutes>
      </BrowserRouter>
    </>
  );
};

export default Routes;
