import { Button } from '@/components/Button';
import { useLocalStorageState, useMount } from 'ahooks';
import { useGlobalAudioPlayer } from 'react-use-audio-player';
import PlayIcon from '../../assets/icon/play.svg';
import PauseIcon from '../../assets/icon/pause.svg';

export function AudioButton() {
  const { load, pause, play } = useGlobalAudioPlayer();

  const [playing, setPlaying] = useLocalStorageState<boolean>('AudioButtonState', {
    defaultValue: false,
  });

  useMount(() => {
    load('/music.mp3', {
      autoplay: playing,
      loop: true,
      onpause() {
        setPlaying(false);
      },
      onplay() {
        setPlaying(true);
      },
    });
  });

  const toggle = () => {
    if (playing) {
      pause();
    } else {
      play();
    }
  };

  return (
    <Button
      colorPrimaryHover="#000"
      shape="circle"
      className="group md:!min-w-9 md:!w-9 md:h-9"
      onClick={() => {
        toggle();
      }}
      icon={<img src={playing ? PlayIcon : PauseIcon}></img>}
    ></Button>
  );
}
