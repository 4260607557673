import { useRegister } from '@/hooks/app/user/userRegister';
import { useUpdateUserInfo } from '@/hooks/app/user/useUpdateUserInfo';
import { useWallet } from '@/hooks/useWallet';
import { User } from '@/types/User';
import { Button } from '@/components/Button';
import { GreyButton } from '@/components/GreyButton';
import { Modal } from '@/components/Modal';
import { useUserStore } from '@/stores/useUserStore';
import { useRequest } from 'ahooks';
import { Form, Input } from 'antd';
import { cloneDeep } from 'lodash';
import { useEffect } from 'react';
import { FormTheme } from '../Theme/FormTheme';

type FieldType = User;

function getQueryBySearch(key: string) {
  const search = new URLSearchParams(window.location.search);

  return search.get(key);
}

export function ProfileModal({
  open,
  onSave,
  showDisconnect = true,
}: {
  open: boolean;
  onSave?: () => void;
  showDisconnect?: boolean;
}) {
  const [form] = Form.useForm();

  const { info, setProfileModalOpen, setInfo } = useUserStore();

  const { address, disconnect } = useWallet();

  const { runAsync: runRegister } = useRegister({
    onRegisterSuccess(data) {
      setInfo(data);
      onSave?.();
    },
  });

  const { runAsync: runUpdate } = useUpdateUserInfo({
    onUpdateSuccess(data) {
      setInfo(data);
      onSave?.();
    },
  });

  const { run, loading } = useRequest(
    () => {
      const data = cloneDeep(form.getFieldsValue());

      const tgChatId = getQueryBySearch('tgChatId');

      if (tgChatId) {
        Object.assign(data, { tgChatId });
      }

      if (info) {
        return runUpdate({ _id: info?._id, ...data });
      }

      return runRegister({ ...data, address: address });
    },
    {
      manual: true,
      onSuccess() {
        setProfileModalOpen(false);
      },
    },
  );

  useEffect(() => {
    form.setFieldsValue(info || {});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, info]);

  function runSave() {
    form
      .validateFields()
      .then(() => {
        run();
      })
      .catch(() => null);
  }

  return (
    <Modal
      open={open}
      footer={null}
      title="Edit Profile"
      width={619}
      closable={true}
      centered
      getContainer={false}
      onCancel={() => {
        setProfileModalOpen(false);
      }}
    >
      <FormTheme>
        <Form form={form} autoComplete="off" layout="vertical" className="mt-[27px]">
          <Form.Item<FieldType>
            label="Nickname"
            name="name"
            rules={[{ required: true, message: 'Please input your username!' }]}
          >
            <Input showCount maxLength={50} />
          </Form.Item>

          <Form.Item<FieldType> label="Bio" name="bio" rules={[{ required: true, message: 'Please input your bio!' }]}>
            <Input.TextArea
              style={{
                resize: 'none',
              }}
              showCount
              maxLength={50}
            />
          </Form.Item>
        </Form>

        <div className="w-full flex justify-end items-center gap-x-[20px] mt-[10px]">
          {showDisconnect ? (
            <GreyButton
              block
              disabled={loading || !info}
              onClick={() => {
                disconnect();
                setProfileModalOpen(false);
              }}
            >
              Disconnect
            </GreyButton>
          ) : (
            <GreyButton
              block
              disabled={loading || !info}
              onClick={() => {
                setProfileModalOpen(false);
              }}
            >
              Close
            </GreyButton>
          )}

          <Button
            block
            type="primary"
            loading={loading}
            onClick={() => {
              runSave();
            }}
          >
            Save
          </Button>
        </div>
      </FormTheme>
    </Modal>
  );
}
