import { Button } from '@/components/Button';
import { Modal } from '@/components/Modal';
import { useState } from 'react';

const steps = [
  'Choose a coin that appeals to you',
  'Purchase the coin on the bonding curve ',
  'Sell at any time to secure your profits',
  'You can also create your own coin!',
  'When enough people buy on the bonding curve it reaches a market cap of 10K Ton ',
  '3333 Ton of liquidity is then deposited in ston.fi and burned ',
];

export function HowToWork({ children }: { children: React.ReactNode }) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <div
        className="w-full"
        onClick={() => {
          setOpen(true);
        }}
      >
        {children}{' '}
      </div>
      <Modal title="How It Works" width={490} open={open} footer={null} onCancel={() => setOpen(false)}>
        <div className="mt-[23px] md:mt-[24px]">
          {steps.map((item, i) => {
            return (
              <div key={item} className={`mb-[${i === steps.length - 1 ? '30' : '18'}px]`}>
                <div className="font-Inter text-[16px] leading-[16px] opacity-50">Step {i + 1}</div>
                <div className="font-ClashDisplay-Semibold text-[20px] leading-[20px] mt-[6px] font-semibold">
                  {item}
                </div>
              </div>
            );
          })}

          <div className="font-ClashDisplay-Semibold text-[20px] leading-[20px]">
            Every coin on G6 has a fair launch, with no presale or team allocation.
          </div>
        </div>

        <Button
          className="min-w-[300px] mt-[39px] font-ClashDisplay-Semibold md:mt-[30px] md:w-[100%]"
          onClick={() => setOpen(false)}
        >
          I&apos;M READY TO G6
        </Button>
      </Modal>
    </>
  );
}
