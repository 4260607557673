import { getRandomBigInt } from '@/utils';
import { Address, beginCell } from '@ton/core';
import { G6Pool, Opcodes } from '../wrappers/G6Pool';
import { JettonWallet } from '../wrappers/JettonWallet';

type Props = {
  jettonAmount: bigint;
  poolAddress: Address;
  tonReceiver: Address;
  jettonSoldable: bigint;
  sellForwardTxFee: bigint;
  message?: string;
  queryId: bigint;
};

export function getSellParam(props: Props) {
  const { jettonAmount, poolAddress, tonReceiver, jettonSoldable, sellForwardTxFee } = props;

  const param = {
    jetton_amount: jettonAmount, //jetton_amount,
    to: poolAddress, //toAddress,
    responseAddress: tonReceiver, //responseAddress,
    customPayload: null, //customPayload,
    forward_ton_amount: sellForwardTxFee, //forward_ton_amount,
    forwardPayload: G6Pool.sellTransferForwardMessage({
      tonReceiver: tonReceiver,
      jettonRefundReceiver: tonReceiver,
      expectedJettonSoldableAmount: jettonSoldable, //force fine
      slippageInPercent: 1n,
      message: props.message || '',
    }), //forwardPayload  bob是单独收到ton的人
    queryId: props.queryId,
  };

  const body = JettonWallet.transferMessage(
    param.jetton_amount,
    param.to,
    param.responseAddress,
    param.customPayload,
    param.forward_ton_amount,
    param.forwardPayload,
    param.queryId,
  );

  return { body };
}
