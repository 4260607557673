import { Coin } from '@/types/Coin';
import { Button, List } from 'antd';
import { useFetchCoinList } from '../hooks/useFetchCoinList';
import { useContext, useEffect, useMemo, useState } from 'react';
import { WebSocketContext } from '@/context/WebSocketContext';
import { useFilter } from '@/hooks/useFilter';
import { Item } from '@/components/CoinCard';
import { useFetchG6 } from '../hooks/useFetchG6';
import { take, uniqBy } from 'lodash';
import classNames from 'classnames';

export function CoinList() {
  const { query } = useFilter();

  const { data, loading, loadMore } = useFetchCoinList({
    createdTimestampStart: +query.createdTimestampStart,
    search: query.search,
    order: query.order,
    sortBy: query.sortBy,
    size: +query.size,
  });

  const [socketData, setSocketData] = useState<Coin[]>([]);

  const { data: g6Item } = useFetchG6();

  const { onMessage } = useContext(WebSocketContext);

  const list = useMemo(() => {
    const val = uniqBy([...socketData, ...(data?.list || [])], 'poolAddress');

    if (g6Item) {
      val.unshift(g6Item);
    }

    return take(val, 60);
  }, [g6Item, socketData, data?.list]);

  useEffect(() => {
    const event = onMessage?.((e) => {
      if (e.type === 'launch') {
        e.data._id = `${+new Date()}`;

        setSocketData((prev) => {
          return [e.data, ...prev];
        });
      }
    });

    return () => {
      event?.();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // data?.data.hasNextPage

  const LoadMore = useMemo(() => {
    return (
      <div
        className={classNames('w-full flex justify-center items-center my-[20px]', {
          '!hidden': !data?.totalPages || data?.totalPages === 1 || (!data?.list.length && loading),
        })}
      >
        <Button
          loading={loading}
          disabled={!data?.hasNextPage}
          type="text"
          onClick={() => {
            loadMore();
          }}
        >
          <span className="text-white">{data?.hasNextPage ? ' Load more' : 'No more data'}</span>
        </Button>
      </div>
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.hasNextPage, data?.list.length, data?.totalPages, loading]);

  return (
    <div className="px-[90px] md:px-[12px] h-full">
      <List
        className="coins"
        loading={loading}
        dataSource={list}
        grid={{
          gutter: 20,
          xs: 2,
          sm: 2,
          md: 2,
          lg: 3,
          xl: 4,
          xxl: 4,
        }}
        loadMore={LoadMore}
        renderItem={(item) => {
          return (
            <List.Item className="h-full">
              <Item item={item} />
            </List.Item>
          );
        }}
      ></List>
    </div>
  );
}
