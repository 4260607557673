import { SlippageModal } from '@/components/SlippageModal';
import { TonValue } from '@/components/TonValue';
import { SettingOutlined, WalletOutlined } from '@ant-design/icons';
import { Button as AntButton } from 'antd';

export function ControlBar({
  balance,
  balanceLoading,
  suffix,
}: {
  balance: bigint;
  balanceLoading: boolean;
  suffix?: string;
}) {
  return (
    <div className="flex justify-between items-center w-full px-3">
      <div className="text-[14px] font-normal text-[#ffffff50]">
        <WalletOutlined className="mr-[3px]" />
        <TonValue value={balance} loading={balanceLoading} suffix={suffix}></TonValue>
      </div>

      <SlippageModal>
        <AntButton className="text-[#ffffff50]" type="text" icon={<SettingOutlined />}></AntButton>
      </SlippageModal>
    </div>
  );
}
