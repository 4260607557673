import { Tabs as AntTabs, ConfigProvider } from 'antd';
import { Divider } from 'antd';
import classNames from 'classnames';

export function Tabs({
  ...props
}: {
  className?: string;
  items: any[];
  activeKey: string;
  onChange: (key: string) => void;
  isMobile?: boolean;
  children?: any;
}) {
  return (
    <div
      className={classNames(props.className)}
      style={
        {
          '-webkit-backdrop-filter': `blur(${blur}px)`,
          backdropFilter: `blur(${blur}px)`,
        } as any
      }
    >
      {props.isMobile && (
        <Divider
          style={{
            borderTopColor: 'rgba(255, 255, 255, 0.1)',
            borderTopWidth: '2px',
            margin: '0 -12px',
            width: 'auto',
          }}
        />
      )}

      {props.children}

      <ConfigProvider
        wave={{ disabled: true }}
        theme={{
          components: {
            Tabs: {
              inkBarColor: '#fff',
              itemActiveColor: '#fff',
              itemSelectedColor: '#fff',
              itemHoverColor: '#fff',
              titleFontSize: 15,
              itemColor: 'rgba(225, 225, 225, 0.5)',
              lineWidth: 0,
              horizontalItemPadding: '18px 0 2px 0',
              fontFamily: 'ClashDisplay-Semibold',
              colorBgContainer: '#000',
            },
          },
        }}
      >
        <AntTabs
          items={props.items}
          activeKey={props.activeKey}
          className={props.className}
          onChange={props.onChange}
        ></AntTabs>
      </ConfigProvider>
    </div>
  );
}
