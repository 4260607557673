export function goAccount(address: string) {
  window.open(`https://tonviewer.com/${address}`, '_blank');
}

export function goTx(tx: string) {
  window.open(`https://tonviewer.com/transaction/${tx}`, '_blank');
}

export function goStonfiPool(pool: string) {
  window.open(`https://app.ston.fi/pools/${pool}`, '_blank');
}
