import { MessageData } from '@/types/Message';
import { fetchInitialBroadcast } from '@/service/coin';
import { useRequest } from 'ahooks';

export function useFetchInitialBroadcast({ onSuccess }: { onSuccess?: (data: MessageData[]) => void }) {
  const hook = useRequest(
    async () => {
      const res = await fetchInitialBroadcast();

      return res.data;
    },
    {
      onSuccess(data) {
        onSuccess?.(data);
      },
    },
  );

  return hook;
}
