import { GlobalDataContext } from '@/context/GlobalDataContext';
import { useAppMessage } from '@/hooks/useAppMessage';
import { useResponsive } from '@/hooks/useResponsive';
import { Button } from '@/components/Button';
import { Modal } from '@/components/Modal';
import { Modal as AntModal } from 'antd';
import { useG6RouterMethods } from '@/contract/hooks/useG6RouterMethods';
import { LoadingOutlined } from '@ant-design/icons';
import { toNano } from '@ton/core';
import { useRequest } from 'ahooks';
import { Col, Form, GetProp, Input, Row, Upload, UploadProps } from 'antd';
import { useContext, useEffect, useMemo, useState } from 'react';
import { ConnectButton } from '../ConnectButton';
import { FormTheme } from '../Theme/FormTheme';
import { useFetchFee } from './hooks/useFetchFee';
import { InitialPurchaseModal } from './InitialPurchase';

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

type FieldType = {
  name: string;
  ticker: string;
  description: string;
  image: string;
  website?: string;
  twitter?: string;
  telegram?: string;
};

function isTwitterUrl(url: string) {
  const twitterRegex = /^(https?:\/\/)?(www\.)?(twitter\.com|x\.com)\/([a-zA-Z0-9_]+)/;

  return twitterRegex.test(url);
}

function isWebsiteUrl(url: string) {
  const websiteRegex = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})(\/[a-zA-Z0-9-._~:/?#[\]@!$&'()*+,;%=]*)?$/;

  return websiteRegex.test(url);
}

function isTelegramUrl(url: string) {
  const telegramRegex =
    /^(https?:\/\/)?(www\.)?(t\.me|telegram\.me)\/[a-zA-Z0-9_]+$|^tg:\/\/resolve\?domain=[a-zA-Z0-9_]+$/;

  return telegramRegex.test(url);
}

const getBase64 = (img: FileType, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

function MobileForm({ commonColProps }: { commonColProps: any }) {
  return (
    <>
      <Col span={8} {...commonColProps}>
        <Form.Item
          label="Website"
          name="website"
          rules={[
            {
              validator(rule, value, callback) {
                if (!value) {
                  return callback();
                }

                if (!isWebsiteUrl(value)) {
                  return callback('Invalid website url');
                }

                return callback();
              },
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col span={8} {...commonColProps}>
        <Form.Item
          label="Twitter"
          name="twitter"
          rules={[
            {
              validator(rule, value, callback) {
                if (!value) {
                  return callback();
                }

                if (!isTwitterUrl(value)) {
                  return callback('Invalid twitter url');
                }

                return callback();
              },
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col span={8} {...commonColProps}>
        <Form.Item
          label="Telegram"
          name="telegram"
          rules={[
            {
              validator(rule, value, callback) {
                if (!value) {
                  return callback();
                }

                if (!isTelegramUrl(value)) {
                  return callback('Invalid telegram url');
                }

                return callback();
              },
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Col>
    </>
  );
}

export function NewCoin({ children }: { children?: React.ReactNode }) {
  const [open, setOpen] = useState(false);

  const [form] = Form.useForm<FieldType>();

  const message = useAppMessage();

  const { handleLaunch } = useG6RouterMethods();

  const [imageUrl, setImageUrl] = useState<string | null>(null);

  const { refreshBalanceAsync } = useContext(GlobalDataContext);

  const { run, loading } = useRequest(
    async (amount: string) => {
      const field = form.getFieldsValue();

      return handleLaunch({
        name: field.name,
        symbol: field.ticker,
        description: field.description,
        image: field.image,
        twitter: field.twitter,
        telegram: field.telegram,
        website: field.website,
        tonAmountToPayForInitBuy: toNano(amount),
      });
    },
    {
      manual: true,
      onSuccess() {
        message.success('Coin created successfully');

        setOpen(false);

        setInitialPurchaseOpen(false);
      },
      onError(err) {
        console.log('handleLaunch-err', err);

        message.error(err.message || 'Failed to create coin');
      },
    },
  );

  const [initialPurchaseOpen, setInitialPurchaseOpen] = useState(false);

  const runCreate = () => {
    form
      .validateFields()
      .then(() => {
        setInitialPurchaseOpen(true);
      })
      .catch(() => null);
  };

  const [uploadLoading, setLoading] = useState(false);

  const UploadButton = (
    <button style={{ border: 0, background: 'none' }} type="button">
      {uploadLoading ? <LoadingOutlined /> : null}
      <div style={{ marginTop: 8, fontSize: '15px', color: '#00000050' }}>UPLOAD</div>
    </button>
  );

  const beforeUpload = (file: FileType) => {
    const isImage = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif';

    if (!isImage) {
      message.error('You can only upload JPG/PNG/GIF file!');
    }

    const isLt10M = file.size / 1024 / 1024 < 10;

    if (!isLt10M) {
      AntModal.info({
        zIndex: 10000,
        centered: true,
        content: 'Exceeding 10M will be compressed',
        className: 'error-modal',
        icon: null,
      });
    }

    return isImage;
  };

  const handleChange: UploadProps['onChange'] = (info) => {
    if (info.file.status === 'uploading') {
      setLoading(true);

      return;
    }

    if (info.file.status === 'error') {
      setLoading(false);

      return message.error('Failed to upload image');
    }

    if (info.file.status === 'done') {
      form.setFieldValue('image', info.file.response.data.url);

      getBase64(info.file.originFileObj as FileType, (url) => {
        setLoading(false);

        setImageUrl(url);
      });
    }
  };

  useEffect(() => {
    if (open) {
      setLoading(false);
      setImageUrl('');
      form?.resetFields?.();
    } else {
      refreshBalanceAsync?.().catch(() => null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const { isMobile } = useResponsive();

  const { fee } = useFetchFee({});

  const commonColProps = useMemo(() => {
    if (isMobile) {
      return {
        md: 24,
        xs: 24,
      };
    }

    return {};
  }, [isMobile]);

  return (
    <>
      {children ? (
        <div
          className="w-full"
          onClick={() => {
            setOpen(true);
          }}
        >
          {children}
        </div>
      ) : (
        <Button onClick={() => setOpen(true)} className="md:hidden">
          <span className="font-ClashDisplay-Semibold p-[17px 21px 16px 21px] text-[15px] whitespace-break-spaces">
            START A NEW COIN
          </span>
        </Button>
      )}

      <Modal
        title="New Coin"
        width={726}
        open={open}
        footer={null}
        onCancel={() => setOpen(false)}
        centered
        destroyOnClose
      >
        <FormTheme>
          <div
            className="w-full mt-[18px] md:max-h-[600px] md:overflow-y-auto overflow-x-hidden mb-6 md:mt-6 md:mb-[6px]"
            style={isMobile ? {} : { borderBottom: '2px solid #EBEBEB' }}
          >
            <Form<FieldType> layout="vertical" form={form} autoComplete="off">
              <Row gutter={[20, 0]}>
                <Col span={16} {...commonColProps}>
                  <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Please input your name!' }]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={8} {...commonColProps}>
                  <Form.Item label="Ticker" name="ticker" rules={[{ required: true, message: 'Please input ticker!' }]}>
                    <Input />
                  </Form.Item>
                </Col>

                {!isMobile && (
                  <Col span={4} {...commonColProps}>
                    <Form.Item label="Image" name="image" rules={[{ required: true, message: 'Please upload image' }]}>
                      <Upload
                        name="file"
                        listType="picture-card"
                        showUploadList={false}
                        multiple={false}
                        action={`${process.env.REACT_APP_API}/ipfs/file`}
                        beforeUpload={beforeUpload}
                        onChange={handleChange}
                      >
                        {imageUrl ? (
                          <img src={imageUrl} className="rounded-[12px] max-h-[110px] object-contain" alt="avatar" style={{ width: '100%' }} />
                        ) : (
                          UploadButton
                        )}
                      </Upload>
                    </Form.Item>
                  </Col>
                )}

                <Col span={20} {...commonColProps}>
                  <Form.Item label="Description" name="description" rules={[{ message: 'Please input description' }]}>
                    <Input.TextArea
                      style={{
                        resize: 'none',
                        height: isMobile ? '96px' : '102px',
                      }}
                    />
                  </Form.Item>
                </Col>

                {!isMobile && <MobileForm commonColProps={commonColProps}></MobileForm>}

                {isMobile && (
                  <div className="flex w-[100%]">
                    <Col {...commonColProps} className="basis-[content]">
                      <Form.Item
                        label="Image"
                        name="image"
                        rules={[{ required: true, message: 'Please upload image' }]}
                      >
                        <Upload
                          name="file"
                          listType="picture-card"
                          showUploadList={false}
                          multiple={false}
                          action={`${process.env.REACT_APP_API}/ipfs/file`}
                          beforeUpload={beforeUpload}
                          onChange={handleChange}
                        >
                          {imageUrl ? (
                            <img src={imageUrl} className="rounded-[12px] max-h-[110px] object-contain" alt="avatar" style={{ width: '100%' }} />
                          ) : (
                            UploadButton
                          )}
                        </Upload>
                      </Form.Item>
                    </Col>
                    <div className="flex-1">
                      <MobileForm commonColProps={commonColProps}></MobileForm>
                    </div>
                  </div>
                )}
              </Row>
            </Form>
          </div>
          <div className="flex justify-between items-center text-[15px] font-ClashDisplay-Semibold md:pt-[20px] md:flex md:flex-col-reverse">
            <div>
              <span className="text-[#808080] mr-[5px]">COST OF DEPLOY:</span> {fee || '-'} TON
            </div>

            <div className="md:w-[100%]">
              <ConnectButton block className="w-[275px] md:mb-[18px] md:w-[100%]">
                <Button
                  disabled={uploadLoading}
                  onClick={() => {
                    runCreate();
                  }}
                  className="w-[275px] md:mb-[18px] md:w-[100%]"
                >
                  CREATE COIN
                </Button>
              </ConnectButton>
            </div>
          </div>

          <InitialPurchaseModal
            open={initialPurchaseOpen}
            imageUri={imageUrl!}
            symbol={form.getFieldValue('ticker')}
            loading={loading}
            onClose={() => {
              setInitialPurchaseOpen(false);
            }}
            onCreate={(amount) => {
              run(amount);
            }}
          />
        </FormTheme>
      </Modal>
    </>
  );
}
