import request from '@/request';
import { Following } from '@/types/Following';

// 关注用户
export function follow({ address }: { address: string }) {
  return request.post<{ token: string }>(`/follows/follow`, { address });
}

// 取消关注用户
export function unFollow({ address }: { address: string }) {
  return request.post<{ token: string }>(`/follows/unfollow`, { address });
}

// 获取该用户的关注列表
export function fetchFollowing(address: string) {
  return request.get<Following>(`/follows/following/${address}`);
}

// 获取关注该用户的列表
export function fetchFollowers(address: string) {
  return request.get<any>(`/follows/followers/${address}`);
}
