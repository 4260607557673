import { App } from 'antd';

export function useAppMessage({ key }: { key?: string } = { key: 'default' }) {
  const { message } = App.useApp();

  const info = (content: string) => {
    return message.info({ content, key });
  };

  const error = (content: string) => {
    return message.error({ content, key });
  };

  const warning = (content: string) => {
    return message.warning({ content, key });
  };

  const success = (content: string) => {
    return message.success({ content, key });
  };

  return {
    info,
    error,
    warning,
    success,
    messageInstance: message,
  };
}
