import { useEffect, useRef } from 'react';
import * as React from 'react';
import { dataFeed } from './dataFeed';
import {
  ChartingLibraryWidgetOptions,
  IChartingLibraryWidget,
  LanguageCode,
} from 'public/charting_library/charting_library';
import { Coin } from '@/types/Coin';
import { toRealValue } from '@/utils/ton';
import { delay } from 'lodash';
import { Skeleton } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import classNames from 'classnames';

const { widget } = window.TradingView;

export interface ChartContainerProps {
  symbol: ChartingLibraryWidgetOptions['symbol'];
  interval: ChartingLibraryWidgetOptions['interval'];

  // BEWARE: no trailing slash is expected in feed URL
  datafeedUrl: string;
  libraryPath: ChartingLibraryWidgetOptions['library_path'];
  chartsStorageUrl: ChartingLibraryWidgetOptions['charts_storage_url'];
  chartsStorageApiVersion: ChartingLibraryWidgetOptions['charts_storage_api_version'];
  clientId: ChartingLibraryWidgetOptions['client_id'];
  userId: ChartingLibraryWidgetOptions['user_id'];
  fullscreen: ChartingLibraryWidgetOptions['fullscreen'];
  autosize: ChartingLibraryWidgetOptions['autosize'];
  studiesOverrides: ChartingLibraryWidgetOptions['studies_overrides'];
  container: ChartingLibraryWidgetOptions['container'];
}

const getLanguageFromURL = (): LanguageCode | null => {
  const regex = new RegExp('[\\?&]lang=([^&#]*)');
  // eslint-disable-next-line no-restricted-globals
  const results = regex.exec(location.search);
  return results === null ? null : (decodeURIComponent(results[1].replace(/\+/g, ' ')) as LanguageCode);
};

export const KLine = ({ coin }: { coin: Coin }) => {
  const chartContainerRef = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;

  const [ready, setReady] = React.useState(false);

  useEffect(() => {
    let tvWidget: IChartingLibraryWidget;

    if (coin.address) {
      const widgetOptions: ChartingLibraryWidgetOptions = {
        symbol: coin.address as string,
        // BEWARE: no trailing slash is expected in feed URL
        // datafeed: new (window as any).Datafeeds.UDFCompatibleDatafeed(defaultProps.datafeedUrl),
        datafeed: dataFeed({
          symbol: coin.address,
          symbolName: coin.symbol,
          // description: coin.description,
          description: '',
          fullName: coin.name,
        }),
        interval: '15' as ChartingLibraryWidgetOptions['interval'],
        container: chartContainerRef.current,
        library_path: '/charting_library/',
        locale: getLanguageFromURL() || 'en',
        disabled_features: ['use_localstorage_for_settings'],
        enabled_features: ['study_templates'],
        charts_storage_url: 'https://saveload.tradingview.com',
        charts_storage_api_version: '1.1',
        client_id: 'tradingview.com',
        user_id: 'public_user_id',
        fullscreen: false,
        autosize: true,
        studies_overrides: {},
        theme: 'Dark',
      };

      tvWidget = new widget(widgetOptions);

      const parsePrice = (widget: IChartingLibraryWidget) => {
        const chart = tvWidget?.activeChart();

        if (chart) {
          console.log('Active chart is available.');

          chart.priceFormatter().format = (v) => {
            return `${toRealValue(v!)}`;
          };

          chart.executeActionById('scaleSeriesOnly');

          delay(() => setReady(true), 500);
        } else {
          console.log('Active chart is not available.');
          delay(() => parsePrice(widget), 500);
        }
      };

      tvWidget.onChartReady(() => {
        console.log('Chart has loaded!');
        parsePrice(tvWidget);
      });
    }

    return () => {
      tvWidget?.remove();
    };
  }, [coin]);

  return (
    <div className="min-h-[400px] md:min-h-[260px] h-full relative">
      {!ready && (
        <Skeleton.Node active={true} className="absolute !w-full !h-full left-0 top-0 z-20">
          <LoadingOutlined className="hidden" />
        </Skeleton.Node>
      )}

      <div
        ref={chartContainerRef}
        className={classNames('min-h-[400px] md:min-h-[260px] h-full relative z-10 opacity-0 transition-all', {
          '!opacity-100': ready,
        })}
      />
    </div>
  );
};
