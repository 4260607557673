import { Select, ConfigProvider } from 'antd';

import { Coin } from '@/types/Coin';

export function TonSuffix() {
  return (
    <div className="inline-flex justify-start items-center gap-x-[5px] relative bottom-[-1px]">
      <div className="w-[24px]">
        <img className="w-[24px] h-[24px]" src="/static/images/ton_symbol.svg" />
      </div>
      <span className="text-[15px] font-[ClashDisplay-Semibold] font-semibold">TON</span>
    </div>
  );
}

export function SymbolSelect({
  symbol,
  setSymbol,
  detail,
}: {
  symbol: string;
  setSymbol: (e: string) => void;
  detail?: Coin;
}) {
  return (
    <ConfigProvider
      wave={{ disabled: true }}
      theme={{
        components: {
          Select: {
            optionSelectedColor: '#000',
            colorText: '#000',
            optionFontSize: 15,
            fontSize: 15,
            colorPrimary: '#000',
          },
          Input: {
            colorPrimaryText: '#000',
            colorPrimary: '#000',
          },
        },
        token: {
          colorText: '#000',
          colorBgTextHover: '#000',
        },
      }}
    >
      <Select
        value={symbol}
        defaultValue="TON"
        onChange={(e) => {
          setSymbol(e);
        }}
        className="!mr-[-15px] !mt-0"
      >
        <Select.Option value="TON">
          <TonSuffix />
        </Select.Option>

        <Select.Option value={detail?.symbol} class="m-auto">
          <div className="inline-flex justify-start items-center gap-x-[6px] overflow-hidden relative bottom-[-1px]">
            <div className="w-[24px]">
              <img className="w-[24px] h-[24px] rounded-full" src={detail?.imageUri} />
            </div>
            <span className="text-[15px] font-[ClashDisplay-Semibold] font-semibold max-w-[140px] text-ellipsis overflow-hidden">
              {detail?.symbol}
            </span>
          </div>
        </Select.Option>
      </Select>
    </ConfigProvider>
  );
}
