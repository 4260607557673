import { Outlet } from 'react-router-dom';
import Header from '@/components/Header';
import { InformationCarousel } from '@/components/InformationCarousel';
import { useMemo, useState, useEffect, useRef } from 'react';
import { useEventListener, useMount, useScroll } from 'ahooks';
import { useLocation } from 'react-router-dom';

export function Layout() {
  const [container, setContainer] = useState<HTMLDivElement | null>(null);

  const data = useScroll(container);

  const ref = useRef<HTMLVideoElement>(null);

  const [playing, setPlaying] = useState(false);

  function requestPlay() {
    if (playing) return;

    ref.current
      ?.play()
      .then(() => {
        setPlaying(true);
      })
      .catch(() => null);
  }

  useEventListener(
    'click',
    () => {
      requestPlay();
    },
    { target: window },
  );

  useEventListener(
    'touchstart',
    () => {
      requestPlay();
    },
    { target: window },
  );

  useMount(() => {
    requestPlay();
  });

  const blur = useMemo(() => {
    try {
      return data!.top / 20;
    } catch (e) {
      return 0;
    }
  }, [data]);

  const location = useLocation();

  useEffect(() => {
    container?.scrollTo?.(0, 0);
  }, [location, container]);

  return (
    <>
      <div className="w-full h-full relative z-10 overflow-y-auto" ref={setContainer}>
        <div className="w-full h-[30px] z-20 sticky top-0">
          <InformationCarousel></InformationCarousel>
        </div>

        <Header
          className="sticky top-[30px] z-40"
          style={{
            WebkitBackdropFilter: `blur(${blur}px)`,
            backdropFilter: `blur(${blur}px)`,
          }}
        ></Header>

        <div className="container-center pb-[80px]">
          <Outlet />
        </div>
      </div>

      <div className="fixed left-0 top-[30px] z-0 w-full h-full bg-slate-300 bg-gradient">
        <video
          ref={ref}
          className="w-full h-full object-fill md:object-cover"
          autoPlay
          muted
          loop
          playsInline
          src="/bg.mp4"
          controlsList="nodownload nofullscreen noremoteplayback"
        ></video>
      </div>
    </>
  );
}
