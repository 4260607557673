import { useRequest } from 'ahooks';
import { useAppMessage } from '@/hooks/useAppMessage';
import { likeComments } from '@/service/comments';

export function useLikeComments({ onSuccess }: { onSuccess: () => void }) {
  const message = useAppMessage();

  const { run, runAsync, refresh, loading } = useRequest(
    (id: string) => {
      return likeComments(id);
    },
    {
      manual: true,
      onSuccess() {
        message.success('success');
        onSuccess();
      },
      onError(e) {
        message.error(e.message || 'Failed to like');
      },
    },
  );

  return {
    run,
    runAsync,
    refresh,
    loading,
  };
}
