import { ConfigProvider, Input, InputProps } from 'antd';

export function TransparentInput(props: InputProps) {
  return (
    <ConfigProvider
      renderEmpty={() => <span className="text-white">Empty~</span>}
      theme={{
        components: {
          Input: {
            controlHeight: 38,
            colorBgContainer: 'transparent',
            borderRadius: 50,
            colorPrimary: '#fff',
            hoverBorderColor: '#fff',
            colorTextPlaceholder: 'rgba(255,255,255,.45)',
            controlOutlineWidth: 2,
            lineWidth: 2,
          },
        },
      }}
    >
      <Input {...props}></Input>{' '}
    </ConfigProvider>
  );
}
