import { GlobalDataContext } from '@/context/GlobalDataContext';
import { Modal } from '@/components/Modal';
import { Form, Input, Radio } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { FormTheme } from '../Theme/FormTheme';

const labelStyle: React.CSSProperties = {
  width: '33.33%',
  textAlign: 'center',
};

const options = [
  {
    label: '0.5%',
    value: '0.5',
    style: labelStyle,
  },
  { label: '1%', value: '1', style: labelStyle },
  { label: '2%', value: '2', style: labelStyle },
];

export function SlippageModal({ children }: { children: React.ReactNode }) {
  const [open, setOpen] = useState(false);

  const [form] = Form.useForm();

  const { slippage, setSlippage } = useContext(GlobalDataContext);

  const slippageRadio = Form.useWatch('slippageRadio', form);

  useEffect(() => {
    if (open) {
      console.log('slippage', slippage);

      if (slippage) {
        form.setFieldsValue({
          slippage,
          slippageRadio: slippage,
        });
      }
    } else {
      const value = form.getFieldValue('slippage')?.replace(/[^\d.]/g, '');

      if (value) {
        setSlippage?.(+value >= 0.005 ? value : '0.01');
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    form.setFieldsValue({
      slippage: slippageRadio,
    });
  }, [form, slippage, slippageRadio]);

  return (
    <>
      <div
        onClick={() => {
          setOpen(true);
        }}
      >
        {children}
      </div>
      <Modal title="Settings" width={540} open={open} centered footer={null} onCancel={() => setOpen(false)}>
        <FormTheme>
          <div className="w-full mt-[18px]">
            <Form layout="vertical" form={form} autoComplete="off">
              <Form.Item
                label={<div>Max. slippage</div>}
                name="slippage"
                rules={[{ required: true, message: 'Recommended range is 0.5%' }]}
              >
                <Input suffix="%" />
              </Form.Item>

              <Form.Item name="slippageRadio" className="!pb-0 !mb-0">
                <Radio.Group size="large" className="w-full" options={options} optionType="button" />
              </Form.Item>
            </Form>
          </div>
        </FormTheme>
      </Modal>
    </>
  );
}
