import { Button } from '@/components/Button';
import { Input, ConfigProvider } from 'antd';
import { InputTokenType, useG6PoolMethods } from '@/contract/hooks/useG6PoolMethods';
import { useContext, useMemo, useState } from 'react';
import { fromNano, toNano } from '@ton/core';
import { useRequest, useUnmount } from 'ahooks';
import { useAppMessage } from '@/hooks/useAppMessage';
import { useAccountMethods } from '@/contract/hooks/useAccountMethods';
import { TonValue } from '@/components/TonValue';
import { CoinDetailContext } from '../../context/CoinDetailContext';
import { SymbolSelect } from './SymbolSelect';
import { useCalcBuyAmount } from '../../hooks/useCalcBuyAmount';
import classNames from 'classnames';
import { useCalcBuyAmountByJetton } from '../../hooks/useCalcBuyAmountByJetton';
import { ConnectButton } from '@/components/ConnectButton';
import { PoolComplete } from './PoolComplete';
import { G6Math } from '@/contract/utils/Math';
import { useFetchTXResult } from '../../hooks/useFetchTXResult';
import { GlobalDataContext } from '@/context/GlobalDataContext';

const units = [
  {
    label: '1 TON',
    value: '1',
  },
  {
    label: '5 TON',
    value: '5',
  },
  {
    label: '10 TON',
    value: '10',
  },
  {
    label: 'MAX',
    value: 'MAX',
  },
];

const loadingKey = 'LOADING';

export function Buy() {
  const { handleBuy } = useG6PoolMethods();

  const { getBalance, ready } = useAccountMethods();

  const { detail, isGraduated, refreshIsGraduated } = useContext(CoinDetailContext);

  const { refreshBalanceAsync } = useContext(GlobalDataContext);

  const [count, setCount] = useState('');

  const { data: balance = toNano(0), loading: balanceLoading } = useRequest(
    async () => {
      return getBalance().then((balance) => {
        return balance ? balance : toNano(0);
      });
    },
    {
      ready: ready,
    },
  );

  const handlePut = (val: any) => {
    if (isGraduated) return;

    setCount(val);
  };

  const [queryId, setQueryId] = useState<string | undefined>();

  useFetchTXResult({
    queryId,
    type: 'buy',
    onSuccess() {
      message.success('Purchase success');

      refreshBalanceAsync?.().catch(() => null);

      setQueryId(undefined);

      message.messageInstance.destroy(loadingKey);
    },
    onFail() {
      message.error('Purchase failed, fee will be refunded later');

      message.messageInstance.destroy(loadingKey);
    },
  });

  const message = useAppMessage();

  const [symbol, setSymbol] = useState('TON');

  const isTon = useMemo(() => {
    return symbol === 'TON';
  }, [symbol]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCount(e.target.value);
  };

  const onBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/[^\d.]/g, '');

    setCount(value);
  };

  const { run: runBuy, loading: buyLoading } = useRequest(
    () => {
      if (isTon) {
        if (new G6Math(count).isGreaterThan(fromNano(balance) || 0n)) {
          return Promise.reject(new Error('Insufficient balance'));
        }
      } else {
        if (new G6Math(tonAmount).isGreaterThan(fromNano(balance) || 0n)) {
          return Promise.reject(new Error('Insufficient balance'));
        }
      }

      return handleBuy({
        tonAmountToPay: toNano(count),
        buyType: isTon ? InputTokenType.ByTon : InputTokenType.ByJetton,
        poolAddress: detail!.poolAddress,
      });
    },
    {
      manual: true,
      ready: !!detail?.poolAddress,
      onSuccess: (res) => {
        message.success('Transaction sent successfully');

        refreshIsGraduated?.();

        setCount('');

        console.log('res-------->', res);

        setQueryId(res?.queryId.toString());

        refreshBalanceAsync?.().catch(() => null);

        message.messageInstance.open({
          type: 'loading',
          key: loadingKey,
          content: 'Waiting for purchase result',
          duration: 1000,
        });
      },
      onError(err) {
        message.error(err.message || 'Transaction sent failed');
      },
    },
  );

  function toSafeNano(val: string) {
    try {
      return toNano(val);
    } catch {
      return toNano('0');
    }
  }

  const { displayValue: tokenAmount } = useCalcBuyAmount({
    poolAddress: detail?.poolAddress,
    amount: toSafeNano(count),
    ready: isTon && !isGraduated && !!count,
  });

  const { displayValue: tonAmount } = useCalcBuyAmountByJetton({
    poolAddress: detail?.poolAddress,
    amount: toSafeNano(count),
    ready: !isTon && !isGraduated && !!+count,
  });

  useUnmount(() => {
    message.messageInstance.destroy(loadingKey);
  });

  return (
    <ConfigProvider
      wave={{ disabled: true }}
      theme={{
        components: {
          Input: {
            colorBgContainer: '#fff',
            activeBg: '#fff',
            addonBg: '#fff',
            activeBorderColor: '#ffffff00',
            colorSplit: '#ffffff00',
            colorBorder: '#ffffff00',
            hoverBorderColor: '#ffffff00',
            colorText: '#000',
            borderRadius: 12,
            controlHeight: 48,
            fontSize: 16,
          },
        },
      }}
    >
      <div>
        <Input
          className="w-full mt-[18px]"
          readOnly={isGraduated}
          addonAfter={<SymbolSelect symbol={symbol} setSymbol={setSymbol} detail={detail} />}
          defaultValue={count}
          value={count}
          onBlur={onBlur}
          onChange={handleChange}
        />

        <div
          className={classNames('flex items-center mt-3 pl-3', {
            hidden: !isTon,
          })}
        >
          <ul className="flex items-center leading-[15px] text-[15px] font-semibold gap-[18px] font-[ClashDisplay-Semibold]">
            {units.map((item) => {
              return (
                <li
                  key={item.label}
                  className={classNames(isGraduated ? 'cursor-not-allowed' : 'cursor-pointer')}
                  onClick={() => {
                    handlePut(item.value === 'MAX' ? fromNano(balance) : Number(item.value));
                  }}
                >
                  {item.label}
                </li>
              );
            })}
          </ul>

          <div className="text-[14px] font-normal text-[#ffffff50] ml-[6px]">
            <TonValue value={balance} loading={balanceLoading} suffix={'TON'}></TonValue>
          </div>
        </div>

        {!isGraduated && (
          <ConnectButton className="w-full mt-[18px] text-[15px] font-[ClashDisplay-Semibold]">
            <Button
              disabled={isGraduated || !+count}
              loading={buyLoading}
              className={classNames('w-full mt-[18px] text-[15px] font-[ClashDisplay-Semibold]')}
              onClick={() => {
                runBuy();
              }}
            >
              {isGraduated ? 'Complete' : 'PLACE TRADE'}
            </Button>
          </ConnectButton>
        )}

        <PoolComplete />

        {!!count &&
          (!isTon ? (
            <div>{!!+tonAmount && <div className="my-[10px]  pl-3">{tonAmount} TON</div>}</div>
          ) : (
            <div>
              {!!+tokenAmount && (
                <div className="my-[10px]  pl-3">
                  {tokenAmount} {detail?.symbol}
                </div>
              )}
            </div>
          ))}
      </div>
    </ConfigProvider>
  );
}
