export function formatDate(d: Date): string {
  const date = new Date(d);

  const year = date.getFullYear();

  const month = padZero(date.getMonth() + 1);

  const day = padZero(date.getDate());

  const hours = padZero(date.getHours());

  const minutes = padZero(date.getMinutes());

  const seconds = padZero(date.getSeconds());

  return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
}

function padZero(value: number): string {
  return value < 10 ? `0${value}` : `${value}`;
}
