import { TonConnect } from '@tonconnect/ui-react';
import { Modal } from 'antd';
import { AxiosError } from 'axios';
import { useUserStore } from '@/stores/useUserStore';

function disconnect() {
  try {
    const tc = new TonConnect();

    if (tc.connected) {
      new TonConnect().disconnect();
    }
  } catch (e) {
    //
  }
}

/**
 * error handler
 * @param err
 * @returns
 */
export const errorHandler = async (err: AxiosError) => {
  switch (err.status) {
    case 401:
      if (useUserStore.getState().token) {
        Modal.info({
          zIndex: 10000,
          className: 'error-modal',
          icon: null,
          centered: true,
          content: 'Login has expired, please reconnect wallet',
          async onOk() {
            disconnect();
            useUserStore.getState().loginOut();
          },
        });
      }

      return Promise.reject(err);
    default:
      return Promise.reject(err);
  }
};
