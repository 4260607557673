import { TonConnectUIProvider } from '@tonconnect/ui-react';
import { GlobalWarp } from './components/GlobalWarp';
import Routes from './routes';
import { App as AntdApp, ConfigProvider } from 'antd';
import locale from 'antd/es/locale/en_GB';

function App() {
  return (
    <TonConnectUIProvider manifestUrl={process.env.REACT_APP_TON_MANIFEST_URL} language="en">
      <ConfigProvider
        renderEmpty={() => <span className="text-white">Empty~</span>}
        theme={{
          components: {
            Message: {
              colorText: '#000000',
              controlHeightLG: 50,
              fontSizeIcon: 24,
              fontSizeLG: 18,
              fontFamily: 'ClashDisplay-Semibold',
            },
            Dropdown: {
              colorText: '#000000',
              fontFamily: 'ClashDisplay-Semibold',
              paddingContentVertical: 12,
            },
            Spin: {
              colorPrimary: '#fff',
            },
            Modal: {
              borderRadius: 27,
              borderRadiusLG: 27,
            },
          },
          token: {
            fontFamily: 'Inter',
            colorText: '#ffffff',
            controlOutline: 'transparent',
          },
        }}
        prefixCls="g6"
        locale={locale}
      >
        <AntdApp className="w-full h-full">
          <GlobalWarp>
            <Routes />
          </GlobalWarp>
        </AntdApp>
      </ConfigProvider>
    </TonConnectUIProvider>
  );
}

export default App;
