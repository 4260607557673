import Storage from '@/utils/Storage';

const storage = new Storage({ engine: 'localStorage', prefix: 'G6' });

const get = <T = any>(key: string) => {
  return storage.get(key) as T;
};

const set = (key: string, data: any) => {
  storage.set(key, data);
};

const del = (key: string) => {
  storage.delete(key);
};

export default { get, set, del };
