import mitt from 'mitt';
import { TradeMessage } from '../types/Message';

export const mittEvent = mitt();

export const EventName = {
  trade: 'trade',
};

export const event = {
  emitTrade(data: TradeMessage) {
    mittEvent.emit(EventName.trade, data);
  },
  onTrade(cb: (payload: TradeMessage) => void) {
    mittEvent.on(EventName.trade, (params: any) => {
      cb(params);
    });
  },
};
