import { useWallet } from '@/hooks/useWallet';
import { G6Router } from '@/contract/wrappers/G6Router';
import { fromNano, toNano } from '@ton/core';
import { useRequest } from 'ahooks';

export function useFetchFee({ tonAmountToPayForInitBuy }: { tonAmountToPayForInitBuy?: string }) {
  const { provider } = useWallet({ address: process.env.REACT_APP_G6_ROUTER_ADDRESS });

  const { data, loading } = useRequest(
    async () => {
      const feeResult = await G6Router.getLaunchTxFee(provider!, {
        tonAmountToPayForInitBuy: tonAmountToPayForInitBuy ? toNano(tonAmountToPayForInitBuy) : 0n,
      });

      return fromNano(feeResult.fee);
    },
    {
      ready: !!provider,
      refreshDeps: [tonAmountToPayForInitBuy],
      onSuccess(res) {
        console.log('launch-fee', res);
      },
      onError(err) {
        console.log('launch-fee-err', err);
      },
    },
  );

  return {
    fee: data,
    loading,
  };
}
