import { fetchUserCreateCoins } from '@/service/user';
import { useRequest } from 'ahooks';

export function useFetchUserCreateCoins(address: any) {
  const hook = useRequest(
    () => {
      return fetchUserCreateCoins(address);
    },
    {
      ready: !!address,
    },
  );

  return hook;
}
