import { LoadingOutlined } from '@ant-design/icons';
import { Skeleton } from 'antd';
import classNames from 'classnames';
import { useState } from 'react';

export function G6Image({ src, className }: { src?: string; className?: string }) {
  const [loading, setLoading] = useState(true);

  return (
    <div className={classNames('w-full relative flex justify-center items-center', className)}>
      {loading && (
        <Skeleton.Node className="!w-full !h-full absolute left-0 top-0" active={loading}>
          <LoadingOutlined />
        </Skeleton.Node>
      )}

      <img
        onLoad={() => {
          setLoading(false);
        }}
        className={classNames('group-hover:scale-125 transition-all duration-300 max-w-full max-h-full')}
        src={src}
      />
    </div>
  );
}
