import { fetchFollowers } from '@/service/follows';
import { useRequest } from 'ahooks';

export function useFetchFollowers(address: any) {
  const hook = useRequest(
    () => {
      return fetchFollowers(address);
    },
    {
      ready: !!address,
    },
  );

  return hook;
}
