import { List, Pagination, PaginationProps, ConfigProvider } from 'antd';
import { useMemo, useState } from 'react';
import { useWindowResize } from '@/hooks/useWindowResize';
import { useFetchMyComments } from '../hooks/useFetchMyComments';
import { CommentDetail } from '@/components/Comments/CommentDetail';
import { Reply } from '@/types/Reply';
import { useParseRouterQuery } from '@/hooks/router/useParseRouterQuery';

export function Replies() {
  const { width } = useWindowResize();

  const { query, updateQuery } = useParseRouterQuery({
    current: '1',
  });

  const { data, refresh } = useFetchMyComments({ current: +query.current });

  const onChange: PaginationProps['onChange'] = (page) => {
    updateQuery({ ...query, current: page.toString() });
  };

  const isMobile = useMemo(() => {
    return width <= 768;
  }, [width]);

  const [replyValue, setReplyValue] = useState('');

  console.log(replyValue);

  const getDefaultReply = (id: string) => {
    setReplyValue(id);
  };

  return (
    <div>
      <List
        itemLayout="horizontal"
        dataSource={data?.data.docs}
        renderItem={(item: Reply) => (
          <List.Item
            className="h-full"
            style={{
              borderBottomColor: 'rgba(225,225,225,0.1)',
              borderBottomWidth: '2px',
              padding: isMobile ? '10px 0' : '15px 0',
            }}
          >
            <CommentDetail item={item} refresh={refresh} getDefaultReply={getDefaultReply} />
          </List.Item>
        )}
      />
      <div className="text-center">
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: '#000',
              colorTextSecondary: '#fff',
              colorTextDisabled: 'rgba(255,255,255, .6)',
            },
          }}
        >
          <Pagination
            hideOnSinglePage={true}
            current={data?.data.page}
            total={data?.data.totalDocs}
            pageSize={data?.data.limit}
            onChange={onChange}
          />
        </ConfigProvider>
      </div>
    </div>
  );
}
