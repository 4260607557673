import { Coin } from '../types/Coin';
import { createContext, useRef, useState } from 'react';
import { useWebsocket } from '../hooks/app/useWebsocket';
import { MessageData } from '../types/Message';
import { event } from '../utils/event';

type LaunchCallBack = (listener: (data: MessageData) => void) => () => void;

export const WebSocketContext = createContext<{
  latestCoin: Coin | undefined;
  onMessage?: LaunchCallBack;
}>({
  latestCoin: undefined,
  onMessage: undefined,
});

function parseData(msg: string) {
  try {
    const data = JSON.parse(msg) as MessageData;

    return data;
  } catch (err) {
    console.log('parseData error', err);

    return null;
  }
}

export const useWebSocketContext = () => {
  const [latestCoin, setLatestCoin] = useState<Coin | undefined>(undefined);

  const listeners = useRef<any>([]);

  const onMessage: LaunchCallBack = (listener) => {
    listeners.current.push(listener);

    return () => {
      listeners.current = listeners.current.filter((l: any) => l !== listener);
    };
  };

  function handleData(msg: string) {
    const data = parseData(msg);

    console.log('onMessage------->', data);

    switch (data?.type) {
      case 'launch':
        setLatestCoin(data.data);

        listeners.current.forEach((listener: any) => listener(data));
        break;
      case 'buy':
        event.emitTrade(data);
        break;
      case 'sell':
        event.emitTrade(data);
        break;
      default:
        return;
    }
  }

  const { websocket } = useWebsocket({
    onMessage(event) {
      handleData(event.data);
    },
  });

  return {
    websocket,
    latestCoin,
    onMessage,
  };
};
