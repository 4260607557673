import { Reply } from '@/types/Reply';
import { useLikeComments } from '../../views/Coin/hooks/useLikeComment';
import { useUnLikeComments } from '../../views/Coin/hooks/useUnLikeComment';
import { HeartFilled, HeartOutlined } from '@ant-design/icons';
import { useUserStore } from '@/stores/useUserStore';
import { formatDate } from '@/utils/format';

function Card({ children }: { children?: React.ReactNode }) {
  return <div className="w-full relative">{children}</div>;
}

export function CommentDetail({
  item,
  refresh,
  getDefaultReply,
}: {
  item: Reply;
  refresh: () => void;
  getDefaultReply: (id: string) => void;
}) {
  const handleReply = (id: string) => {
    getDefaultReply(id);
  };

  const { runAsync: runLike } = useLikeComments({
    onSuccess() {
      refresh();
    },
  });

  const { runAsync: runUnLike } = useUnLikeComments({
    onSuccess() {
      refresh();
    },
  });

  const handleLike = (id: string) => {
    runLike(id);
  };

  const handleUnLike = (id: string) => {
    runUnLike(id);
  };

  const { info } = useUserStore();

  const isMyLike = (likes: any) => {
    const myAddress = info?.address;

    const index = likes.indexOf(myAddress);

    if (index == -1) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <Card>
      <div className="flex gap-[18px] w-full text-[#94a3b8] text-base justify-between relative">
        <div className="flex text-[14px] leading-[17.5px] gap-[6px] items-center">
          <div className="font-medium text-white">{item.username}</div>
          <div>{formatDate(item.timestamp)}</div>
          <a className="cursor-pointer  text-[#94a3b8]"> #{item.targetId}</a>
        </div>
        <div className="flex gap-[18px] font-semibold items-center md:absolute md:right-0">
          {isMyLike(item.likes) && (
            <div
              className="cursor-pointer flex items-center"
              onClick={() => {
                handleUnLike(item._id);
              }}
            >
              <HeartFilled style={{ fontSize: '20px', fontWeight: 600, marginRight: '6px' }} />{' '}
              {item.likes ? item.likes.length : 0}
            </div>
          )}
          {!isMyLike(item.likes) && (
            <div
              className="cursor-pointer flex items-center"
              onClick={() => {
                handleLike(item._id);
              }}
            >
              <HeartOutlined style={{ fontSize: '20px', fontWeight: 600, marginRight: '6px' }} />{' '}
              {item.likes ? item.likes.length : 0}
            </div>
          )}
          <div
            className="text-[#ffffff50] cursor-pointer font-semibold"
            onClick={() => {
              handleReply(item.targetId);
            }}
          >
            Reply
          </div>
        </div>
      </div>
      <div className={`flex relative items-start gap-3 text-slate-300 overflow-auto mt-[6px] md:mb-[30px]`}>
        {item.file_uri && <img src={item.file_uri} className="h-[100px] w-[100px] object-contain" />}
        <div>
          <div>
            <div className="text-base text-white font-semibold">{item.text}</div>
          </div>
          {item.mentions && item.mentions.length > 0 && (
            <div className="text-[14px] leading-[17.5px] opacity-50">
              Mentions：
              {item.mentions &&
                item.mentions.map((mention) => {
                  return <span key={mention}>#{mention} </span>;
                })}
            </div>
          )}
        </div>
      </div>
    </Card>
  );
}
