import { GlobalDataContext, useGlobalDataContext } from '@/context/GlobalDataContext';
import { useWebSocketContext, WebSocketContext } from '@/context/WebSocketContext';
import { useFetchUserInfo } from '@/hooks/app/user/useFetchUserInfo';
import { Temporary } from '@/preferences/Temporary';
import { getQueryParam } from '@/utils';
import { useUserStore } from '@/stores/useUserStore';
import { useTonConnectUI } from '@tonconnect/ui-react';
import { useExternal } from 'ahooks';
import { useEffect } from 'react';
import { ProfileModal } from '../ProfileModal';

export function GlobalWarp({ children }: { children: any }) {
  const { profileModalOpen, setProfileModalOpen } = useUserStore();

  const [tonConnectUI] = useTonConnectUI();

  const { latestCoin, onMessage } = useWebSocketContext();

  const {
    tonUsdPrice,
    walletReady,
    balance,
    isBalanceLoading,
    slippage,
    slippageValue,
    setSlippage,
    refreshProof,
    refreshBalanceAsync,
  } = useGlobalDataContext();

  const telegramSdkStatus = useExternal('https://telegram.org/js/telegram-web-app.js', {
    js: {
      async: true,
    },
  });

  const vCnsoleStatus = useExternal(
    window.location.href.includes('enableDebug=1') ? 'https://unpkg.com/vconsole@latest/dist/vconsole.min.js' : '',
    {
      js: {
        async: true,
      },
    },
  );

  useFetchUserInfo({
    onSuccess(data) {
      if (!data) {
        setProfileModalOpen(true);
      }
    },
    onExpired() {
      tonConnectUI.disconnect();
    },
  });

  useEffect(() => {
    if (telegramSdkStatus === 'ready') {
      window?.Telegram?.WebApp?.expand?.();
    }
  }, [telegramSdkStatus]);

  useEffect(() => {
    if (vCnsoleStatus === 'ready') {
      new window.VConsole();
    }
  }, [vCnsoleStatus]);

  useEffect(() => {
    const chatId = getQueryParam('tgChatId');

    if (chatId) {
      Temporary.setChatId(chatId);
    }
  }, []);

  return (
    <>
      <GlobalDataContext.Provider
        value={{
          tonUsdPrice,
          walletReady,
          balance,
          isBalanceLoading,
          slippage,
          slippageValue,
          refreshProof,
          refreshBalanceAsync,
          setSlippage,
        }}
      >
        <WebSocketContext.Provider
          value={{
            latestCoin,
            onMessage,
          }}
        >
          <ProfileModal open={profileModalOpen}></ProfileModal>
          {children}
        </WebSocketContext.Provider>
      </GlobalDataContext.Provider>
    </>
  );
}
