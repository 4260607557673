import { StonApiClient } from '@ston-fi/api';
import { useRequest } from 'ahooks';

export const stonApiClient = new StonApiClient();

type SwapDirectSimulationPayload = {
  askAddress: string;
  offerAddress: string;
  offerUnits: string;
  slippageTolerance: string;
};

export function useGetSwapDirectSimulation(payload: SwapDirectSimulationPayload) {
  const hooks = useRequest(
    () => {
      return stonApiClient.simulateSwap({
        ...payload,
      });
    },
    {
      manual: true,
    },
  );

  return hooks;
}
