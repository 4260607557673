import { fromPairs } from 'lodash';
import { useSearchParams } from 'react-router-dom';

export function useParseRouterQuery<T extends Record<string, string>>(payload: T) {
  const [searchParams, setSearchParams] = useSearchParams();

  const keys = Object.keys(payload as Record<string, string>);

  const query = fromPairs(
    keys.map((key) => {
      return [key, searchParams.get(key) || payload[key]];
    }),
  ) as T;

  const updateQuery = (payload: Partial<T>) => {
    setSearchParams({ ...(query as any), ...payload }, { replace: true });
  };

  return {
    query,
    updateQuery,
  };
}
