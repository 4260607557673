import { Button } from '@/components/Button';
import { Modal } from '@/components/Modal';
import { ConfigProvider } from 'antd';
// import { SymbolSuffix } from '@/views/Coin/components/Trade/Sell';
import { TonSuffix } from '@/views/Coin/components/Trade/SymbolSelect';
import { Input } from 'antd';
import { useState } from 'react';
import { useFetchFee } from './hooks/useFetchFee';

export function InitialPurchaseModal({
  open,
  loading,
  symbol,
  onClose,
  onCreate,
}: {
  open: boolean;
  loading: boolean;
  symbol: string;
  imageUri: string;
  onClose?: () => void;
  onCreate: (amount: string) => void;
}) {
  // symbol,
  // imageUri,
  const [amount, setAmount] = useState('');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAmount(e.target.value);
  };

  const onBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/[^\d.]/g, '');

    setAmount(value);
  };

  const { fee } = useFetchFee({ tonAmountToPayForInitBuy: amount });

  return (
    <Modal title={`Want buy ${symbol}?`} width={490} open={open} footer={null} onCancel={() => onClose?.()} centered>
      <div className="mb-[30px] text-[16px] leading-[19.2px] mt-[9px] font-Inter max-w-[375px] md:mt-[17px]">
        It’s optional, but buying a small amount of coins helps protect your coin from snipers
      </div>

      <ConfigProvider
        wave={{ disabled: true }}
        theme={{
          components: {
            Input: {
              colorBgContainer: '#EBEBEB',
              activeBg: '#EBEBEB',
              addonBg: '#EBEBEB',
              activeBorderColor: '#ffffff00',
              colorSplit: '#ffffff00',
              colorBorder: '#ffffff00',
              hoverBorderColor: '#ffffff00',
              colorText: '#000',
              borderRadius: 12,
              controlHeight: 48,
              fontSize: 16,
            },
          },
        }}
      >
        <div>
          <Input
            className="w-full b-0 mb-3"
            addonAfter={
              <TonSuffix />
              // <SymbolSuffix symbol={symbol} icon={imageUri} />
            }
            defaultValue={amount}
            value={amount}
            onBlur={onBlur}
            onChange={handleChange}
          />
        </div>
      </ConfigProvider>

      {/* <div className="text-[14px] leading-[14px] font-Inter font-normal opacity-50">Cost: 0.0 TON</div> */}

      <Button
        block
        disabled={loading}
        loading={loading}
        className="mt-[31px]"
        onClick={() => {
          onCreate?.(amount);
        }}
      >
        CREATE COIN
      </Button>

      <div className="flex justify-center text-[15px] leading-[15px] items-center font-ClashDisplay-Semibold text-black mt-[18px] mb-[8px] md:mb-0">
        <span className="text-black text-opacity-50 mr-[5px]">COST OF DEPLOY: </span> {fee || '-'} TON
      </div>
    </Modal>
  );
}
