import { fetchMyComments } from '@/service/comments';
import { useRequest } from 'ahooks';

export function useFetchMyComments({ current }: { current: number }) {
  const hook = useRequest(
    () => {
      return fetchMyComments({
        current,
        size: 5,
      });
    },
    {
      refreshDeps: [current],
    },
  );

  return hook;
}
