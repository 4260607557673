// import chartIcon from '@/assets/icon/icon_chart.png';
// import setIcon from '@/assets/icon/icon_set.png';
import { MarketCap } from '@/components/CoinCard';
import { KLine } from '@/components/KLine';
import { useCoinDetailContext } from '../context/CoinDetailContext';

export function MarketCard() {
  const { detail } = useCoinDetailContext();

  return (
    <div className="flex flex-col w-full gap-[17px] border-2 border-white rounded-[18px] p-[18px] pb-[14px] md:border-0 md:p-0 md:pb-[14px] md:gap-[14px]">
      <div className="flex justify-between">
        <div className="flex items-center">
          <div className="w-12 h-12 rounded-full mr-[12px] overflow-hidden md:w-9 md:h-9">
            <img src={detail?.imageUri} className="w-full h-full" />
          </div>
          <div>
            <div className="text-[24px] leading-6 mb-[6px] font-semibold font-[ClashDisplay-Semibold] md:text-[20px] md:leading-[20px] md:mb-[3px]">
              {detail?.symbol}
            </div>
            <div className="text-[16px] leading-4 font-normal md:text-[14px] md:leading-[14px]">{detail?.name}</div>
          </div>
        </div>
        <div className="text-right">
          <div className="text-[24px] leading-6 mb-[6px] font-semibold font-[ClashDisplay-Semibold] md:text-[20px] md:leading-[20px] md:mb-[3px]">
            <MarketCap unit="$" tonAmount={detail?.marketCap} />
          </div>
          <div className="text-[16px] leading-4 font-normal md:text-[14px] md:leading-[14px]">Market Cap</div>
        </div>
      </div>
      <div className="w-full h-[450px] md:h-[400px] rounded-[12px] overflow-hidden">{detail && <KLine coin={detail}></KLine>}</div>
    </div>
  );
}
