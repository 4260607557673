import { fetchCoinDetail } from '@/service/coin';
import { useRequest } from 'ahooks';

export function useFetchCoinDetail({ address }: { address?: string }) {
  const hook = useRequest(
    () => {
      return fetchCoinDetail(address!);
    },
    {
      ready: !!address,
    },
  );

  return hook;
}
