import constants from '@/constants';
import { G6Math } from '@/contract/utils/Math';
import { toNano } from '@ton/core';
import { useRequest } from 'ahooks';
import { useMemo } from 'react';
import { stonApiClient } from './useStonFiApi';

export type SwapableTokenAmountRes = {
  askAddress: string;
  askJettonWallet: string;
  askUnits: string;
  feeAddress: string;
  feePercent: string;
  feeUnits: string;
  minAskUnits: string;
  offerAddress: string;
  offerJettonWallet: string;
  offerUnits: string;
  poolAddress: string;
  priceImpact: string;
  routerAddress: string;
  slippageTolerance: string;
  swapRate: string;
};

type SwapableTokenAmountParams = {
  tokenAddress?: string;
  offerAddressIsTon: boolean;
  offerUnits: string;
  slippageTolerance: string;
};

export function useSwapableTokenAmount({
  tokenAddress,
  offerAddressIsTon,
  offerUnits,
  slippageTolerance,
}: SwapableTokenAmountParams) {
  const hook = useRequest(
    async (payload?: Partial<SwapableTokenAmountParams>) => {
      let addresses = {
        // buy address
        askAddress: tokenAddress!,
        // sell address
        offerAddress: constants.tonContractAddress,
      };

      if (!offerAddressIsTon) {
        addresses = {
          // buy address
          askAddress: constants.tonContractAddress,
          // sell address
          offerAddress: tokenAddress!,
        };
      }

      const res = await stonApiClient.simulateSwap({
        ...addresses,
        offerUnits: toNano(offerUnits).toString(),
        slippageTolerance: slippageTolerance || '0.01',
        ...payload,
      });

      return {
        ...res,
      };
    },
    {
      ready: !!tokenAddress && !!offerUnits,
      refreshDeps: [tokenAddress, offerAddressIsTon],
      throttleWait: 1000,
      pollingInterval: 3000,
      onSuccess() {
        // console.log('useSwapableTokenAmount data:', data);
      },
      onError(error) {
        console.error('useSwapableTokenAmount error:', error);
      },
    },
  );

  const priceImpactTooHigh = useMemo(() => {
    const data = hook?.data;

    return data && data.priceImpact && new G6Math(data.priceImpact).isGreaterThan('0.05');
  }, [hook?.data]);

  return { ...hook, priceImpactTooHigh };
}
