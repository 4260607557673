import { Avatar, List } from 'antd';
import { useMemo } from 'react';
import { useWindowResize } from '@/hooks/useWindowResize';
import { useFetchUserHeldCoins } from '../hooks/useFetchUserHeldCoins';
import { useParseRouterParam } from '@/hooks/router/useParseRouterParam';
import { useG6PoolMethods } from '@/contract/hooks/useG6PoolMethods';
import { useRequest } from 'ahooks';
import { TonValue } from '@/components/TonValue';
import { Coin } from '@/types/Coin';
import { G6Math } from '@/contract/utils/Math';

export function Item({ item, isMobile }: { item: Coin; isMobile: boolean }) {
  const { getJettonBalance } = useG6PoolMethods();

  const { param } = useParseRouterParam({ address: '' });

  const { data, loading } = useRequest(
    () => {
      return getJettonBalance(item.poolAddress, param?.address);
    },
    {
      ready: !!item.poolAddress && !!param?.address,
      cacheKey: `${item.poolAddress}-${param?.address}`,
      staleTime: 5000,
      throttleWait: 1000,
      onSuccess(res) {
        console.log('getJettonBalance------->', res);
      },
      onError(res) {
        console.log('getJettonBalance-error------->', res);
      },
    },
  );

  const jettonToTon = useMemo(() => {
    try {
      const val = new G6Math(data || 0n)
        .multiply(new G6Math(item.kTonIn9!).multiply(new G6Math(item.jettonSold!)))
        .divide(10n ** 27n)
        .divide(10n ** 9n)
        .toFixed(6);

      if (val === 'NaN') return '-';

      return val;
    } catch (err) {
      return '-';
    }
  }, [data, item.jettonSold, item.kTonIn9]);

  return (
    <List.Item className="h-full" style={{ borderBottomColor: 'rgba(225,225,225,0.1)', borderBottomWidth: '2px' }}>
      <List.Item.Meta
        avatar={
          <Avatar size={isMobile ? 36 : 48} style={{ marginRight: isMobile ? '-6px' : '' }} src={item.imageUri} />
        }
        title={
          <div className="text-[24px] md:text-[20px] leading-6 md:leading-5 font-[ClashDisplay-Semibold] text-white">
            <span className="mr-[3px]">
              <TonValue value={data || 0n} loading={loading}></TonValue>
            </span>
            <span className="opacity-50"> {item.name}</span>
          </div>
        }
        description={
          <span className="text-[16px] md:text-[14px] leading-4 md:leading-[14px] text-white mt-1.5">
            {jettonToTon} TON
          </span>
        }
      />
    </List.Item>
  );
}

export function CoinsHeld({ address }: { address: string }) {
  const { width } = useWindowResize();

  const { data, loading } = useFetchUserHeldCoins(address);

  const isMobile = useMemo(() => {
    return width <= 768;
  }, [width]);

  return (
    <List
      loading={loading}
      itemLayout="horizontal"
      dataSource={data?.data.docs}
      renderItem={(item) => <Item item={item} isMobile={isMobile} />}
    />
  );
}
