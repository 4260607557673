// import { Icon } from 'antd';
import { useFetchUserInfoByAddress } from '../hooks/useFetchUserInfoByAddress';
// import { fetchFollowers } from "@/service/follows";
import { useUserStore } from '@/stores/useUserStore';
import copyIcon from '@/assets/icon/icon_copy.svg';
import editIcon from '@/assets/icon/icon_edit.svg';
import { ProfileModal } from '@/components/ProfileModal';
import { message } from 'antd';
import { useParseRouterParam } from '@/hooks/router/useParseRouterParam';
import { goAccount } from '@/utils/exploer';
import { useFetchFollowing } from '../hooks/useFetchFellowing';
import { useMemo } from 'react';
import { useUnFollowUser } from '../hooks/useUnFollowUser';
import { useFollowUser } from '../hooks/useFollowUser';
import { toInternalAddress, useWallet } from '@/hooks/useWallet';
import { Button } from '@/components/Button';

{
  /* <CopyToClipboard
text={EQAddress}
onCopy={() => {
  message.success('Copied');
}}
>
<div>
  <img src={copyIcon} className="w-6 h-6 cursor-pointer hover:opacity-80" />
</div>
</CopyToClipboard> */
}

function Card({ children }: { children?: React.ReactNode }) {
  return <div className="rounded-[18px] border-2 border-solid border-white p-[18px]">{children}</div>;
}

export function AccountInfo() {
  // const address = useTonAddress();

  const { internalAddress, address } = useWallet();

  const { param } = useParseRouterParam({ address: '' });

  const { data } = useFetchUserInfoByAddress(param?.address);

  // find my following
  const { data: followingData, refresh, loading } = useFetchFollowing(internalAddress);

  const { profileModalOpen, setProfileModalOpen } = useUserStore();

  const { refreshAsync: unFollowUser, loading: unFollowUserLoading } = useUnFollowUser(param?.address, {
    onSuccess() {
      refresh();
    },
  });

  const { refreshAsync: followUser, loading: followUserLoading } = useFollowUser(param?.address, {
    onSuccess() {
      refresh();
    },
  });

  const isFollowing = useMemo(() => {
    const addresses = followingData?.following?.map((item) => toInternalAddress(item.user_address));

    return addresses?.includes(toInternalAddress(param.address));
  }, [followingData?.following, param.address]);

  const editProfile = () => {
    setProfileModalOpen(true);
  };

  // Copy
  const copyClick = async () => {
    try {
      await navigator.clipboard.writeText(data?.data.address || '');

      message.success('Copy success');
    } catch (error) {
      message.error('Copy failed');
    }
  };

  const toggleFollow = async () => {
    // Example logic to toggle follow/unfollow
    try {
      if (isFollowing) {
        await unFollowUser();
        message.success('UnFollow success');
      } else {
        await followUser();
        message.success('Follow success');
      }
    } catch (error) {
      message.error('Failed to toggle follow');
    }
  };

  return (
    <>
      <Card>
        <div className="grid gap-1">
          <div className="flex justify-between w-full gap-4 leading-6 item-start md:gap-0 relative">
            <div className="grid justify-items-start gap-1 w-auto md:block !md:w-[200px]">
              <div className="flex justify-between w-full align-end">
                <div className="font-semibold">
                  <div className="text-[24px] md:text-[20px] font-[ClashDisplay-Semibold]">
                    <span className="opacity-50">@</span>
                    <span>{data?.data.name}</span>
                  </div>
                </div>
              </div>
              <div className="flex gap-2 py-4 text-[16px] md:text-[14px] leading-5">{data?.data.bio}</div>
              <div className="flex gap-2 justify-self-start justify-items-start">
                <div className="items-center gap-1 text-sm mr-9">
                  <div className="font-[ClashDisplay-Semibold] text-[20px] md:text-[15px] mb-1">
                    {data?.data.followers || 0}
                  </div>
                  <div className="opacity-50 text-[16px] md:text-[14px]">Followers</div>
                </div>
                <div className="items-center gap-1 text-sm mr-9">
                  <div className="font-[ClashDisplay-Semibold] text-[20px] mb-1">{data?.data.likes_received || 0}</div>
                  <div className="opacity-50 text-[16px]">Likes</div>
                </div>
                <div className="items-center gap-1 text-sm">
                  <div className="font-[ClashDisplay-Semibold] text-[20px] mb-1">
                    {data?.data.mentions_received || 0}
                  </div>
                  <div className="opacity-50 text-[16px]">Mentiones</div>
                </div>
              </div>
            </div>
            <div className="shrink-0">
              {internalAddress === toInternalAddress(param.address) ? (
                <Button
                  onClick={() => {
                    editProfile();
                  }}
                >
                  <span className="block md:!hidden">EDIT PROFILE</span>
                  <img src={editIcon} className="hidden md:block md:hover:brightness-0" />
                </Button>
              ) : (
                address && (
                  <Button
                    className="md:absolute md:right-[0px] md:top-[0px]"
                    loading={loading || unFollowUserLoading || followUserLoading}
                    onClick={() => {
                      toggleFollow();
                    }}
                  >
                    <span className="md:hidden">{isFollowing ? 'FOLLOWING' : 'FOLLOW'}</span>
                  </Button>
                )
              )}
            </div>
          </div>
        </div>
      </Card>
      <div className="flex items-center justify-between my-5">
        <div className="text-[16px] rounded-[18px] border-2 border-solid border-white h-full p-[16px] flex items-center justify-between flex-1 mr-2.5">
          <div style={{ overflowWrap: 'anywhere' }}>{param.address || '-'}</div>
          <img src={copyIcon} className="w-6 h-6 cursor-pointer hover:opacity-80" onClick={copyClick} />
        </div>
        <div className="md:hidden">
          <div
            className="text-[15px] m-[-2px] font-semibold px-[30px] py-[17px] items-center bg-black rounded-full cursor-pointer hover:bg-white hover:text-black font-[ClashDisplay-Semibold]"
            onClick={() => {
              goAccount(param.address);
            }}
          >
            VIEW ON SCAN
          </div>
        </div>
      </div>
      <div
        className="hidden md:block text-[15px] font-semibold text-center font-[ClashDisplay-Semibold]"
        onClick={() => {
          goAccount(param.address);
        }}
      >
        VIEW ON SCAN
      </div>
      <ProfileModal open={profileModalOpen}></ProfileModal>
    </>
  );
}
