import { useTonAddress, useTonConnectUI } from '@tonconnect/ui-react';
import { getBlockchainClient } from '../utils/client';
import { Address } from '@ton/core';
import { useWallet } from '@/hooks/useWallet';

export const useAccountMethods = () => {
  const [tonConnectUI] = useTonConnectUI();

  // const address = useTonAddress();

  const { internalAddress } = useWallet();

  const checkTonConnect = () => {
    if (!tonConnectUI.connected) {
      console.error('not connected');

      return false;
    }
    return true;
  };

  const getBalance = async () => {
    if (!checkTonConnect()) {
      return;
    }

    const balance = (await getBlockchainClient()).getBalance(Address.parse(tonConnectUI.account!.address));

    return balance;
  };

  return {
    getBalance,
    ready: !!internalAddress,
  };
};
