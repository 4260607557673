import { useNavigate } from 'react-router-dom';

export function useAppRouter() {
  const navigate = useNavigate();

  const goHome = () => {
    navigate('/');
  };

  const goCreate = () => {
    navigate('/create');
  };

  const goCoinDetail = ({ poolAddress }: { poolAddress: string }) => {
    navigate(`/coin/${poolAddress}`);
  };

  const goProfile = (address: string) => {
    navigate(`/profile/${address}`);
  };

  return {
    goCreate,
    goCoinDetail,
    goHome,
    goProfile,
  };
}
