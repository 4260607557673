// import { Address } from '@ton/core';
import { G6Math } from '@/contract/utils/Math';
import { Address } from '@ton/core';

function trimTrailingZeros(str: string) {
  return str.replace(/(\.\d*?)0+$/, '$1').replace(/\.$/, '');
}

export function parseAddress(address?: string, { internal } = { internal: false }) {
  if (!address) {
    return '';
  }

  const addr = Address.parse(address);

  return addr.toString({ bounceable: internal });
}

export function shortenAddress(address: string) {
  return `${address.slice(0, 6)}...${address.slice(-4)}`;
}

export function displayAddress(address: string, { internal } = { internal: false }) {
  return shortenAddress(parseAddress(address, { internal }));
}
export function toRealValue<T extends boolean>(val: string | number, toNumber?: T): T extends true ? number : string {
  const value = trimTrailingZeros(new G6Math(val).divide(10n ** 27n).toFixed(18));

  if (toNumber) {
    return parseFloat(value) as any;
  }

  return value as any;
}
