import { useWallet } from '@/hooks/useWallet';
import { getG6Pool } from '@/contract/instance';
import { Address, fromNano } from '@ton/core';
import { useRequest } from 'ahooks';
import { useMemo } from 'react';

export function useCalcSellAmount({
  poolAddress,
  amount,
  ready,
}: {
  poolAddress?: string;
  amount: bigint;
  ready: boolean;
}) {
  const { provider } = useWallet({ address: poolAddress });

  const { data: value } = useRequest(
    async () => {
      console.log(poolAddress, amount);

      const res = await getG6Pool(Address.parse(poolAddress!)).getCalcSellAmount(provider!, amount);

      return res.ton_to_gain;
    },
    {
      ready: !!poolAddress && !!provider && ready,
      refreshDeps: [amount],
      debounceWait: 1000,
    },
  );

  const displayValue = useMemo(() => {
    try {
      return fromNano(value!);
    } catch (err) {
      return '';
    }
  }, [value]);

  return {
    value,
    displayValue,
  };
}
