import { Address } from '@ton/core';
import { displayAddress } from './ton';

export function isMobile(): boolean {
  return window.innerWidth <= 500;
}

export function openLink(href: string, target = '_self') {
  window.open(href, target, 'noreferrer noopener');
}

export function getDisplayName(str: string) {
  try {
    const isAddress = Address.isAddress(Address.parse(str));

    if (isAddress) {
      return displayAddress(str);
    } else {
      return str;
    }
  } catch (err) {
    return str;
  }
}

export function getQueryParam(key: string) {
  const urlObj = new URL(window.location.href);

  const params = new URLSearchParams(urlObj.search);

  const queryParams: any = {};

  for (const [key, value] of params.entries()) {
    queryParams[key] = value;
  }

  return queryParams[key];
}

export function getRandomBigInt(max = 2n ** 64n) {
  const high = Math.floor(Math.random() * 2 ** 32);

  const low = Math.floor(Math.random() * 2 ** 32);

  const randomBigInt = (BigInt(high) << 32n) + BigInt(low);

  return randomBigInt % max;
}
