import { LoadingOutlined } from '@ant-design/icons';
import { fromNano } from '@ton/core';
import { useMemo } from 'react';

export function TonValue({
  value,
  fixed,
  loading,
  suffix,
}: {
  value?: string | bigint;
  fixed?: number;
  loading?: boolean;
  suffix?: string;
}) {
  const val = useMemo(() => {
    try {
      if (!value) return '0';

      return fromNano(value);
    } catch {
      return '0';
    }
  }, [value]);

  const displayValue = useMemo(() => {
    return +val ? `${parseFloat((+val).toFixed(fixed || 2))} ${suffix || ''}` : '-';
  }, [fixed, suffix, val]);

  return loading ? <LoadingOutlined></LoadingOutlined> : <span title={val}>{displayValue}</span>;
}
