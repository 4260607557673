import Storage from '@/utils/Storage';

export const Key = {
  chatId: 'CHAT_ID',
};

const storage = new Storage({ prefix: 'G6', engine: 'sessionStorage' });

export class Temporary {
  static setChatId(chatId: string) {
    storage.set(Key.chatId, chatId);
  }

  static getChatId() {
    return storage.get<string>(Key.chatId);
  }

  static clearChatId() {
    storage.delete(Key.chatId);
  }
}
