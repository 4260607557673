import { useState, useEffect } from 'react';
import type { UploadProps } from 'antd';
import { Input, ConfigProvider, Upload, message, GetProp, Form, Button } from 'antd';
import pictureIcon from '@/assets/icon/icon_picture.svg';
import vectorBlackIcon from '@/assets/icon/icon_vector_black.svg';
import { useCommitComments } from '../hooks/useCommitComments';
import { Modal as AntModal } from 'antd';

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

type FieldType = {
  file_uri: string;
  text: string;
};

export function ReplyInput({
  mintId,
  defaultReply,
  onReplay,
}: {
  mintId: string;
  defaultReply: string;
  onReplay: () => void;
}) {
  const [uploadLoading, setLoading] = useState(false);

  const [isDisabled, setIsDisabled] = useState(false);

  const [form] = Form.useForm<FieldType>();

  const values = Form.useWatch([], form);

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setIsDisabled(true))
      .catch(() => setIsDisabled(false));
  }, [form, values]);

  useEffect(() => {
    if (defaultReply) {
      form.setFieldValue('text', `#${defaultReply} `);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultReply]);

  const getBase64 = (img: FileType, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result as string));
    reader.readAsDataURL(img);
  };

  const [imageUrl, setImageUrl] = useState<string | null>(null);

  const beforeUpload = (file: FileType) => {
    const isImage = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif';

    if (!isImage) {
      message.error('You can only upload JPG/PNG/GIF file!');
    }

    const isLt10M = file.size / 1024 / 1024 < 10;

    if (!isLt10M) {
      AntModal.info({
        zIndex: 10000,
        centered: true,
        content: 'Exceeding 10M will be compressed',
        className: 'error-modal',
        icon: null,
      });
    }

    return isImage;
  };

  const handleImgChange: UploadProps['onChange'] = (info) => {
    if (info.file.status === 'uploading') {
      setLoading(true);

      return;
    }

    if (info.file.status === 'error') {
      setLoading(false);

      return message.error('Failed to upload image');
    }

    if (info.file.status === 'done') {
      form.setFieldValue('file_uri', info.file.response.data.url);

      getBase64(info.file.originFileObj as FileType, (url) => {
        setLoading(false);

        setImageUrl(url);
      });
    }
  };

  function reset() {
    setLoading(false);
    setImageUrl('');
    form.resetFields();
  }

  const SendReply = (
    <div className="w-[106px] mr-2 flex items-center justify-between">
      <Form<FieldType> layout="vertical" form={form} autoComplete="off" className="pt-1">
        <Form.Item name="file_uri" className="m-0">
          <ConfigProvider
            wave={{ disabled: true }}
            theme={{
              components: {
                Upload: {
                  colorBorder: '#ffffff00',
                  colorBgMask: '#ffffff00',
                },
              },
            }}
          >
            <Upload
              className="relative"
              name="file"
              showUploadList={false}
              multiple={false}
              action={`${process.env.REACT_APP_API}/ipfs/file`}
              beforeUpload={beforeUpload}
              onChange={handleImgChange}
            >
              <img src={imageUrl ? imageUrl : pictureIcon} />
            </Upload>
          </ConfigProvider>
        </Form.Item>
      </Form>
      <ConfigProvider
        wave={{ disabled: true }}
        theme={{
          components: {
            Button: {
              borderColorDisabled: '#ffffff00',
              defaultBorderColor: '#ffffff00',
              defaultHoverBorderColor: '#ffffff00',
              contentFontSize: 16,
              defaultActiveBg: '#ffffff00',
              defaultColor: '#000',
              colorText: '#000',
            },
          },
        }}
      >
        <Button
          loading={uploadLoading}
          icon={<img className="brightness-0" src={vectorBlackIcon} />}
          disabled={!isDisabled}
          type="text"
          size="small"
          iconPosition="end"
          onClick={() => {
            submitComment();
          }}
        >
          <span className="text-black text-[16px] font-semibold font-[ClashDisplay-Semibold] p-0">POST</span>
        </Button>
      </ConfigProvider>
    </div>
  );

  const submitComment = () => {
    const field = form.getFieldsValue();

    if (!field.text) return;

    runCommit({ mint: mintId, text: field.text, file_uri: field.file_uri });
  };

  const { runAsync: runCommit } = useCommitComments({
    onCommitSuccess() {
      onReplay();
      form.resetFields();

      reset();
    },
  });

  return (
    <ConfigProvider
      wave={{ disabled: true }}
      theme={{
        components: {
          Input: {
            colorBgContainer: '#fff',
            activeBg: '#fff',
            addonBg: '#fff',
            activeBorderColor: '#ffffff00',
            colorSplit: '#ffffff00',
            colorBorder: '#ffffff00',
            hoverBorderColor: '#ffffff00',
            colorText: '#000',
            borderRadius: 12,
            controlHeight: 48,
            fontSize: 16,
            colorTextPlaceholder: '#00000050',
          },
        },
      }}
    >
      <Form<FieldType> layout="vertical" name="validateOnly" form={form} autoComplete="off">
        <Form.Item name="text">
          <Input className="w-full mt-[18px] md:pb-[100px]" addonAfter={SendReply} placeholder="Your reply" />
        </Form.Item>
      </Form>
    </ConfigProvider>
  );
}
