import request from '@/request';
import { Comment } from '@/types/Comment';
import { ListQuery } from '@/types/App';

export function fetchComments({ current, size, mintId }: ListQuery<{ mintId?: string }>) {
  return request.get(`/comments/mint/${mintId}`, { current, size });
}

export function fetchMyComments({ current, size }: ListQuery) {
  return request.get(`/comments/my`, { current, size });
}

export function commitComments(payload: Partial<Comment>) {
  return request.post<Comment>(`/comments`, payload);
}

export function likeComments(id: string) {
  return request.put<{ token: string }>(`/comments/like/${id}`, null);
}

export function unlikeComments(id: string) {
  return request.put<{ token: string }>(`/comments/unlike/${id}`, null);
}
