import request from '@/request';

export function fetchTonPrice() {
  return request.get<{
    markets: {
      market: string;
      usd_price: number;
      last_date_update: number;
    }[];
  }>(`/ton-price`, null);
}

export function fetchLaunchConf() {
  return request.get<{
    initLaunchJettonAmount: number;
    jettonAmountToReserve: number;
    kTonIn9: number;
    decimals: number;
    kTonInDenominator: number;
  }>(`/config/poolinit`, null);
}
