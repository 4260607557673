import { User } from '@/types/User';
import { fetchUserInfo } from '@/service/user';
import { useUserStore } from '@/stores/useUserStore';
import { useTonAddress } from '@tonconnect/ui-react';
import { useRequest } from 'ahooks';

export function useFetchUserInfo({
  onSuccess,
  onError,
  onExpired,
}: {
  onSuccess?: (data: User | null) => void;
  onError?: (error: any) => void;
  onExpired?: () => void;
}) {
  const { token, setInfo } = useUserStore();

  const userFriendlyAddress = useTonAddress();

  const { data, loading } = useRequest(
    () => {
      return fetchUserInfo();
    },
    {
      ready: !!token && !!userFriendlyAddress,
      onSuccess(data) {
        onSuccess?.(data.data);

        setInfo(data.data);
      },
      onError(error) {
        console.log('error', error);

        onError?.(error);

        onExpired?.();
      },
      refreshDeps: [token, userFriendlyAddress],
    },
  );

  return {
    data,
    loading,
  };
}
