import { unFollow } from '@/service/follows';
import { useRequest } from 'ahooks';

// 取消关注用户
export function useUnFollowUser(
  address: string,
  {
    onSuccess,
  }: {
    onSuccess?: () => void;
  } = {},
) {
  return useRequest(
    async () => {
      const dto = { address: address };

      const res = await unFollow(dto);

      return res.data;
    },
    {
      manual: true,
      onSuccess() {
        onSuccess?.();
      },
    },
  );
}
