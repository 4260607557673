import { useAppRouter } from '@/hooks/router/useAppRouter';
import { shortenAddress } from '@/utils/ton';
import { Button } from '@/components/Button';
import { useTonConnectUI, TonConnectButton } from '@tonconnect/ui-react';
import { Dropdown, MenuProps } from 'antd';
// import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useResponsive } from '@/hooks/useResponsive';
import { useContext } from 'react';
import { GlobalDataContext } from '@/context/GlobalDataContext';
import classNames from 'classnames';
import { useWallet } from '@/hooks/useWallet';
import { useUserStore } from '@/stores/useUserStore';

export function ConnectButton({
  children,
  className,
  block,
}: {
  children?: React.ReactNode;
  className?: string;
  block?: boolean;
}) {
  const { isMobile } = useResponsive();

  // const address = useTonAddress();

  const { internalAddress, userFriendlyAddress } = useWallet();

  const [tonConnectUI] = useTonConnectUI();

  const { loginOut } = useUserStore();

  // const message = useAppMessage();

  function showConnect() {
    tonConnectUI.openModal();
  }

  function disconnect() {
    tonConnectUI.connector.disconnect();
    loginOut();
    refreshProof?.();
  }

  const { goProfile } = useAppRouter();

  const { walletReady, refreshProof } = useContext(GlobalDataContext);

  const items: MenuProps['items'] = [
    {
      key: '1',
      dashed: true,
      label: (
        <div
          onClick={() => {
            goProfile(userFriendlyAddress);
          }}
        >
          Account
        </div>
      ),
    },
    // {
    //   key: '2',
    //   label: (
    //     <CopyToClipboard
    //       text={EQAddress}
    //       onCopy={() => {
    //         message.success('Copied');
    //       }}
    //     >
    //       <div>Copy</div>
    //     </CopyToClipboard>
    //   ),
    // },
    {
      key: '3',
      label: <div onClick={disconnect}>Disconnect</div>,
    },
  ];

  return (
    <>
      {internalAddress ? (
        children ? (
          children
        ) : (
          <Dropdown
            overlayClassName="g6-app-dropdown"
            menu={{ items }}
            overlayStyle={isMobile ? { top: '90px' } : { top: '112px' }}
          >
            <Button loading={walletReady} className="p-[14px] pr-[18px] md:p-[10px] md:pr-[12px] md:h-9">
              <img src={'/static/images/ton_symbol.svg'} className="md:w-4" />
              <span className="ml-[-2px] md:text-[12px]">{shortenAddress(userFriendlyAddress)}</span>
            </Button>
          </Dropdown>
        )
      ) : children ? (
        <Button
          loading={walletReady}
          block={block}
          className={classNames('p-[14px] pr-[18px] group', className)}
          onClick={showConnect}
        >
          <span className="ml-[-2px] font-ClashDisplay-Semibold">CONNECT WALLET</span>
        </Button>
      ) : (
        <TonConnectButton />
      )}
    </>
  );
}
