import { ConfigProvider } from 'antd';
import { useResponsive } from '@/hooks/useResponsive';

export const FormTheme: React.FC<{ children: any }> = ({ children }) => {
  const { isMobile } = useResponsive();

  return (
    <ConfigProvider
      theme={{
        components: {
          Input: {
            controlHeight: 48,
            colorBgContainer: '#EBEBEB',
            activeBg: '#EBEBEB',
            borderRadius: 12,
            colorPrimary: '#EBEBEB',
            hoverBorderColor: '#EBEBEB',
            colorTextPlaceholder: 'rgba(255,255,255,.45)',
            controlOutlineWidth: 2,
            lineWidth: 1,
            fontFamily: 'Inter',
          },
          Form: {
            labelFontSize: isMobile ? 15 : 20,
            fontFamily: 'ClashDisplay-Semibold',
            fontWeightStrong: 600,
            verticalLabelPadding: '0 0 9px',
            lineHeight: 1,
            itemMarginBottom: isMobile ? 18 : 30,
          },
        },
        token: {
          fontFamily: 'ClashDisplay-Semibold',
          controlOutline: 'transparent',
        },
      }}
    >
      {children}
    </ConfigProvider>
  );
};
