import { fetchG6List } from '@/service/coin';
import { useRequest } from 'ahooks';

export function useFetchG6List({
  createdTimestampEnd,
  createdTimestampStart,
  nsfw,
  search,
  order,
  sortBy,
  size,
}: {
  createdTimestampEnd?: number;
  createdTimestampStart?: number;
  nsfw?: boolean;
  search?: string;
  order?: string;
  sortBy?: string;
  size: number;
}) {
  const hook = useRequest(
    () => {
      return fetchG6List({
        current: 1,
        size: size,
        createdTimestampEnd,
        createdTimestampStart,
        nsfw,
        search,
        order,
        sortBy,
      });
    },
    {
      refreshDeps: [createdTimestampEnd, createdTimestampStart, nsfw, search, order, sortBy, size],
    },
  );

  return hook;
}
