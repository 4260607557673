import { User } from '@/types/User';
import { useRequest } from 'ahooks';
import { useAppMessage } from '../../useAppMessage';
import { register } from '@/service/user';

export function useRegister({ onRegisterSuccess }: { onRegisterSuccess?: (data: User) => void }) {
  const message = useAppMessage();

  const { run, runAsync, loading } = useRequest(
    (data: Partial<User>) => {
      return register(data);
    },
    {
      manual: true,
      onSuccess(data) {
        onRegisterSuccess?.(data.data);
      },
      onError(e) {
        console.log(e);

        message.error(e.message || 'Failed to register');
      },
    },
  );

  return {
    run,
    runAsync,
    loading,
  };
}
