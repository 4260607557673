import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper';
import { Autoplay } from 'swiper/modules';
import 'swiper/css';
import { WebSocketContext } from '@/context/WebSocketContext';
import { useContext, useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { getDisplayName } from '@/utils';
import { MessageData } from '@/types/Message';
import { useFetchInitialBroadcast } from './hooks/useFetchInitialBroadcast';
import { take } from 'lodash';

function formatTime(date: any) {
  return dayjs(date).format('YYYY-MM-DD');
}

function parseMessage(data: MessageData) {
  function calcWidth(data: string) {
    return data.length * 9;
  }

  function parseDisplayName() {
    return getDisplayName(data.data.creator);
  }

  // TODO:
  switch (data.type) {
    case 'launch':
      return {
        id: data.data._id,
        width: calcWidth(`${parseDisplayName()} created ${data.data.symbol} on ${formatTime(data.data.createdAt)}`),
        data: (
          <div className="flex justify-start items-center text-black font-normal">
            <a className="font-semibold" target="_blank" href={`/profile/${data.data.address}`} rel="noreferrer">
              {parseDisplayName()}
            </a>
            <span className="mx-[5px]">created</span>
            <a
              className="font-semibold mr-[5px]"
              target="_blank"
              href={`/coin/${data.data.poolAddress}`}
              rel="noreferrer"
            >
              {data.data.symbol}
            </a>
            on {formatTime(data.data.createdAt)}
          </div>
        ),
      };
    case 'buy':
      return {
        width: 600,
        id: data.data._id,
        data: (
          <div className="flex justify-start items-center text-black font-normal">
            <a className="font-semibold" target="_blank" href={`/profile/${data.data.address}`} rel="noreferrer">
              {parseDisplayName()}
            </a>
            <span className="mx-[5px]">bought</span>- TON on
            <a className="font-semibold ml-5" target="_blank" href={`/coin/${data.data.poolAddress}`} rel="noreferrer">
              {data.data.symbol}{' '}
            </a>
          </div>
        ),
      };
    case 'sell':
      return {
        width: 600,
        id: data.data._id,
        data: (
          <div className="flex justify-start items-center text-black font-normal">
            <a className="font-semibold" target="_blank" href={`/profile/${data.data.address}`} rel="noreferrer">
              {parseDisplayName()}
            </a>
            <span className="mx-[5px]">sold</span>
            <a className="font-semibold ml-5" target="_blank" href={`/coin/${data.data.poolAddress}`} rel="noreferrer">
              {data.data.symbol}
            </a>
          </div>
        ),
      };
    default:
      return;
  }
}

export function InformationCarousel() {
  const { onMessage } = useContext(WebSocketContext);

  const [socketData, setSocketData] = useState<{ data: any; id: string; width: number }[]>([]);

  const swiperRef = useRef<SwiperCore | null>(null);

  useFetchInitialBroadcast({
    onSuccess(res) {
      setSocketData(res.map(parseMessage).filter((v) => !!v) as any);

      swiperRef.current?.update();
    },
  });

  useEffect(() => {
    const event = onMessage?.((e) => {
      const item = parseMessage(e);

      if (!item) return;

      setSocketData((prev) => {
        return take([item, ...prev], 50);
      });
    });

    return () => {
      event?.();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   swiperRef.current?.update();
  // }, [socketData]);

  return (
    <div className="w-full h-full bg-white text-black flex justify-start items-center px-[20px]">
      <Swiper
        rer={swiperRef}
        spaceBetween={10}
        slidesPerView="auto"
        centeredSlides={false}
        speed={1000}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
          waitForTransition: false,
        }}
        loop={true}
        modules={[Autoplay]}
      >
        {socketData.map((item, i) => (
          <SwiperSlide
            key={`${item.id}-${i}`}
            style={{
              width: `${item.width}px`,
            }}
          >
            <div
              style={{
                width: `${item.width}px`,
              }}
            >
              {item.data}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
