import { Table, ConfigProvider, Button } from 'antd';
import { useFetchCoinTrade } from '../hooks/useFetchCoinTrade';
import { useParseRouterParam } from '@/hooks/router/useParseRouterParam';
import { ColumnType } from 'antd/es/table';
import { fromNano } from '@ton/core';
import { displayAddress } from '@/utils/ton';
import { useMemo, useContext } from 'react';
import { CoinDetailContext } from '../context/CoinDetailContext';
import { TradeHistoryItem } from '@/service/trade';
import { goTx } from '@/utils/exploer';
import { slice } from 'lodash';
import dayjs from 'dayjs';
import { useAppRouter } from '@/hooks/router/useAppRouter';
import { useResponsive } from '@/hooks/useResponsive';

function parseNano(val: number) {
  try {
    return fromNano(val);
  } catch (e) {
    return '-';
  }
}

function formatTime(time: number) {
  return dayjs.unix(time).format('YYYY-MM-DD HH:mm:ss');
}

export function TradesTable() {
  const { param } = useParseRouterParam({ address: '' });

  const { data, loading } = useFetchCoinTrade({ address: param.address });

  const { detail } = useContext(CoinDetailContext);

  const { goProfile } = useAppRouter();

  const { isMobile } = useResponsive();

  const columns = useMemo<ColumnType<TradeHistoryItem>[]>(() => {
    return [
      {
        title: 'Account',
        dataIndex: 'address',
        key: 'address',
        width: 140,
        render(text, record) {
          return (
            <span
              className="cursor-pointer break-all"
              onClick={() => {
                goProfile(record.user);
              }}
            >
              {displayAddress(record.user)}
            </span>
          );
        },
      },
      {
        title: 'Type',
        width: 60,
        render(text, record) {
          return <span>{record.isBuy ? 'Buy' : 'Sell'}</span>;
        },
      },
      {
        title: detail?.symbol,
        render(text, record) {
          return <span className="break-all">{(+parseNano(record.jettonAmount)).toFixed(2)}</span>;
        },
      },

      {
        title: 'TON',
        render(text, record) {
          return <span className="break-all">{parseNano(record.tonAmount)}</span>;
        },
      },

      {
        title: 'Date',
        width: 180,
        render(text, record) {
          return <span className="break-all">{formatTime(record.timestamp)}</span>;
        },
      },

      {
        title: 'Transaction',
        dataIndex: 'transaction',
        key: 'transaction',
        width: 80,
        render(text, record) {
          return (
            <Button
              type="text"
              onClick={() => {
                goTx(record.hash);
              }}
            >
              {slice(record.hash, -4).join('')}
            </Button>
          );
        },
      },
    ];

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ConfigProvider
      wave={{ disabled: true }}
      theme={{
        components: {
          Table: {
            colorBgContainer: '#ffffff00',
            borderColor: '#ffffff10',
            headerBg: '#ffffff00',
            cellFontSize: 14,
            cellPaddingBlock: 15,
            cellPaddingInline: 0,
            footerBg: '#ffffff00',
            headerColor: '#ffffff50',
            headerSplitColor: '#ffffff00',
            lineWidth: 2,
          },
        },
      }}
    >
      <Table
        columns={columns as any}
        loading={loading}
        scroll={{ x: isMobile ? 800 : undefined }}
        dataSource={data?.data?.docs}
        className="mt-[14px] tradeTable md:mt-0"
        pagination={{ position: ['none', 'none'] }}
      />
    </ConfigProvider>
  );
}
