// import discordIcon from '@/assets/icon/icon_discord.svg';
import globalIcon from '@/assets/icon/icon_global.svg';
import twitterIcon from '@/assets/icon/icon_twitter.svg';
import telegramIcon from '@/assets/icon/telegram.svg';
import { goAccount } from '@/utils/exploer';
import { displayAddress } from '@/utils/ton';
import { MarketCap } from '@/components/CoinCard';
import { Skeleton } from 'antd';
import classNames from 'classnames';
import { useCoinDetailContext } from '../context/CoinDetailContext';

export function CoinDetail() {
  const { detail, loading } = useCoinDetailContext();

  return (
    <Skeleton active loading={loading}>
      <div>
        <div className="flex">
          <div className="flex-1">
            <div className="font-semibold text-[18px] leading-[18px] pb-[9px] font-[ClashDisplay-Semibold]">
              {detail?.name}
            </div>
            <div className="text-[14px] leading-[14px] pb-[9px]">
              <span className="text-[#ffffff50] mr-[3px]">Ticker:</span>
              {detail?.symbol}
            </div>
            <div className="text-[14px] leading-[14px] pb-[9px]">
              <span className="text-[#ffffff50] mr-[3px]">Market Cap:</span>
              <MarketCap unit="$" tonAmount={detail?.marketCap} />
            </div>
            <div className="text-[14px] leading-[14px] pb-[9px]">
              <span className="text-[#ffffff50] mr-[3px]">Created By:</span>
              <a
                className="underline hover:text-white hover:underline"
                target="_blank"
                href={`/profile/${detail?.creator}`}
                rel="noreferrer"
              >
                {displayAddress(detail?.creator || '')}
              </a>
            </div>

            {detail?.address && (
              <div className="text-[14px] leading-[14px] pb-[9px]">
                <span className="text-[#ffffff50] mr-[3px]">Jetton:</span>
                <span
                  className="underline hover:text-white hover:underline cursor-pointer"
                  onClick={() => {
                    goAccount(detail?.address || '');
                  }}
                >
                  {displayAddress(detail?.address || '', { internal: true })}
                </span>
              </div>
            )}

            {detail?.poolAddress && (
              <div className="text-[14px] leading-[14px] pb-[9px]">
                <span className="text-[#ffffff50] mr-[3px]">Pool address:</span>
                <span
                  className="underline hover:text-white hover:underline cursor-pointer"
                  onClick={() => {
                    goAccount(detail?.poolAddress || '');
                  }}
                >
                  {displayAddress(detail?.poolAddress || '', { internal: true })}
                </span>
              </div>
            )}

            {detail?.stonfiPool && (
              <div className="text-[14px] leading-[14px] pb-[9px]">
                <span className="text-[#ffffff50] mr-[3px]">STON.fi:</span>
                <a
                  className="underline hover:text-white hover:underline"
                  target="_blank"
                  href={detail!.stonfiPool}
                  rel="noreferrer"
                >
                  Go to STON.fi
                </a>
              </div>
            )}

            <div className="flex gap-[12px] mt-[12px] mb-[18px]">
              <a
                href={detail?.telegram}
                target="_blank"
                rel="noreferrer"
                className={classNames({
                  hidden: !detail?.telegram,
                })}
              >
                <img src={telegramIcon} className="w-6 h-6" />
              </a>

              <a
                href={detail?.website}
                className={classNames({
                  hidden: !detail?.website,
                })}
                target="_blank"
                rel="noreferrer"
              >
                <img src={globalIcon} className="w-6 h-6" />
              </a>

              <a
                className={classNames({
                  hidden: !detail?.twitter,
                })}
                href={detail?.twitter}
                target="_blank"
                rel="noreferrer"
              >
                <img src={twitterIcon} className="w-6 h-6" />
              </a>
            </div>
          </div>
          <img src={detail?.imageUri} className="h-[100px] max-w-[130px]" />
        </div>
        <div className="text-sm break-all">{detail?.description}</div>
      </div>
    </Skeleton>
  );
}
