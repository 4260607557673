import { GlobalDataContext } from '@/context/GlobalDataContext';
import { useWallet } from '@/hooks/useWallet';
import { getRandomBigInt } from '@/utils';
import { fetchLaunchConf } from '@/service/common';
import { toNano } from '@ton/core';
import { SendTransactionRequest } from '@tonconnect/sdk';
import { useTonConnectUI } from '@tonconnect/ui-react';
import { useContext } from 'react';
import { getLaunchParam } from '../action/launch';
import { G6Config } from '../G6Config';
import { BlockchainClient, getBlockchainClient } from '../utils/client';
import { G6Math } from '../utils/Math';
import { G6Router } from '../wrappers/G6Router';

export const useG6RouterMethods = () => {
  const [tonConnectUI] = useTonConnectUI();

  const { provider } = useWallet({ address: process.env.REACT_APP_G6_ROUTER_ADDRESS });

  const { balance } = useContext(GlobalDataContext);

  const handleLaunch = async (payload: {
    name: string;
    symbol: string;
    description: string;
    image: string;
    website?: string;
    twitter?: string;
    telegram?: string;
    tonAmountToPayForInitBuy?: bigint;
  }) => {
    if (!tonConnectUI.connected) {
      return Promise.reject(new Error('not connected'));
    }

    try {
      const { name, symbol, description, image, website, twitter, telegram } = payload;

      const feeResult = await G6Router.getLaunchTxFee(provider!, {
        tonAmountToPayForInitBuy: payload.tonAmountToPayForInitBuy || 0n,
      });

      // const feeResult = await BlockchainClient.runMethod(G6Config.G6RouterAddress, 'get_launch_tx_fee');

      const fee = feeResult.fee;

      const { data } = await fetchLaunchConf();

      const { body: launchBody } = getLaunchParam({
        name,
        symbol,
        description,
        image,
        website,
        twitter,
        telegram,
        decimals: data.decimals.toString(),
        k_ton_in_denominator: BigInt(data.kTonInDenominator),
        jetton_amount_to_reserve: BigInt(data.jettonAmountToReserve),
        init_launch_jetton_amount: BigInt(data.initLaunchJettonAmount),
        k_ton_in_9: BigInt(data.kTonIn9),
        ton_to_pay_for_init_buy: payload.tonAmountToPayForInitBuy,
        query_id: getRandomBigInt(),
      });

      if (new G6Math(fee).isGreaterThan(balance || 0n)) {
        return Promise.reject(new Error('Insufficient balance'));
      }

      const transaction: SendTransactionRequest = {
        messages: [
          {
            address: G6Config.G6RouterAddress.toString(), // destination address
            amount: fee.toString(), //Toncoin in nanotons
            payload: launchBody.toBoc().toString('base64'),
          },
        ],
        validUntil: Math.floor(Date.now() / 1000) + 360,
      };

      const launchResult = await tonConnectUI.sendTransaction(transaction);
      console.log('launchResult:', launchResult);
    } catch (error) {
      console.error('error:', error);

      return Promise.reject(error);
    }
  };

  return {
    handleLaunch,
  };
};
