import {
  Address,
  beginCell,
  Cell,
  Contract,
  contractAddress,
  ContractProvider,
  Sender,
  SendMode,
  Slice,
  toNano,
} from '@ton/core';
import { Transaction } from '@ton/ton';
import { decodeJettonContent } from '../scripts/jettonContentDecode';
import { decodeJettonSupplementContent } from '../scripts/jettonSupplementContentDecode';

export enum ReportOpCode {
  unknown = 0,
  reportLaunch = 0x2e97a07f,
  reportBuy = 0x592f61e7,
  reportBuyFail = 0x9a04373b,
  reportSell = 0x6b07efda,
  reportSellFail = 0x151dc92d,
  reportGraduate = 0xea2b2279,
}

export type G6RouterConfig = {
  pool_code: Cell;
  jetton_wallet_code: Cell;
  jetton_minter_code: Cell;
  ston_fi_router_address: Address;
  ston_fi_jetton_pton_address: Address;
  fee_collector_address: Address;
  k_ton_numerator_should_be?: bigint;
  k_ton_denominator_should_be?: bigint;
  init_launch_jetton_amount_should_be?: bigint;
  jetton_amount_to_reserve_should_be?: bigint;
};

export function g6RouterConfigToCell(pool_ctx_id: number, router_admin_address: Address): Cell {
  return beginCell().storeUint(pool_ctx_id, 32).storeAddress(router_admin_address).storeUint(0, 1).endCell();
}

export const Opcodes = {
  op_init: 0x29c102d1,
  op_launch: 0xe3aeb7a1,
  op_take_balance: 0x2df08797,
};

export class G6Router implements Contract {
  constructor(
    readonly address: Address,
    readonly init?: { code: Cell; data: Cell },
  ) {}

  static createFromAddress(address: Address) {
    return new G6Router(address);
  }

  static createFromConfig(config: { pool_ctx_id: number; router_admin_address: Address }, code: Cell, workchain = 0) {
    const data = g6RouterConfigToCell(config.pool_ctx_id, config.router_admin_address);
    const init = { code, data };
    return new G6Router(contractAddress(workchain, init), init);
  }

  async sendDeploy(provider: ContractProvider, via: Sender, value: bigint, config: G6RouterConfig) {
    await provider.internal(via, {
      value,
      sendMode: SendMode.PAY_GAS_SEPARATELY,
      body: beginCell()
        .storeUint(Opcodes.op_init, 32)
        .storeRef(
          beginCell()
            .storeAddress(config.ston_fi_router_address)
            .storeAddress(config.ston_fi_jetton_pton_address)
            .storeAddress(config.fee_collector_address)
            .endCell(),
        )
        .storeRef(
          beginCell()
            .storeUint(config.k_ton_numerator_should_be ?? 0n, 32) //k_ton_numerator_should_be
            .storeUint(config.k_ton_denominator_should_be ?? 0n, 8) //k_ton_denominator_should_be
            .storeCoins(config.init_launch_jetton_amount_should_be ?? 0n) //init_launch_jetton_amount_should_be
            .storeCoins(config.jetton_amount_to_reserve_should_be ?? 0n) //jetton_amount_to_reserve_should_be
            .storeRef(config.pool_code)
            .storeRef(config.jetton_wallet_code)
            .storeRef(config.jetton_minter_code)
            .endCell(),
        )
        .endCell(),
    });
  }

  async sendLaunch(
    provider: ContractProvider,
    via: Sender,
    value: bigint,
    args: {
      jetton_content: Cell;
      k_ton_in_9?: bigint;
      k_ton_in_denominator?: bigint;
      init_launch_jetton_amount?: bigint;
      jetton_amount_to_reserve?: bigint;
      jetton_supplement_content: Cell;
      ton_to_pay_for_init_buy?: bigint;
      query_id?: bigint;
    },
  ) {
    await provider.internal(via, {
      value: value,
      sendMode: SendMode.PAY_GAS_SEPARATELY,
      body: beginCell()
        .storeUint(Opcodes.op_launch, 32)
        .storeRef(args.jetton_content)
        .storeInt(args.k_ton_in_9 ?? 15000n, 32)
        .storeInt(args.k_ton_in_denominator ?? 27, 8)
        .storeCoins(args.init_launch_jetton_amount ?? toNano(1_000_000_000n))
        .storeCoins(args.jetton_amount_to_reserve ?? toNano(1_000_000_000n) / 3n)
        .storeRef(args.jetton_supplement_content)
        .storeCoins(args.ton_to_pay_for_init_buy ?? 0n)
        .storeUint(args.query_id ?? 0n, 64)
        .endCell(),
    });
  }

  async sendTakeBalance(provider: ContractProvider, via: Sender, value: bigint) {
    await provider.internal(via, {
      value: value,
      sendMode: SendMode.PAY_GAS_SEPARATELY,
      body: beginCell().storeUint(Opcodes.op_take_balance, 32).endCell(),
    });
  }

  async getPoolAddress(provider: ContractProvider, pool_index: bigint) {
    let res = await provider.get('get_pool_address', [{ type: 'int', value: pool_index }]);

    let pool_address = res.stack.readAddress();
    return {
      pool_address,
    };
  }

  async getRouterData(provider: ContractProvider) {
    let res = await provider.get('get_router_data', []);
    let router_ctx_id = res.stack.readBigNumber();
    let pool_code = res.stack.readCell();
    let jetton_wallet_code = res.stack.readCell();
    let jetton_minter_code = res.stack.readCell();
    let ston_fi_router_address = res.stack.readAddress();
    let ston_fi_jetton_pton_address = res.stack.readAddress();
    let fee_collector_address = res.stack.readAddress();
    let router_admin_address = res.stack.readAddress();
    return {
      router_ctx_id,
      pool_code,
      jetton_wallet_code,
      jetton_minter_code,
      fee_collector_address,
      router_admin_address,
    };
  }

  static async getLaunchTxFee(
    provider: ContractProvider,
    opts: {
      tonAmountToPayForInitBuy: bigint;
    },
  ) {
    let res = await provider.get('get_launch_tx_fee', [{ type: 'int', value: opts.tonAmountToPayForInitBuy }]);
    let fee = res.stack.readBigNumber();
    return {
      fee,
    };
  }

  async getTakeBalanceAmount(provider: ContractProvider) {
    let res = await provider.get('get_take_balance_amount', []);
    let amount = res.stack.readBigNumber();
    return {
      amount,
    };
  }

  async getCalInitBuyAmount(
    provider: ContractProvider,
    opts: {
      kTonIn9?: bigint;
      kTonDenominator?: bigint;
      tonToPay: bigint;
    },
  ) {
    let res = await provider.get('cal_init_buy_amount', [
      { type: 'int', value: opts.kTonIn9 ?? 15000n },
      { type: 'int', value: opts.kTonDenominator ?? 27n },
      { type: 'int', value: opts.tonToPay },
    ]);
    let jettonToGain = res.stack.readBigNumber();
    return {
      jettonToGain,
    };
  }

  async getCalInitBuyAmountByJetton(
    provider: ContractProvider,
    opts: {
      kTonIn9?: bigint;
      kTonDenominator?: bigint;
      jettonAmountToGain: bigint;
    },
  ) {
    let res = await provider.get('cal_init_buy_amount_by_jetton', [
      { type: 'int', value: opts.kTonIn9 ?? 15000n },
      { type: 'int', value: opts.kTonDenominator ?? 27n },
      { type: 'int', value: opts.jettonAmountToGain },
    ]);
    let needTon = res.stack.readBigNumber();
    return {
      needTon,
    };
  }

  //=====================================================

  //tx是对fee collector过滤出来的
  static parseTransactions(txs: Array<Transaction>, srcAddress: Address, filter: boolean = true) {
    let parsed = txs.map((tx) => G6Router.parseTransaction(tx, srcAddress)).flat();

    return filter ? parsed.filter((tx) => tx.op !== ReportOpCode.unknown) : parsed;
  }

  static parseTransaction(tx: Transaction, srcAddress: Address) {
    let data = null;
    let op = ReportOpCode.unknown;

    if (!!tx.inMessage) {
      let [maybeOP, maybeData] = G6Router.parseMessageBody(tx.inMessage.body);

      if (maybeOP != ReportOpCode.unknown) {
        //found
        //check genuine
        let src = tx.inMessage.info.src as Address;
        if (src.equals(srcAddress)) {
          op = maybeOP;
          data = maybeData;
        }
      }
    }

    return {
      op,
      data,
      rawTx: tx,
    };
  }

  static parseMessageBody(body: Cell): [ReportOpCode, any] {
    let data = null;
    let op = ReportOpCode.unknown;

    let bs = body.beginParse();

    if (32 <= bs.remainingBits) {
      op = bs.loadUint(32);

      if (op == ReportOpCode.reportLaunch) {
        data = G6Router.parseReportLaunch(bs);
      } else if (op == ReportOpCode.reportBuy) {
        data = G6Router.parseReportBuy(bs);
      } else if (op == ReportOpCode.reportBuyFail) {
        data = G6Router.parseReportBuyFail(bs);
      } else if (op == ReportOpCode.reportSell) {
        data = G6Router.parseReportSell(bs);
      } else if (op == ReportOpCode.reportSellFail) {
        data = G6Router.parseReportSellFail(bs);
      } else if (op == ReportOpCode.reportGraduate) {
        data = G6Router.parseReportGraduate(bs);
      } else {
        op = ReportOpCode.unknown;
      }
    }

    return [op, data];
  }

  static parseReportLaunch(bs: Slice) {
    let poolAddress = bs.loadAddress();
    let jettonMinterAddress = bs.loadAddress();
    let launcherAddress = bs.loadAddress();
    let kTonIn9 = bs.loadIntBig(32);
    let jettonContent = bs.loadRef();
    let jettonSupplementContent = bs.loadRef();
    let cs = bs.loadRef().beginParse();
    //
    let initLaunchJettonAmount = cs.loadCoins();
    let jettonAmountToReserve = cs.loadCoins();
    let jettonWalletPool = cs.loadAddress();
    //
    let queryId = bs.loadUintBig(64);

    if (bs.remainingBits !== 0 || bs.remainingRefs != 0) {
      console.log(`parseReportLaunch has error`);
    }
    if (cs.remainingBits !== 0 || cs.remainingRefs != 0) {
      console.log(`parseReportLaunch cs has error`);
    }

    let jettonInfo: null | ReturnType<typeof decodeJettonContent> = null;
    try {
      jettonInfo = decodeJettonContent(jettonContent);
    } catch (e) {
      console.log(`parseReportLaunch parse jetton metadata fails`);
    }
    let jettonSupplementInfo: null | ReturnType<typeof decodeJettonSupplementContent> = null;
    try {
      jettonSupplementInfo = decodeJettonSupplementContent(jettonSupplementContent);
    } catch (e) {
      console.log(`parseReportLaunch parse jetton supplement metadata fails`);
    }

    return {
      poolAddress,
      jettonMinterAddress,
      launcherAddress,
      kTonIn9,
      jettonContent,
      initLaunchJettonAmount,
      jettonAmountToReserve,
      jettonWalletPool,
      jettonInfo,
      jettonSupplementInfo,
      queryId,
    };
  }

  static parseReportBuy(bs: Slice) {
    let poolAddress = bs.loadAddress();
    let buyerAddress = bs.loadAddress();
    let jettonAmountToGain = bs.loadCoins();
    let tonAmountToPay = bs.loadCoins();
    let jettonBalance = bs.loadCoins();
    let tonBalance = bs.loadCoins();
    let jettonSoldable = bs.loadCoins();
    let jettonSold = bs.loadCoins();
    let queryId = bs.loadUintBig(64);

    if (bs.remainingBits !== 0 || bs.remainingRefs != 0) {
      console.log(`parseReportBuy has error`);
    }
    return {
      poolAddress,
      buyerAddress,
      jettonAmountToGain,
      tonAmountToPay,
      jettonBalance,
      tonBalance,
      jettonSoldable,
      jettonSold,
      queryId,
    };
  }

  static parseReportBuyFail(bs: Slice) {
    let poolAddress = bs.loadAddress();
    let buyerAddress = bs.loadAddress();
    let tonAmountToPay = bs.loadCoins();
    let expectedJettonSoldableAmount = bs.loadCoins();
    let slippageInPercent = bs.loadUintBig(8);
    let queryId = bs.loadUintBig(64);

    if (bs.remainingBits !== 0 || bs.remainingRefs != 0) {
      console.log(`parseReportBuy has error`);
    }
    return {
      poolAddress,
      buyerAddress,
      tonAmountToPay,
      expectedJettonSoldableAmount,
      slippageInPercent,
      queryId,
    };
  }

  static parseReportSell(bs: Slice) {
    let poolAddress = bs.loadAddress();
    let tonReceiverAddress = bs.loadAddress();
    let jettonAmountToPay = bs.loadCoins();
    let tonAmountToGain = bs.loadCoins();
    let jettonBalance = bs.loadCoins();
    let tonBalance = bs.loadCoins();
    let jettonSoldable = bs.loadCoins();
    let jettonSold = bs.loadCoins();
    let queryId = bs.loadUintBig(64);

    if (bs.remainingBits !== 0 || bs.remainingRefs != 0) {
      console.log(`parseReportSell has error`);
    }

    return {
      poolAddress,
      tonReceiverAddress,
      jettonAmountToPay,
      tonAmountToGain,
      jettonBalance,
      tonBalance,
      jettonSoldable,
      jettonSold,
      queryId,
    };
  }

  static parseReportSellFail(bs: Slice) {
    let poolAddress = bs.loadAddress();
    let tonReceiverAddress = bs.loadAddress();
    let jettonAmountToPay = bs.loadCoins();
    let expectedJettonSoldableAmount = bs.loadCoins();
    let slippageInPercent = bs.loadUintBig(8);
    let queryId = bs.loadUintBig(64);

    if (bs.remainingBits !== 0 || bs.remainingRefs != 0) {
      console.log(`parseReportSell has error`);
    }

    return {
      poolAddress,
      tonReceiverAddress,
      jettonAmountToPay,
      expectedJettonSoldableAmount,
      slippageInPercent,
      queryId,
    };
  }

  static parseReportGraduate(bs: Slice) {
    let poolAddress = bs.loadAddress();
    let queryId = bs.loadUintBig(64);

    if (bs.remainingBits !== 0 || bs.remainingRefs != 0) {
      console.log(`parseReportSell has error`);
    }
    return {
      poolAddress,
      queryId,
    };
  }
}
