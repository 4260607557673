import {Address, beginCell, Cell, Contract, ContractProvider, Sender, SendMode} from '@ton/core';

export const Opcodes = {
    op_buy: 0xAF750D34,
    op_sell: 0x742B36D8,
    op_add_liquidity_test: 0x4F396FE5
};

export class G6Pool implements Contract {
    constructor(readonly address: Address, readonly init?: { code: Cell; data: Cell }) {
    }

    static createFromAddress(address: Address) {
        return new G6Pool(address);
    }

    /*async sendDeploy(provider: ContractProvider, via: Sender, value: bigint) {
        await provider.internal(via, {
            value,
            sendMode: SendMode.PAY_GAS_SEPARATELY,
            body: beginCell().endCell(),
        });
    }*/

    async sendBuy(
        provider: ContractProvider,
        via: Sender,
        value: bigint,
        opts: {
            tonAmountToPay: bigint;
            tonReceiver: Address,//找零
            expectedJettonSoldableAmount: bigint,
            slippageInPercent: bigint,
            message?: string,
            queryId?:bigint,
        }
    ) {

        await provider.internal(via, {
            value: value,
            sendMode: SendMode.PAY_GAS_SEPARATELY,
            body: beginCell()
                .storeUint(Opcodes.op_buy, 32)
                .storeCoins(opts.tonAmountToPay)
                .storeAddress(opts.tonReceiver)
                .storeCoins(opts.expectedJettonSoldableAmount)
                .storeUint(opts.slippageInPercent, 8)
                .storeUint(opts.queryId??0n,64)
                .storeRef(beginCell().storeStringTail(opts.message ?? "").endCell())
                .endCell(),
        });
    }


    static sellTransferForwardMessage(
        opts: {
            tonReceiver: Address,
            jettonRefundReceiver: Address,
            expectedJettonSoldableAmount: bigint,
            slippageInPercent: bigint,
            message?: string,
        }
    ): Cell {
        return beginCell()
            .storeInt(Opcodes.op_sell, 32)
            .storeAddress(opts.tonReceiver)
            .storeAddress(opts.jettonRefundReceiver)
            .storeCoins(opts.expectedJettonSoldableAmount)
            .storeUint(opts.slippageInPercent, 8)
            .storeRef(beginCell().storeStringTail(opts.message ?? "").endCell())
            .endCell()
    }

    async getPoolData(provider: ContractProvider) {
        let res = await provider.get('get_pool_data', []);

        let jetton_minter_address = res.stack.readAddress();
        let jetton_balance = res.stack.readBigNumber();
        let ton_balance = res.stack.readBigNumber();
        let router_address = res.stack.readAddress();
        let jetton_wallet_code = res.stack.readCell();
        let jetton_minter_code = res.stack.readCell();
        let jetton_content = res.stack.readCell();
        let k_ton_in_9 = res.stack.readBigNumber();
        let init_launch_jetton_amount = res.stack.readBigNumber();
        let jetton_amount_to_reserve = res.stack.readBigNumber();
        let fee_collect_address = res.stack.readAddress();
        let ston_fi_router_address = res.stack.readAddress();
        let ston_fi_jetton_pton_address = res.stack.readAddress();
        let graduated = res.stack.readBigNumber();
        let launcherAddress = res.stack.readAddress();
        let jettonSupplementContent = res.stack.readCell();
        let initBuyDone = res.stack.readBigNumber();
        let jettonSoldable = res.stack.readBigNumber();
        let jettonSold = res.stack.readBigNumber();

        return {
            jetton_minter_address,
            jetton_balance,
            ton_balance,
            router_address,
            jetton_wallet_code,
            jetton_minter_code,
            jetton_content,
            k_ton_in_9,
            init_launch_jetton_amount,
            jetton_amount_to_reserve,
            fee_collect_address,
            ston_fi_router_address,
            ston_fi_jetton_pton_address,
            graduated,
            launcherAddress,
            jettonSupplementContent,
            initBuyDone,
            jettonSoldable,
            jettonSold,
        };
    }

    async getPoolData2(provider: ContractProvider) {
        let res = await provider.get('get_pool_data2', []);

        let freeze = res.stack.readBigNumber();
        let router_admin_address = res.stack.readAddress();

        return {
            freeze,
            router_admin_address,
        };
    }

    async getCalcBuyAmount(provider: ContractProvider, tonToPay: bigint) {
        let res = await provider.get('cal_buy_amount', [
            {type: 'int', value: tonToPay}
        ]);

        let max_jetton_to_gain = res.stack.readBigNumber();
        return {
            max_jetton_to_gain,
        };
    }

    async getCalcBuyAmountByJetton(provider: ContractProvider, jettonAmountToGain: bigint) {
        let res = await provider.get('cal_buy_amount_by_jetton', [
            {type: 'int', value: jettonAmountToGain}
        ]);

        let need_ton = res.stack.readBigNumber();
        return {
            need_ton,
        };
    }

    async getCalcSellAmount(provider: ContractProvider, jettonToPay: bigint) {
        let res = await provider.get('cal_sell_amount', [
            {type: 'int', value: jettonToPay}
        ]);

        let ton_to_gain = res.stack.readBigNumber();
        return {
            ton_to_gain,
        };
    }

    async getCalcSellAmountByTon(provider: ContractProvider, tonAmountToGain: bigint) {
        let res = await provider.get('cal_sell_amount_by_ton', [
            {type: 'int', value: tonAmountToGain}
        ]);

        let need_jetton = res.stack.readBigNumber();
        return {
            need_jetton,
        };
    }

    async getBuyTxFee(provider: ContractProvider, opts: { tonAmountToPay: bigint }) {
        let res = await provider.get('get_buy_tx_fee', [
            {type: 'int', value: opts.tonAmountToPay}
        ]);
        let fee = res.stack.readBigNumber();
        return {
            fee
        };
    }

    async getSellTxFee(provider: ContractProvider) {
        let res = await provider.get('get_sell_tx_fee', []);
        let sellTransferTxFee = res.stack.readBigNumber();
        let sellForwardTxFee = res.stack.readBigNumber();
        return {
            sellTransferTxFee,
            sellForwardTxFee
        };
    }

}
