import { CommentsList } from './components/CommentsList';
import { TabBar } from '@/components/Tabbar';
import { MarketCard } from './components/MarketCard';
import { CoinInfo } from './components/CoinInfo';
// import { Trade } from './components/Trade';
// import { CoinDetail } from './components/CoinDetail';
// import { CurveProgress } from './components/CurveProgress';
// import { HolderDistribution } from './components/HolderDistribution';
import { CoinDetailContext, useCoinDetailContext } from './context/CoinDetailContext';
import { TradesTable } from './components/TradeTable';
import { Loading } from '@/components/Loading';
import { Divider } from 'antd';
import { Tabs } from '@/components/Tabs';
import { useState, useMemo, useEffect } from 'react';
import { useWindowResize } from '@/hooks/useWindowResize';

export function Card({ children }: { children: any }) {
  return (
    <div className="flex flex-col w-full h-auto gap-4 mb-[20px]">
      <div className="flex flex-col w-full h-auto rounded-[18px] p-[18px]  border-2 border-white">{children}</div>
    </div>
  );
}

export default function () {
  const [tabItem, setTabItem] = useState('Info');

  const items: any = [
    {
      key: 'THREAD',
      label: 'THREAD',
      children: (
        <div className="mb-[18px]">
          <CommentsList />
        </div>
      ),
    },
    {
      key: 'TRADES',
      label: 'TRADES',
      children: (
        <div className="mt-[-15px]">
          <TradesTable />
        </div>
      ),
    },
  ];

  const DynamicComponent = useMemo(() => {
    switch (tabItem) {
      case 'Info':
        return <CoinInfo />;
      case 'Gragh':
        return <MarketCard />;
      case 'Thread':
        return <CommentsList />;
      case 'Trades':
        return <TradesTable />;
      default:
        return null;
    }
  }, [tabItem]);

  const tabsList = [
    {
      label: 'Info',
      key: 'Info',
    },
    {
      label: 'Gragh',
      key: 'Gragh',
    },
    {
      label: 'Thread',
      key: 'Thread',
    },
    {
      label: 'Trades',
      key: 'Trades',
    },
  ];

  const [tabs, setTabs] = useState([]);

  const tabsChange = (key: string) => {
    setTabItem(key);
  };

  const { width } = useWindowResize();

  const isMobile = useMemo(() => {
    return width <= 768;
  }, [width]);

  useEffect(() => {
    const tabsCopy = JSON.parse(JSON.stringify(tabsList));

    if (isMobile) {
      setTabs(tabsCopy);
      if (tabItem === 'Info') setTabItem('Info');
    } else {
      setTabs(tabsCopy);
      if (tabItem === 'Info') setTabItem('Info');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { detail, isGraduated, loading, refreshIsGraduated, graduatedLoading } = useCoinDetailContext();

  return (
    <CoinDetailContext.Provider value={{ detail, isGraduated, refreshIsGraduated, loading, graduatedLoading }}>
      <Loading loading={loading}>
        <div className="px-[90px] md:px-[12px] mt-[9px] md:hidden">
          <div className="flex flex-row w-full md:flex-wrap mb-8 gap-[20px]">
            <div className="flex-1 h-auto md:w-full flex flex-col gap-4">
              <div className="w-full">
                <MarketCard />
                <div className="w-full border-2 border-white rounded-[18px] mt-5 p-[18px] pb-[14x]">
                  <TabBar items={items} />
                </div>
              </div>
            </div>
            <CoinInfo />
            {/* <div className="w-[407px] shrink-0 md:w-full">
              <Card>
                <Trade />
              </Card>

              <Card>
                <CoinDetail />
              </Card>

              <div className="w-full mb-[20px] flex flex-col h-auto rounded-[18px] border-2 border-white">
                <CurveProgress />
              </div>

              <Card>
                <HolderDistribution />
              </Card>
            </div> */}
          </div>
        </div>

        <div className="w-[62%] mx-auto pb-7  md:w-auto">
          <div className="hidden md:block z-50 mt-[6px]">
            <div className="md:px-[12px]">{DynamicComponent}</div>
            <div className="w-auto fixed bottom-0  backdrop-blur-lg" style={{ width: '100vw', zIndex: 100 }}>
              <Divider style={{ borderTopColor: 'rgba(255, 255, 255, 0.1)', borderTopWidth: '2px', margin: '0' }} />
              <Tabs className="px-[12px]" activeKey={tabItem} items={tabs} onChange={tabsChange}></Tabs>
            </div>
          </div>
        </div>
      </Loading>
    </CoinDetailContext.Provider>
  );
}
