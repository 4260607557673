import { useFetchCoinDetail } from '@/views/Coin/hooks/useFetchCoinDetail';
import { Coin } from '@/types/Coin';
import { createContext, useMemo } from 'react';
import { useParseRouterParam } from '@/hooks/router/useParseRouterParam';
import { useRequest } from 'ahooks';
import { useG6PoolMethods } from '@/contract/hooks/useG6PoolMethods';
import { isUndefined } from 'lodash';

export const CoinDetailContext = createContext<{
  detail: Coin | undefined;
  isGraduated?: boolean;
  refreshIsGraduated?(): void;
  graduatedLoading: boolean;
  loading: boolean;
}>({
  detail: undefined,
  isGraduated: false,
  refreshIsGraduated: undefined,
  graduatedLoading: false,
  loading: false,
});

export const useCoinDetailContext = () => {
  const { param } = useParseRouterParam({
    address: '',
  });

  const { data, loading: detailLoading } = useFetchCoinDetail({ address: param.address });

  const { getPoolData } = useG6PoolMethods();

  const {
    data: isGraduated,
    loading: graduatedLoading,
    refresh: refreshIsGraduated,
  } = useRequest(
    async () => {
      const res = await getPoolData(param.address);

      return !!res?.graduated && !!param.address;
    },
    {
      ready: !!param.address,
    },
  );

  const loading = useMemo(() => {
    return detailLoading || (isUndefined(isGraduated) && graduatedLoading);
  }, [detailLoading, graduatedLoading, isGraduated]);

  return { detail: data?.data, isGraduated, loading, refreshIsGraduated, graduatedLoading };
};
