import request from '@/request';
import { ListQuery } from '@/types/App';
import { Coin } from '@/types/Coin';
import { MessageData } from '@/types/Message';

type ListPayload = ListQuery<{
  createdTimestampEnd?: number;
  createdTimestampStart?: number;
  nsfw?: boolean;
  search?: string;
  order?: string;
  sortBy?: string;
}>;

export function fetchCoinList({
  current,
  size,
  createdTimestampEnd,
  createdTimestampStart,
  nsfw,
  search,
  order,
  sortBy,
}: ListPayload) {
  return request.get<Ajax.ListResponse<Coin>>('/coin', {
    current,
    size,
    createdTimestampEnd,
    createdTimestampStart,
    nsfw,
    search,
    order,
    sortBy,
  });
}

export function fetchCoinDetail(poolAddress: string) {
  return request.get<Coin>(`/coin/${poolAddress}`);
}

export function fetchInitialBroadcast() {
  return request.get<MessageData[]>(`/coin/broadcast`);
}

export function fetchG6List(payload: ListPayload) {
  return request.get(`/coin/g6List`, payload);
}

export function fetchG6() {
  return request.get<Coin>(`/coin/g6`);
}
