import { useAccountMethods } from '../contract/hooks/useAccountMethods';
import { G6Math } from '../contract/utils/Math';
import { fetchTonPrice } from '../service/common';
import { checkProof, generatePayload } from '../service/proof';
import { useUserStore } from '../stores/useUserStore';
import { toNano } from '@ton/core';
import { Account, TonProofItemReplySuccess } from '@tonconnect/sdk';
import { useTonConnectUI } from '@tonconnect/ui-react';
import { useLocalStorageState, useRequest } from 'ahooks';
import { Modal } from 'antd';
import { createContext, useMemo } from 'react';
import { useUpdateUserChatId } from '../hooks/app/user/useUpdateUserChatId';
import { useAppMessage } from '../hooks/useAppMessage';
import { Temporary } from '../preferences/Temporary';

export const GlobalDataContext = createContext<{
  tonUsdPrice: number;
  walletReady?: boolean;
  balance?: bigint;
  isBalanceLoading: boolean;
  refreshProof?: () => void;
  setSlippage?: (val: string) => void;
  refreshBalanceAsync?(): Promise<bigint>;
  slippage: string;
  slippageValue: string;
}>({
  tonUsdPrice: 0,
  balance: 0n,
  walletReady: false,
  isBalanceLoading: false,
  slippage: '1',
  slippageValue: '0.01',
});

export const useGlobalDataContext = () => {
  const { data: tonUsdPrice = 0 } = useRequest(async () => {
    try {
      const { data } = await fetchTonPrice();

      return data.markets.find((item) => item.market === 'OKX')!.usd_price;
    } catch {
      return 0;
    }
  }, {});

  const [tonConnectUI] = useTonConnectUI();

  const message = useAppMessage();

  const userStore = useUserStore();

  const { runAsync: runUpdate } = useUpdateUserChatId({});

  const { run: handleCheckProof } = useRequest(
    async (proof: TonProofItemReplySuccess['proof'], account: Account) => {
      const reqBody = {
        address: account.address,
        network: account.chain,
        proof: {
          ...proof,
          state_init: account.walletStateInit,
        },
      };

      const response = await checkProof(reqBody);

      return response;
    },
    {
      manual: true,
      onSuccess(data) {
        userStore.setToken(data.data.token);

        if (Temporary.getChatId()) {
          runUpdate(Temporary.getChatId()!);
        }
      },
      onError(err) {
        console.log('checkProof error', err);

        tonConnectUI.connector.disconnect();

        message.error('Failed to check proof');
      },
    },
  );

  const { loading: ready, refresh: refreshProof } = useRequest(
    () => {
      return generatePayload();
    },
    {
      onBefore() {
        tonConnectUI.setConnectRequestParameters({
          state: 'loading',
        });

        tonConnectUI.connector.onStatusChange((wallet) => {
          if (!wallet) {
            return;
          }

          const tonProof = wallet.connectItems?.tonProof;

          if (tonProof) {
            if ('proof' in tonProof) {
              handleCheckProof(tonProof.proof, wallet.account);

              return;
            }
          }

          if (!userStore.token) {
            Modal.info({
              zIndex: 10000,
              centered: true,
              content: 'Token not found please reconnect',
              className: 'error-modal',
              icon: null,
              async onOk() {
                tonConnectUI.connector.disconnect();

                useUserStore.getState().loginOut();

                refreshProof?.();
              },
            });
          }
        });
      },
      onSuccess(data) {
        tonConnectUI.setConnectRequestParameters({
          state: 'ready',
          value: {
            tonProof: data.data.payload,
          },
        });
      },
      onError(err) {
        console.log('err', err);
        message.error(err.message || 'Failed to generate payload');
      },
      ready: !userStore.token,
      refreshDeps: [userStore.token],
    },
  );

  const { getBalance, ready: accountReady } = useAccountMethods();

  const {
    data: balance = toNano(0),
    loading: balanceLoading,
    refreshAsync: refreshBalanceAsync,
  } = useRequest(
    async () => {
      return getBalance().then((balance) => {
        return balance ? balance : toNano(0);
      });
    },
    {
      ready: accountReady,
    },
  );

  const [slippage, setSlippage] = useLocalStorageState('SA_SLIPPAGE', {
    defaultValue: '1',
  });

  const slippageValue = useMemo(() => {
    try {
      return new G6Math(slippage!).divide(100).toString();
    } catch (e) {
      return '0.01';
    }
  }, [slippage]);

  return {
    tonUsdPrice,
    walletReady: ready,
    isBalanceLoading: balanceLoading,
    balance,
    refreshProof,
    refreshBalanceAsync,
    slippage: slippage!,
    setSlippage,
    slippageValue,
  };
};
