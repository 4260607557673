import request from '@/request';
import { ListQuery } from '@/types/App';
// import { Trade } from '@/types/Trade';

export type TradeHistoryItem = {
  _id: string;
  user: string;
  username: string;
  address: string;
  tonAmount: number;
  jettonAmount: number;
  isBuy: boolean;
  timestamp: number;
  hash: string;
};

export type ApiTXResult = { status: 'success' | 'failed' | 'not_found' };

export function fetchCoinTrade(poolAddress: string, { current, size }: ListQuery) {
  return request.get<any>(`/trade/${poolAddress}`, { current, size });
}

export function fetchTXResult(queryId: string, type: 'buy' | 'sell') {
  return request.get<ApiTXResult>(`/sync/result/${queryId}`, { type });
}
