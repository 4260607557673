import React, { useState, useMemo } from 'react';
import { ConfigProvider, List, Pagination, PaginationProps, Skeleton } from 'antd';
// import { ReplyModel } from './ReplyModel';
import { useFetchComments } from '../hooks/useFetchComments';
import { Reply } from '@/types/Reply';
import { ReplyInput } from './ReplyInput';

import { useParseRouterParam } from '@/hooks/router/useParseRouterParam';
import { useCoinDetailContext } from '../context/CoinDetailContext';
import { CommentDetail } from '@/components/Comments/CommentDetail';
import { formatDate } from '@/utils/format';
import { useParseRouterQuery } from '@/hooks/router/useParseRouterQuery';
import { useWindowResize } from '@/hooks/useWindowResize';

export function CommentsList() {
  const { param } = useParseRouterParam({ address: '' });

  const { query, updateQuery } = useParseRouterQuery({
    mintId: param.address,
    current: '1',
  });

  const { data, loading, refresh } = useFetchComments({ mintId: query.mintId, current: +query.current });

  const onChange: PaginationProps['onChange'] = (page) => {
    updateQuery({ ...query, current: page.toString() });
  };

  const { detail } = useCoinDetailContext();

  const [size, setSize] = useState('small');

  const [replyValue, setReplyValue] = useState('');

  const changeSize = () => {
    const newSize = size === 'small' ? 'large' : 'small';
    setSize(newSize);
  };

  const getDefaultReply = (id: string) => {
    setReplyValue(id);
  };

  // const showPagination = useMemo(() => {
  //   try {
  //     return data!.data!.docs?.totalPages >= 2;
  //   } catch (err) {
  //     return false;
  //   }
  // }, [data]);

  const { width } = useWindowResize();

  const isMobile = useMemo(() => {
    return width <= 768;
  }, [width]);

  return (
    <Skeleton active loading={loading}>
      <div>
        <div className="flex text-[14px] text-white items-center">
          <div className="mr-[3px] leading-[17.5px]">{detail?.creatorUsername || '-'}</div>
          <div className="mr-1.5 text-[12px] rounded bg-black py-0.5 px-[5px]">Dev</div>
          <div className="opacity-50 leading-[17.5px]">{detail?.createdAt ? formatDate(detail.createdAt) : ''}</div>
        </div>
        <div
          className={`flex justify-start relative items-start gap-3 text-slate-300 overflow-auto mt-[9px] ${size == 'large' ? '' : 'flex'} pb-[15px] border-b-2 border-[#ffffff10]`}
        >
          <img
            src={detail?.imageUri}
            className={`${size == 'large' ? 'w-full' : 'w-[100px]'} max-h-[100px] max-w-[100px] object-contain cursor-pointer`}
            onClick={() => {
              changeSize();
            }}
          />
          <div
            style={{
              wordWrap: 'break-word',
              width: isMobile ? 'calc(100vw - 140px)' : 'auto',
              minWidth: isMobile ? 'auto' : '130px',
            }}
          >
            <div className="text-xl text-white break-words leading-[18px] flex justify-start items-center">
              <span className="text-[18px] font-bold font-[ClashDisplay-Semibold] block mr-[5px]">{detail?.name}</span>
              <span className="text-[14px] opacity-50 ml-3 block md:ml-0 md:mt-[3px]">ticker: {detail?.symbol}</span>
            </div>
            <div className="text-white mt-[9px] text-[16px] leading-[19.2px] md:mt-[6px] whitespace-pre-wrap break-all">
              {detail?.description}
            </div>
          </div>
        </div>
        <List
          loading={loading}
          dataSource={data?.data.docs}
          loadMore={
            <div className="flex justify-center items-center mt-[30px]">
              <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: '#000',
                    colorTextSecondary: '#fff',
                    colorTextDisabled: 'rgba(255,255,255, .6)',
                  },
                }}
              >
                <Pagination
                  hideOnSinglePage={true}
                  current={data?.data.page}
                  total={data?.data.totalDocs}
                  pageSize={data?.data.limit}
                  onChange={onChange}
                />
              </ConfigProvider>
            </div>
          }
          renderItem={(item: Reply) => {
            return (
              <List.Item className="w-full h-full !py-[10px] !border-b-2 !border-[#ffffff10] last:!border-b-0">
                {/* <Item item={item} refresh={refresh} getDefaultReply={getDefaultReply} /> */}
                <CommentDetail item={item} refresh={refresh} getDefaultReply={getDefaultReply} />
              </List.Item>
            );
          }}
        ></List>

        <ReplyInput
          mintId={param.address}
          defaultReply={replyValue}
          onReplay={() => {
            refresh();
          }}
        ></ReplyInput>
      </div>
    </Skeleton>
  );
}
