import { Segmented, ConfigProvider } from 'antd';

export function ButtonGroup({ onValueChange }: { onValueChange: any }) {
  const handleChange = (val: any) => {
    onValueChange(val);
  };

  return (
    <ConfigProvider
      wave={{ disabled: true }}
      theme={{
        components: {
          Segmented: {
            itemActiveBg: '#fff',
            itemColor: '#000',
            itemHoverBg: '#fff',
            itemHoverColor: '#000',
            itemSelectedBg: '#000',
            itemSelectedColor: '#fff',
            controlHeight: 42,
            borderRadius: 12,
            borderRadiusSM: 12,
            trackPadding: 0,
            fontSize: 15,
            fontWeightStrong: 600,
            fontFamily: 'ClashDisplay-Semibold',
          },
        },
      }}
    >
      <Segmented
        className="font-[ClashDisplay-Semibold] font-semibold"
        block
        options={['BUY', 'SELL']}
        onChange={(value) => {
          handleChange(value);
        }}
      />
    </ConfigProvider>
  );
}
