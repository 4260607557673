import { fetchCoinTrade } from '@/service/trade';
import { useRequest } from 'ahooks';

export function useFetchCoinTrade({ address }: { address: string }) {
  const hook = useRequest(() => {
    return fetchCoinTrade(address, {
      current: 1,
      size: 60,
    });
  }, {});

  return hook;
}
