import { follow } from '@/service/follows';
import { useRequest } from 'ahooks';

//关注用户
export function useFollowUser(
  address: string,
  {
    onSuccess,
  }: {
    onSuccess?: () => void;
  } = {},
) {
  return useRequest(
    async () => {
      const dto = { address: address };

      const res = await follow(dto);

      return res.data;
    },
    {
      manual: true,
      onSuccess() {
        onSuccess?.();
      },
    },
  );
}
