import { useParseRouterParam } from '@/hooks/router/useParseRouterParam';
// import { useFetchCoinDetail } from '@/views/Coin/hooks/useFetchCoinDetail';
import { SendTransactionRequest } from '@tonconnect/sdk';
import { useTonConnectUI } from '@tonconnect/ui-react';
import { Address, beginCell, ContractProvider, fromNano, toNano } from '@ton/core';
import { getBuyParam } from '../action/buy';
import { getSellParam } from '../action/sell';
// import { getPoolAddress } from '../info/poolAddress';
import { getBlockchainClient } from '../utils/client';
import { getG6Pool, getJettonWallet } from '../instance';
import { G6Math } from '../utils/Math';
import { useContext, useMemo } from 'react';
import { CoinDetailContext } from '@/views/Coin/context/CoinDetailContext';
import { v4 as uuidV4 } from 'uuid';
import TonWeb from 'tonweb';
import { getRandomBigInt } from '@/utils';

export enum InputTokenType {
  ByTon = 'ByTon',
  ByJetton = 'ByJetton',
}

function getRandomValues() {
  const randomValues = new Uint8Array(16);

  for (let i = 0; i < randomValues.length; i++) {
    randomValues[i] = Math.floor(Math.random() * 256);
  }
  return Array.from(randomValues);
}

function getQueryId() {
  return uuidV4({
    random: getRandomValues(),
  });
}

// function toMessageString(msg: Record<string, any>) {
//   return TonWeb.utils.bytesToBase64(TonWeb.utils.stringToBytes(JSON.stringify(msg)));
// }

export const useG6PoolMethods = () => {
  const [tonConnectUI] = useTonConnectUI();

  const { param } = useParseRouterParam({ address: '' });

  // const {} = useFetchCoinDetail({ address: param.address });

  const { detail: data } = useContext(CoinDetailContext);

  const poolAddress = useMemo(() => {
    try {
      return Address.parse(data!.poolAddress);
    } catch (e) {
      return null;
    }
  }, [data]);

  const checkTonConnect = () => {
    if (!tonConnectUI.connected) {
      console.error('not connected');

      return false;
    }

    if (!data) {
      console.error('data is null');

      return false;
    }

    return true;
  };

  const fetchPoolAddress = async () => {
    if (!checkTonConnect()) {
      return;
    }
    // const payload = {
    //   name: data?.data.name,
    //   symbol: data?.data.symbol,
    //   description: data?.data.description,
    // };

    if (!poolAddress) {
      console.error('poolAddress is null');

      return;
    }

    return Address.parse(poolAddress.toString());
  };

  //
  const handleBuy = async (payload: { tonAmountToPay: bigint; buyType: InputTokenType; poolAddress: string }) => {
    if (!checkTonConnect()) {
      return;
    }
    const poolAddress = Address.parse(payload.poolAddress);

    try {
      const BlockchainClient = await getBlockchainClient();
      // use any to avoid type error, the type ContractProvider here is different from the wrapper
      const provider = BlockchainClient.provider(poolAddress, null) as any;

      // console.log('buyTxFee:', buyTxFeeResult.fee);

      // const lastBuyTxFeeResult = await getG6Pool(poolAddress).getLastBuyTxFee(provider);
      // console.log('lastBuyTxFee:', lastBuyTxFeeResult.fee);

      const g6pool = getG6Pool(poolAddress);

      const poolInfoResult = await g6pool.getPoolData(provider);

      // const jettonSoldableBalance = poolInfoResult.jettonSoldable;

      let payAmount = payload.tonAmountToPay;

      if (payload.buyType === InputTokenType.ByJetton) {
        const getCalcBuyAmountByJettonResult = await getG6Pool(poolAddress).getCalcBuyAmountByJetton(
          provider,
          payload.tonAmountToPay,
        );

        payAmount = getCalcBuyAmountByJettonResult.need_ton;
      }

      const buyTxFeeResult = await getG6Pool(poolAddress).getBuyTxFee(provider, {
        tonAmountToPay: payAmount,
      });

      const param = {
        tonAmountToPay: payAmount,
        tonReceiver: Address.parse(tonConnectUI.account!.address),
        expectedJettonSoldableAmount: poolInfoResult.jettonSoldable,
        slippageInPercent: 49n,
      };

      const queryId = getRandomBigInt();

      const { body: buyBody } = getBuyParam({
        ...param,
        queryId,
      });

      const transaction: SendTransactionRequest = {
        messages: [
          {
            address: poolAddress!.toString(), // destination address
            amount: buyTxFeeResult.fee.toString(), //Toncoin in nanotons
            payload: buyBody.toBoc().toString('base64'),
          },
        ],
        validUntil: Math.floor(Date.now() / 1000) + 360,
      };

      const buyResult = await tonConnectUI.sendTransaction(transaction);

      console.log('buyResult:', buyResult);

      return {
        ...buyResult,
        queryId,
      };
    } catch (error) {
      console.error('error:', error);

      return Promise.reject(error);
    }
  };

  const handleSell = async (jettonAmount: bigint, sellType: InputTokenType) => {
    if (!checkTonConnect()) {
      return;
    }
    const poolAddress = await fetchPoolAddress();
    if (!poolAddress) {
      console.error('poolAddress is null');

      return;
    }

    try {
      const BlockchainClient = await getBlockchainClient();
      // use any to avoid type error, the type ContractProvider here is different from the wrapper
      const provider = BlockchainClient.provider(poolAddress, null) as any;
      // poolData ==> jettonMinterAddress ==> jettonWalletAddress
      const poolData = await BlockchainClient.runMethod(poolAddress, 'get_pool_data');

      const jettonMinterAddress = poolData.stack.readAddress();
      console.log('jettonMinterAddress:', jettonMinterAddress.toString());

      const jettonWalletAddressResult = await BlockchainClient.runMethod(jettonMinterAddress, 'get_wallet_address', [
        {
          type: 'slice',
          cell: beginCell().storeAddress(Address.parse(tonConnectUI.account!.address)).endCell() as any,
        },
      ]);

      const jettonWalletAddress = jettonWalletAddressResult.stack.readAddress();
      console.log('jettonWalletAddress:', jettonWalletAddress.toString());

      const g6pool = getG6Pool(poolAddress);

      const sellTxFeeResult = await g6pool.getSellTxFee(provider);

      const poolInfoResult = await g6pool.getPoolData(provider);

      let jettonAmountToSell = jettonAmount;
      if (sellType === InputTokenType.ByTon) {
        const getCalcSellAmountByTonResult = await getG6Pool(poolAddress).getCalcSellAmountByTon(
          provider,
          jettonAmount,
        );
        jettonAmountToSell = getCalcSellAmountByTonResult.need_jetton;
      }

      const param = {
        jettonAmount,
        poolAddress,
        tonReceiver: Address.parse(tonConnectUI.account!.address),
        jettonSoldable: poolInfoResult.jettonSoldable,
        sellForwardTxFee: sellTxFeeResult.sellForwardTxFee,
      };

      const queryId = getRandomBigInt();

      const { body: sellBody } = getSellParam({
        ...param,
        queryId,
      });

      const transaction: SendTransactionRequest = {
        messages: [
          {
            address: jettonWalletAddress.toString(), // destination address
            amount: (sellTxFeeResult.sellTransferTxFee + toNano(0.1)).toString(), //Toncoin in nanotons, add 0.1 to prevent fail
            payload: sellBody.toBoc().toString('base64'),
          },
        ],
        validUntil: Math.floor(Date.now() / 1000) + 360,
      };

      const sellResult = await tonConnectUI.sendTransaction(transaction);
      console.log('sellResult:', sellResult);

      return { ...sellResult, queryId };
    } catch (error) {
      console.error('error:', error);

      return Promise.reject(error);
    }
  };

  const getPoolData = async (poolAddress: string) => {
    if (!poolAddress) {
      console.error('poolAddress is null');

      return;
    }

    const pool = Address.parse(poolAddress);

    const BlockchainClient = await getBlockchainClient();
    // use any to avoid type error, the type ContractProvider here is different from the wrapper
    const provider = BlockchainClient.provider(pool, null) as any;
    const poolData = await getG6Pool(pool).getPoolData(provider);

    return poolData;
  };

  // TODO
  const getJettonBalance = async (pool: string, useAddress?: string) => {
    if (!pool && !useAddress) {
      return Promise.reject('pool and useAddress is null');
    }

    const poolAddress = Address.parse(pool);

    try {
      const BlockchainClient = await getBlockchainClient();
      // use any to avoid type error, the type ContractProvider here is different from the wrapper
      const provider = BlockchainClient.provider(poolAddress, null) as any;
      const poolData = await getG6Pool(poolAddress).getPoolData(provider);
      const jettonMinterAddress = poolData.jetton_minter_address;
      const jettonWalletAddressResult = await BlockchainClient.runMethod(jettonMinterAddress, 'get_wallet_address', [
        {
          type: 'slice',
          cell: beginCell()
            .storeAddress(Address.parse(useAddress || tonConnectUI.account!.address))
            .endCell() as any,
        },
      ]);
      const jettonWalletAddress = jettonWalletAddressResult.stack.readAddress();
      console.log('jettonWalletAddress:', jettonWalletAddress.toString());
      const jettonBalance = await getJettonWallet(jettonWalletAddress).getJettonBalance(
        BlockchainClient.provider(jettonWalletAddress, null) as any,
      );
      console.log('jettonBalance:', jettonBalance);
      return jettonBalance;
    } catch (error) {
      console.error('error:', error);
      return Promise.reject(error);
    }
  };

  const getPoolProgress = async (pool: string) => {
    const poolAddress = Address.parse(pool);

    try {
      const BlockchainClient = await getBlockchainClient();
      // use any to avoid type error, the type ContractProvider here is different from the wrapper
      const provider = BlockchainClient.provider(poolAddress, null) as any;
      const poolData = await getG6Pool(poolAddress).getPoolData(provider);

      const totalToSellAmunt = poolData.init_launch_jetton_amount - poolData.jetton_amount_to_reserve;
      const soldAmount = poolData.init_launch_jetton_amount - poolData.jetton_balance;

      return new G6Math(BigInt(soldAmount)).divide(totalToSellAmunt).toNumber();
    } catch (error) {
      console.error('error:', error);

      return Promise.reject(error);
    }
  };

  const getTonNeededForBuyingByJetton = async (amount: bigint) => {
    if (!checkTonConnect()) {
      return;
    }

    if (!poolAddress) {
      console.error('poolAddress is null');

      return;
    }

    try {
      const BlockchainClient = await getBlockchainClient();
      // use any to avoid type error, the type ContractProvider here is different from the wrapper
      const provider = BlockchainClient.provider(poolAddress, null) as any;
      const getCalcBuyAmountByJettonResult = await getG6Pool(poolAddress).getCalcBuyAmountByJetton(provider, amount);
      return getCalcBuyAmountByJettonResult.need_ton;
    } catch (error) {
      console.error('error:', error);
      return Promise.reject(error);
    }
  };

  const getJettonNeededForSellingByTon = async (amount: bigint) => {
    if (!checkTonConnect()) {
      return;
    }
    if (!poolAddress) {
      console.error('poolAddress is null');

      return;
    }

    try {
      const BlockchainClient = await getBlockchainClient();
      // use any to avoid type error, the type ContractProvider here is different from the wrapper
      const provider = BlockchainClient.provider(poolAddress, null) as any;
      const getCalcSellAmountByTonResult = await getG6Pool(poolAddress).getCalcSellAmountByTon(provider, amount);
      return getCalcSellAmountByTonResult.need_jetton;
    } catch (error) {
      console.error('error:', error);
      return Promise.reject(error);
    }
  };

  const getSoldableJetton = async () => {
    if (!checkTonConnect()) {
      return;
    }

    if (!poolAddress) {
      console.error('poolAddress is null');

      return;
    }

    try {
      const BlockchainClient = await getBlockchainClient();
      // use any to avoid type error, the type ContractProvider here is different from the wrapper
      const provider = BlockchainClient.provider(poolAddress, null) as any;
      const poolInfoResult = await getG6Pool(poolAddress).getPoolData(provider);
      return poolInfoResult.jettonSoldable;
    } catch (error) {
      console.error('error:', error);

      return Promise.reject(error);
    }
  };

  return {
    handleBuy,
    handleSell,
    getJettonNeededForSellingByTon,
    getJettonBalance,
    getTonNeededForBuyingByJetton,
    getPoolProgress,
    getSoldableJetton,
    getPoolData,
  };
};
