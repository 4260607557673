import { fetchComments } from '@/service/comments';
import { useRequest } from 'ahooks';

export function useFetchComments({ mintId, current }: { mintId?: string; current: number }) {
  const hook = useRequest(
    () => {
      return fetchComments({
        current,
        size: 5,
        mintId,
      });
    },
    {
      ready: !!mintId,
      refreshDeps: [current, mintId],
    },
  );

  return hook;
}
