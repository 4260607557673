import { List } from 'antd';
import { useMemo } from 'react';
import { Item } from '@/components/CoinCard';
import { useFilter } from '@/hooks/useFilter';
import { useFetchG6List } from '../hooks/useFetchG6List';
import { uniqBy } from 'lodash';

export function CoinList() {
  const { query } = useFilter();

  const { data, loading } = useFetchG6List({
    createdTimestampStart: Number(query.createdTimestampStart),
    search: query.search,
    order: query.order,
    sortBy: query.sortBy,
    size: +query.size,
  });

  const list = useMemo(() => {
    const val = uniqBy([...(data?.data?.docs ? data?.data?.docs || [] : data?.data || [])], 'poolAddress');

    return val;
  }, [data]);

  return (
    <div className="px-[90px] md:px-[12px]">
      <List
        className="coins"
        loading={loading}
        dataSource={list}
        grid={{
          gutter: 20,
          xs: 2,
          sm: 2,
          md: 2,
          lg: 3,
          xl: 4,
          xxl: 4,
        }}
        renderItem={(item) => {
          return (
            <List.Item className="h-full">
              <Item item={item} isG6 isImportant />
            </List.Item>
          );
        }}
      ></List>
    </div>
  );
}
