import { Tabs } from '@/components/Tabs';
import { useState, useEffect, useMemo } from 'react';
import { AccountInfo } from './components/AccountInfo';
import { CoinsHeld } from './components/CoinsHeld';
import { Replies } from './components/Replies';
// import { Notifications } from './components/Notifications';
import { CoinsCreated } from './components/CoinsCreated';
import { Followers } from './components/Followers';
import { Following } from './components/Following';
import React from 'react';
import { useWindowResize } from '@/hooks/useWindowResize';
import { useParseRouterParam } from '@/hooks/router/useParseRouterParam';
import { toInternalAddress, useWallet } from '@/hooks/useWallet';

function Card({ children }: { children?: React.ReactNode }) {
  return (
    <div className="rounded-[18px] border-2 border-solid border-white h-full px-[18px] md:p-[18px] mb-6">
      {children}
    </div>
  );
}

export default function () {
  const { internalAddress } = useWallet();

  const { param } = useParseRouterParam({ address: '' });

  const tabsList = useMemo(() => {
    const list = [
      {
        label: 'COINS HELD',
        key: 'coinsHeld',
      },
      {
        label: 'COINS CREATED',
        key: 'coinsCreated',
      },
      {
        label: 'FOLLOWERS',
        key: 'followers',
      },
      {
        label: 'FOLLOWING',
        key: 'following',
      },
    ];

    // 根据条件添加 "REPLIES" 标签
    if (internalAddress === toInternalAddress(param.address)) {
      list.splice(1, 0, {
        label: 'REPLIES',
        key: 'replies',
      });
      // 添加 "NOTIFICATIONS" 标签
      // list.splice(2, 0, {
      //   label: 'NOTIFICATIONS',
      //   key: 'notifications',
      // });
    }

    return list;
  }, [internalAddress, param.address]);

  const [tabs, setTabs] = useState([]);

  const [tabItem, setTabItem] = useState('coinsHeld');

  const tabsChange = (key: string) => {
    setTabItem(key);
  };

  const { width } = useWindowResize();

  const isMobile = useMemo(() => {
    return width <= 767;
  }, [width]);

  useEffect(() => {
    const tabsCopy = JSON.parse(JSON.stringify(tabsList));
    if (isMobile) {
      if (tabsCopy.findIndex((e: { key: string }) => e.key === 'info') === -1) {
        tabsCopy.unshift({ label: 'INFO', key: 'info' });
        setTabs(tabsCopy);
        if (tabItem !== 'info') setTabItem('info');
      }
    } else {
      setTabs(tabsCopy);
      if (tabItem !== 'coinsHeld') setTabItem('coinsHeld');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);

  const DynamicComponent = useMemo(() => {
    const address = toInternalAddress(param.address);

    switch (tabItem) {
      case 'info':
        return <AccountInfo />;
      case 'coinsHeld':
        return <CoinsHeld address={address} />;
      case 'replies':
        return <Replies />;
      // case 'notifications':
      //   return <Notifications />;
      case 'coinsCreated':
        return <CoinsCreated address={address} />;
      case 'followers':
        return <Followers address={address} />;
      case 'following':
        return <Following address={address} />;
      default:
        return null;
    }
  }, [param?.address, tabItem]);

  return (
    <div className="w-[62%] mt-[6px] mx-auto pb-7 md:px-[12px] md:w-auto">
      <div className="block md:hidden">
        <AccountInfo></AccountInfo>
        <Card>
          <Tabs
            isMobile={isMobile}
            className="w-full px-0 profileTabs"
            activeKey={tabItem}
            items={tabs}
            onChange={tabsChange}
          ></Tabs>
          {DynamicComponent}
        </Card>
        <div className="text-[16px] opacity-50 mt-7">©2024 G6</div>
      </div>
      <div className="hidden md:block">
        {DynamicComponent}
        <div className="x-[12px] w-auto fixed bottom-0" style={{ width: 'calc(100vw - 24px)', zIndex: 100 }}>
          <Tabs
            isMobile={isMobile}
            activeKey={tabItem}
            items={tabs}
            className="profileTabs"
            onChange={tabsChange}
          ></Tabs>
        </div>
      </div>
    </div>
  );
}
