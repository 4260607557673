import { CloseOutlined } from '@ant-design/icons';
import { ConfigProvider, Modal as AntModal, ModalProps } from 'antd';
import { GreyButton } from '../GreyButton';

export function Modal({ open, closable = true, ...props }: ModalProps) {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorText: '#000000',
          borderRadius: 21,
          paddingLG: 18,
        },
      }}
    >
      <AntModal
        {...props}
        title={null}
        open={open}
        closable={false}
        classNames={{
          mask: 'backdrop-blur-sm',
        }}
        className={'antModel'}
        maskClosable={false}
      >
        <div className="flex justify-between items-center w-full">
          {props.title && (
            <div className="font-ClashDisplay-Semibold text-[36px] leading-[36px] font-semibold text-black">
              {props.title}
            </div>
          )}
          {closable && (
            <GreyButton className="ml-auto md:h-9 md:!w-9" icon={<CloseOutlined />} onClick={props.onCancel} />
          )}
        </div>
        {props.children}
      </AntModal>
    </ConfigProvider>
  );
}
