import { useWebSocket } from 'ahooks';

export function useWebsocket({
  onMessage,
  onError,
  onClose,
  onOpen,
}: {
  onMessage?: (event: WebSocketEventMap['message'], instance: WebSocket) => void;
  onError?: (event: WebSocketEventMap['error'], instance: WebSocket) => void;
  onClose?: (event: WebSocketEventMap['close'], instance: WebSocket) => void;
  onOpen?: (event: WebSocketEventMap['open'], instance: WebSocket) => void;
}) {
  const websocket = useWebSocket(process.env.REACT_APP_SOCKET_URL!, {
    onMessage(event, instance) {
      onMessage?.(event, instance);
    },
    onError(event, instance) {
      onError?.(event, instance);
    },
    onClose(event, instance) {
      onClose?.(event, instance);
    },
    onOpen(event, instance) {
      onOpen?.(event, instance);
    },
    reconnectInterval: 3 * 1000,
  });

  return {
    websocket,
  };
}
