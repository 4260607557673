import ReactDOM from 'react-dom/client';
import App from './App';
import '@/assets/css/main.css';
import preferences from './preferences';
import { storageKeys } from './constants/storage';

function preprocessing() {
  if (process.env.REACT_APP_VERSION !== preferences.get(storageKeys.version)) {
    localStorage.clear();
    preferences.set(storageKeys.version, process.env.REACT_APP_VERSION);
  }

  console.log('version', process.env.REACT_APP_VERSION);
}

function bootstrap() {
  preprocessing();

  const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

  root.render(<App />);
}

bootstrap();
