import { displayAddress } from '@/utils/ton';
import { List } from 'antd';
import { useFetchFollowers } from '../hooks/useFetchFellowers';

type Follower = {
  _id: string;
  user_address: string;
  follows_count: number;
};

export function Followers({ address }: { address: string }) {
  const { data, loading } = useFetchFollowers(address);

  return (
    <List
      loading={loading}
      itemLayout="horizontal"
      dataSource={data?.data || []}
      renderItem={(item: Follower) => (
        <List.Item className="h-full" style={{ borderBottomColor: 'rgba(225,225,225,0.1)', borderBottomWidth: '2px' }}>
          <List.Item.Meta
            title={
              <div className="flex items-start justify-between text-white">
                <div className="text-[16px] font-semibold leading-6">{displayAddress(item.user_address)}</div>
                <span key="list-followers" className="text-[16px] opacity-50 ml-2.5">
                  {item.follows_count} followers
                </span>
              </div>
            }
          />
        </List.Item>
      )}
    />
  );
}
