import { fetchFollowing } from '@/service/follows';
import { useRequest } from 'ahooks';

export function useFetchFollowing(address: any) {
  const hook = useRequest(
    async () => {
      const res = await fetchFollowing(address);

      return res.data;
    },
    {
      ready: !!address,
      refreshDeps: [address],
    },
  );

  return hook;
}
