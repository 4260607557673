import { List, Avatar } from 'antd';
import { useMemo } from 'react';
import { useWindowResize } from '@/hooks/useWindowResize';
import { useFetchUserCreateCoins } from '../hooks/useFetchUserCreateCoins';
import { Coin } from '@/types/Coin';
import { useAppRouter } from '@/hooks/router/useAppRouter';
import dayjs from 'dayjs';

export function CreatedItem({ item, isMobile }: { item: Coin; isMobile: boolean }) {
  const { goCoinDetail } = useAppRouter();

  const handleSeeThreadClick = () => {
    goCoinDetail({ poolAddress: item.poolAddress });
  };

  function formatTime(time: number) {
    return dayjs.unix(time).format('YYYY-MM-DD HH:mm:ss');
  }

  return (
    <List.Item className="h-full" style={{ borderBottomColor: 'rgba(225,225,225,0.1)', borderBottomWidth: '2px' }}>
      <List.Item.Meta
        avatar={
          <Avatar size={isMobile ? 36 : 48} style={{ marginRight: isMobile ? '-6px' : '' }} src={item.imageUri} />
        }
        title={
          <div className="flex items-start justify-between">
            <div className="text-[14px] leading-6 text-white">
              <span>{item.creatorUsername}</span>
              <span className="bg-black inline-block rounded py-0.5 px-1.5 mx-1 text-[12px]">Dev</span>
              <span className="opacity-50">{formatTime(item.createdTimestamp)}</span>
            </div>
            <span
              key="list-seeThread"
              className="text-[16px] font-semibold opacity-50 ml-2.5"
              onClick={handleSeeThreadClick}
            >
              See thread
            </span>
          </div>
        }
        description={
          <div className="flex mb-1 flex-start">
            <div className="ml-3 text-white">
              <div className="flex md:block">
                <div className="text-[18px] font-[ClashDisplay-Semibold]">{item.name}</div>
                <div className="text-[14px] opacity-50 ml-3 md:ml-0">Ticker: {item.symbol}</div>
              </div>
              <span className="text-[16px] leading-6 mt-1.5">{item.description}</span>
            </div>
          </div>
        }
      />
    </List.Item>
  );
}

export function CoinsCreated({ address }: { address: string }) {
  const { width } = useWindowResize();

  const { data, loading } = useFetchUserCreateCoins(address);

  const isMobile = useMemo(() => {
    return width <= 768;
  }, [width]);

  return (
    <List
      loading={loading}
      itemLayout="horizontal"
      dataSource={data?.data.docs}
      renderItem={(item) => <CreatedItem item={item} isMobile={isMobile} />}
    />
  );
}
