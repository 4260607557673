import { User } from '@/types/User';
import { useRequest } from 'ahooks';
import { useAppMessage } from '../../useAppMessage';
import { updateInfo } from '@/service/user';

export function useUpdateUserInfo({ onUpdateSuccess }: { onUpdateSuccess?: (data: User) => void }) {
  const message = useAppMessage();

  const { run, runAsync, loading } = useRequest(
    (data: Partial<User>) => {
      return updateInfo(data);
    },
    {
      manual: true,
      onSuccess(data) {
        onUpdateSuccess?.(data.data);
      },
      onError(e) {
        console.log('error', e);

        message.error?.(e.message || 'Failed to save');
      },
    },
  );

  return {
    run,
    runAsync,
    loading,
  };
}
