import { fetchCoinList } from '@/service/coin';
import { useInfiniteScroll } from 'ahooks';
import { Data } from 'ahooks/lib/useInfiniteScroll/types';

export function useFetchCoinList({
  createdTimestampEnd,
  createdTimestampStart,
  nsfw,
  search,
  order,
  sortBy,
  size,
}: {
  createdTimestampEnd?: number;
  createdTimestampStart?: number;
  nsfw?: boolean;
  search?: string;
  order?: string;
  sortBy?: string;
  size: number;
}) {
  const hook = useInfiniteScroll(
    async (param?: Data & { page: number }) => {
      const data = await fetchCoinList({
        current: (param?.page || 0) + 1,
        size,
        createdTimestampEnd,
        createdTimestampStart,
        nsfw,
        search,
        order,
        sortBy,
      });

      return {
        list: data.data.docs,
        total: data.data.totalDocs,
        page: data.data.page,
        hasNextPage: data.data.hasNextPage,
        totalPages: data.data.totalPages,
      };
    },
    {
      reloadDeps: [createdTimestampEnd, createdTimestampStart, nsfw, search, order, sortBy, size],
    },
  );

  return hook;
}
