import request from '@/request';

export function generatePayload() {
  return request.post<{ payload: string }>(`/user/ton-proof/generate-payload`, null);
}

export function checkProof(payload: any) {

  console.log('checkProof-payload', {
    proof: payload,
    domain: window.location.host,
  });

  return request.post<{ token: string }>(`/user/ton-proof/check-proof`, {
    proof: payload,
    domain: window.location.host,
  });
}
