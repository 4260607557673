import { getHttpEndpoint } from '@orbs-network/ton-access';
import { TonClient } from '@/packages/ton-client/client/TonClient';
// import { TonClient } from '@/packages/ton/src';

export const BlockchainClient = new TonClient({
  // endpoint: 'https://main.ton.dev',
  endpoint: 'https://toncenter.com/api/v2/jsonRPC',
  // endpoint: 'https://go.getblock.io/0a8b86322a594f689333cf848ccacad9',
  // endpoint: 'https://ton.nownodes.io',
  // endpoint: 'https://main.ton.dev',
});

let client: TonClient | null = null;

export async function getTonClient() {
  const endpoint = await getHttpEndpoint();
  // const endpoint = 'https://toncenter.com/api/v2/jsonRPC';
  return new TonClient({ endpoint });
}

export const getBlockchainClient = async () => {
  if (client) {
    return client;
  }
  const endpoint = await getHttpEndpoint();
  // const endpoint = 'https://toncenter.com/api/v2/jsonRPC';
  return new TonClient({ endpoint ,});
};
