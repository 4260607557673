import { Tabs, ConfigProvider } from 'antd';

export function TabBar(props: any) {
  const { items } = props;

  const onChange = (key: string) => {
    console.log(key);
  };

  return (
    <ConfigProvider
      wave={{ disabled: true }}
      theme={{
        components: {
          Tabs: {
            cardBg: '#00000000',
            colorPrimaryBorder: 'ffffff00',
            inkBarColor: '#ffffff',
            itemActiveColor: '#fff',
            itemColor: '#ffffff50',
            itemHoverColor: '#fff',
            itemSelectedColor: '#fff',
            titleFontSize: 15,
            lineHeight: 1,
            cardGutter: 18,
            colorBorder: '#ffffff00',
            lineWidth: 0,
            horizontalItemPadding: '0 0 5px 0',
            horizontalItemGutter: 18,
            horizontalMargin: '0 0 24px 0',
          },
        },
      }}
    >
      <Tabs
        defaultActiveKey="1"
        items={[...items]}
        onChange={onChange}
        tabBarStyle={{ borderBottom: 'unset', fontFamily: 'ClashDisplay-Semibold' }}
      />
    </ConfigProvider>
  );
}
