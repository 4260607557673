import { assign } from 'lodash';
import { useParams } from 'react-router-dom';

export function useParseRouterParam<T extends Record<string, string>>(payload: T) {
  const param = useParams() as T;

  return {
    param: assign({}, payload, param),
  };
}
