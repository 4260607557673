import { getRandomBigInt } from '@/utils';
import { Address, beginCell } from '@ton/core';
import { Opcodes } from '../wrappers/G6Pool';

type Props = {
  // tonToPay: bigint;
  // jettonSoldableBalance: bigint;
  // slippageInPercent: bigint;
  // txFee: bigint;

  // tonReceiver: Address;
  // message?: string;

  tonAmountToPay: bigint;
  tonReceiver: Address;
  expectedJettonSoldableAmount: bigint;
  slippageInPercent: bigint;
  message?: string;
  queryId: bigint;
};

export function getBuyParam(props: Props) {
  const body = beginCell()
    .storeUint(Opcodes.op_buy, 32)
    .storeCoins(props.tonAmountToPay)
    .storeAddress(props.tonReceiver)
    .storeCoins(props.expectedJettonSoldableAmount)
    .storeUint(props.slippageInPercent, 8)
    .storeUint(props.queryId ?? 0n, 64)
    .storeRef(
      beginCell()
        .storeStringTail(props.message ?? '')
        .endCell(),
    )
    .endCell();

  return { body };
}
