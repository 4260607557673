import { Address, beginCell, toNano } from '@ton/core';
import { encodeJettonContent } from '../scripts/jettonContentEncode';
import { encodeJettonSupplementContent } from '../scripts/jettonSupplementContentEncode';
import { Opcodes } from '../wrappers/G6Router';

type LaunchProps = {
  name: string;
  symbol: string;
  description: string;
  image: string;
  twitter?: string;
  telegram?: string;
  website?: string;
  decimals?: string;
} & {
  init_launch_jetton_amount?: bigint;
  k_ton_in_9?: bigint;
  jetton_amount_to_reserve?: bigint;
  ton_to_pay_for_init_buy?: bigint;
  k_ton_in_denominator?: bigint;
  query_id: bigint;
  // jetton_content: Cell;
  // jetton_supplement_content: Cell;
};

export function getLaunchParam(props: LaunchProps) {
  const { name, symbol, description, image, twitter, telegram, website, decimals } = props;

  let jettonContent = encodeJettonContent({
    name,
    symbol,
    description,
    image,
    decimals,
  });

  let jettonSupplementContent = encodeJettonSupplementContent({
    twitter: twitter || '',
    telegram: telegram || '',
    website: website || '',
  });

  const body = beginCell()
  .storeUint(Opcodes.op_launch, 32)
  .storeRef(jettonContent)
  .storeInt(props.k_ton_in_9 ?? 15000n, 32)
  .storeInt(props.k_ton_in_denominator ?? 27, 8)
  .storeCoins(props.init_launch_jetton_amount ?? toNano(1_000_000_000n))
  .storeCoins(props.jetton_amount_to_reserve ?? toNano(1_000_000_000n) / 3n)
  .storeRef(jettonSupplementContent)
  .storeCoins(props.ton_to_pay_for_init_buy ?? 0n)
  .storeUint(props.query_id ?? 0n, 64)
  .endCell()

  return { body };
}
