import vectorDownIcon from '@/assets/icon/icon_vector_down.svg';
import vectorUpIcon from '@/assets/icon/icon_vector_up.svg';
import vectorDownPurpleIcon from '@/assets/icon/icon_vector_down_purple.svg';
import vectorUpPurpleIcon from '@/assets/icon/icon_vector_up_purple.svg';
import { Popover } from 'antd';
import classNames from 'classnames';
import { useResponsive } from '@/hooks/useResponsive';
import { useState } from 'react';
import { eventBus } from '@/utils/Bus';

interface Item {
  name: string;
  icon?: string;
  checked?: boolean;
}

interface ObjectWithArray {
  [key: string]: Item[];
}

export function PopoverMenu({ className }: { className?: string }) {
  const { isMobile } = useResponsive();

  const objWithArray: ObjectWithArray = {
    // Filter: [
    //   { name: 'Overall' },
    //   { name: 'Top 10 MCAP' },
    //   { name: 'Top 20 MCAP' },
    //   { name: 'Top 10 reply' },
    //   { name: 'Top 20 reply' },
    //   { name: 'Last 24 hours' },
    // ],
    Sort: [
      { name: 'Bump order', icon: 'down' },
      // { name: 'Bump order', icon: 'up' },
      { name: 'MCAP', icon: 'down' },
      // { name: 'MCAP', icon: 'up' },
      { name: 'Newest' },
      { name: 'Reply count', icon: 'down' },
      { name: 'Last reply' },
      // { name: 'Oldest' },
    ],
  };

  const [currentFilterIndex, setCurrentFilterIndex] = useState(`Filter-0`);

  const [currentSortIndex, setCurrentSortIndex] = useState(`Sort-0`);

  const handleClick = (item: object, index: number, sectionName: string) => {
    eventBus.emit('handleClickMenu', { item, sectionName });
    if (sectionName === 'Sort') setCurrentSortIndex(`${sectionName}-${index}`);
    else if (sectionName === 'Filter') setCurrentFilterIndex(`${sectionName}-${index}`);
  };

  const popoverList = (
    <div className="flex mx-[-6px] py-[6px]">
      {Object.entries(objWithArray).map(([sectionName, items]) => (
        <div key={sectionName}>
          <ul>
            {items.map((item, i) => (
              <li
                key={i}
                className="flex text-black text-[18px] cursor-pointer font-[ClashDisplay-Semibold] px-[15px] py-[12px] rounded-xl hover:bg-[#EBEBEB] items-center"
                style={
                  currentFilterIndex === sectionName + '-' + i || currentSortIndex === sectionName + '-' + i
                    ? { color: '#2106D1' }
                    : {}
                }
                onClick={() => {
                  handleClick(item, i, sectionName);
                }}
              >
                {item.name}
                {item.icon ? (
                  <img
                    src={
                      item.icon === 'down'
                        ? currentFilterIndex === sectionName + '-' + i || currentSortIndex === sectionName + '-' + i
                          ? vectorDownPurpleIcon
                          : vectorDownIcon
                        : currentFilterIndex === sectionName + '-' + i || currentSortIndex === sectionName + '-' + i
                          ? vectorUpPurpleIcon
                          : vectorUpIcon
                    }
                    className={'text-black ml-[6px] w-3 h-3'}
                  />
                ) : (
                  ''
                )}
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );

  return (
    <Popover
      content={popoverList}
      className="!py-[0px]"
      overlayInnerStyle={{ paddingTop: 0, paddingBottom: 0 }}
      trigger="click"
      arrow={!isMobile}
      // overlayClassName={isMobile ? 'popover-offset' : ''}
    >
      <div className={classNames(className)}>
        <div
          className={`text-[18px] cursor-pointer underline-offset-[12px] decoration-[3px] font-[ClashDisplay-Semibold] lg:text-[15px] lg:underline-offset-[6px]`}
        >
          FILTERS
        </div>
      </div>
    </Popover>
  );
}
