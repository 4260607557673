import { CurveProgress } from './CurveProgress';
import { Trade } from './Trade';
import { CoinDetail } from './CoinDetail';
import { HolderDistribution } from './HolderDistribution';

export function Card({ children }: { children: any }) {
  return (
    <div className="flex flex-col w-full h-auto gap-4 mb-[20px]">
      <div className="flex flex-col w-full h-auto rounded-[18px] p-[18px] border-2 border-white">{children}</div>
    </div>
  );
}

export function CoinInfo() {
  return (
    <div className="w-[407px] shrink-0 md:w-full">
      <Card>
        <Trade />
      </Card>

      <Card>
        <CoinDetail />
      </Card>

      <div className="w-full mb-[20px] flex flex-col h-auto rounded-[18px] border-2 border-white">
        <CurveProgress />
      </div>

      <Card>
        <HolderDistribution />
      </Card>
    </div>
  );
}
