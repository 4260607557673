import { tonApi } from '@/service/tonApi';
import { useRequest } from 'ahooks';

export function useFetchHolderDistribution({ address }: { address?: string }) {
  const hook = useRequest(
    () => {
      return tonApi.jettons.getJettonHolders(address!, {
        offset: 0,
        limit: 20,
      });
    },
    {
      ready: !!address,
    },
  );

  return hook;
}
