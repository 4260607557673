import { eventBus } from '@/utils/Bus';
import { useEffect } from 'react';
import { useParseRouterQuery } from './router/useParseRouterQuery';

export function useFilter() {
  const { query, updateQuery } = useParseRouterQuery({
    createdTimestampStart: '',
    search: '',
    order: '',
    sortBy: '',
    size: '60',
  });

  useEffect(() => {
    eventBus.on('handleClickMenu', (data) => {
      const { item } = data;

      const queryData = {
        createdTimestampStart: '0',
        sortBy: '',
        order: '',
        size: '60',
      };

      switch (item.name) {
        case 'Overall':
          queryData.sortBy = '';
          updateQuery({ ...queryData });
          break;
        case 'Top 10 MCAP':
          queryData.order = 'desc';
          queryData.sortBy = 'marketCap';
          queryData.size = '10';
          updateQuery({ ...queryData });
          break;
        case 'Top 20 MCAP':
          queryData.order = 'desc';
          queryData.sortBy = 'marketCap';
          queryData.size = '20';
          updateQuery({ ...queryData });
          break;
        case 'Top 10 reply':
          queryData.order = 'desc';
          queryData.sortBy = 'replyCount';
          queryData.size = '10';
          updateQuery({ ...queryData });
          break;
        case 'Top 20 reply':
          queryData.order = 'desc';
          queryData.sortBy = 'replyCount';
          queryData.size = '20';
          updateQuery({ ...queryData });
          break;
        case 'MCAP':
          queryData.sortBy = 'marketCap';
          queryData.size = '60';
          if (item.icon == 'up') {
            queryData.order = 'esc';
          } else {
            queryData.order = 'desc';
          }
          updateQuery({ ...queryData });
          break;
        case 'Reply count':
          queryData.sortBy = 'replyCount';
          queryData.size = '60';
          queryData.order = 'desc';
          updateQuery({ ...queryData });
          break;
        case 'Last reply':
          queryData.sortBy = 'lastReply';
          queryData.size = '60';
          updateQuery({ ...queryData });
          break;

        case 'Bump order':
          queryData.sortBy = 'lastTradeTimestamp';
          queryData.size = '60';
          if (item.icon == 'up') {
            queryData.order = 'esc';
            updateQuery({ ...queryData });
          } else {
            queryData.order = 'desc';
            updateQuery({ ...queryData });
          }
          break;
        case 'Newest':
          queryData.sortBy = 'createdTimestamp';
          queryData.size = '60';
          queryData.order = 'desc';
          updateQuery({ ...queryData });
          break;
        case 'Oldest':
          queryData.sortBy = 'createdTimestamp';
          queryData.size = '60';
          queryData.order = 'esc';
          updateQuery({ ...queryData });
          break;
        case 'Last 24 hours':
          queryData.createdTimestampStart = Date.now().toString();
          queryData.size = '60';
          updateQuery({ ...queryData });
          break;
        default:
          return null;
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    query,
  };
}
