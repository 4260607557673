import { Progress, ConfigProvider } from 'antd';
import { useG6PoolMethods } from '@/contract/hooks/useG6PoolMethods';
import { useRequest } from 'ahooks';
import { useParseRouterParam } from '@/hooks/router/useParseRouterParam';

export function CurveProgress() {
  const { getPoolProgress } = useG6PoolMethods();

  const { param } = useParseRouterParam({ address: '' });

  const { data: progress = 0 } = useRequest(
    async () => {
      return getPoolProgress(param.address).then((data) => {
        return data ? +data.toFixed(2) : 0;
        // return data ? +data.toFixed(4) : 0;
      });
    },
    {
      throttleWait: 1000,
      pollingInterval: 3000,
    },
  );

  return (
    <ConfigProvider
      wave={{ disabled: true }}
      theme={{
        components: {
          Progress: {
            circleTextColor: '#fff',
            defaultColor: '#000',
            lineBorderRadius: 18,
            remainingColor: '#ffffff00',
            fontSize: 16,
          },
        },
      }}
    >
      <div className="w-full relative h-[48px]">
        <div className="w-full h-full absolute flex justify-between z-10 pl-[16px] pr-[18px] content-center items-center ">
          <div className="text-base font-semibold">Bonding curve progress</div>
          <div className="text-[15px] font-ClashDisplay-Semibold">{progress * 100 + '%'}</div>
        </div>

        <Progress
          percent={progress * 100}
          percentPosition={{ align: 'start', type: 'inner' }}
          size={['100%', 48]}
          showInfo={false}
          strokeColor="#000"
        />
      </div>
    </ConfigProvider>
  );
}
