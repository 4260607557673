import classNames from 'classnames';
import { range } from 'lodash';
import { useEffect, useMemo, useRef } from 'react';
import { Typography } from 'antd';
import { useAppRouter } from '@/hooks/router/useAppRouter';
import { CheckCircleFilled } from '@ant-design/icons';
import { useContext } from 'react';
import { getDisplayName } from '@/utils';
import { G6Image } from '@/components/Image';
import { G6Math } from '../../contract/utils/Math';
import { Coin } from '@/types/Coin';
import { GlobalDataContext } from '@/context/GlobalDataContext';
import G6Icon from '../../assets/icon/g6_icon.svg';
import ShareIcon from '../../assets/icon/share.svg';

type MarqueeProps = {
  text: string;
  speed?: number;
};

const Marquee: React.FC<MarqueeProps> = ({ text, speed = 10 }) => {
  const marqueeInnerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (marqueeInnerRef.current) {
      const marqueeInnerWidth = marqueeInnerRef.current.scrollWidth;
      marqueeInnerRef.current.style.animationDuration = `${marqueeInnerWidth / speed}s`;
    }
  }, [text, speed]);

  return (
    <div className="marquee">
      <div ref={marqueeInnerRef} className="marquee-inner">
        {range(0, 10).map((item) => {
          return <span key={item}>{text}</span>;
        })}
      </div>
    </div>
  );
};

export function CoinCard({
  children,
  isG6,
  isImportant,
}: {
  children?: React.ReactNode;
  isG6?: boolean;
  isImportant?: boolean;
}) {
  if (isG6) {
    return <G6Card isImportant={isImportant}>{children}</G6Card>;
  }

  return <DefaultCard>{children}</DefaultCard>;
}

function DefaultCard({ children }: { children?: React.ReactNode }) {
  return (
    <div
      className={classNames(
        'rounded-[27px] border border-solid  h-full hover:bg-[rgba(255,255,255,0.1)] transition-all duration-200 ease-in-out overflow-hidden border-white md:rounded-[15px]',
      )}
    >
      {children}
    </div>
  );
}

function G6Card({ children, isImportant }: { children?: React.ReactNode; isImportant?: boolean }) {
  const border = 'border border-solid  border-[#FFA420]';

  return (
    <div className={classNames('rounded-[27px] h-full overflow-hidden md:rounded-[15px]', !isImportant && border)}>
      {isImportant && (
        <div
          className={classNames(
            'bg-[#FFA420] font-ClashDisplay-Semibold gap-x-[10px] z-10 h-[27px] flex justify-start items-center overflow-hidden',
          )}
        >
          <Marquee text="G6 LIST" speed={100} />
        </div>
      )}

      <div
        style={{
          height: isImportant ? 'calc(100% - 27px)' : '100%',
        }}
        className={classNames(
          'rounded-[27px] hover:bg-[rgba(255,255,255,0.1)] transition-all duration-200 ease-in-out md:rounded-[15px]',
          isImportant && 'outline outline-[30px] outline-[#FFA420]',
          isImportant && border,
        )}
      >
        {children}
      </div>
    </div>
  );
}

const { Paragraph } = Typography;

function Field({ label, value, className }: { label: string; value: any; className?: string }) {
  return (
    <div className={classNames(className)}>
      <div className="text-[16px] leading-[16px] md:text-[14px] md:leading-[14px]">{value}</div>
      <div className="text-[16px] leading-[16px] mt-[3px] opacity-50 md:text-[12px] md:leading-3 md:mb-[3px] md:mt-0">
        {label}
      </div>
    </div>
  );
}

export function MarketCap({ tonAmount, unit }: { tonAmount?: string | number; unit?: string }) {
  const { tonUsdPrice } = useContext(GlobalDataContext);

  const val = useMemo(() => {
    try {
      const value = new G6Math(tonAmount || 0).multiply(tonUsdPrice).toString();

      if (!+value) {
        return '-';
      }

      return `${unit || ''}${(+value).toFixed(2)}`;
    } catch (err) {
      console.log('err', err);

      return '-';
    }
  }, [tonAmount, tonUsdPrice, unit]);

  return <span>{val}</span>;
}

export function Item({ item, isG6, isImportant }: { item: Coin; isG6?: boolean; isImportant?: boolean }) {
  const { goCoinDetail } = useAppRouter();

  return (
    <CoinCard isG6={isG6 || item.isG6} isImportant={item.isImportant || isImportant}>
      <div
        className="flex items-between cursor-pointer flex-col p-[18px] pb-[19px] h-full md:p-3 w-full"
        onClick={() => {
          goCoinDetail({ poolAddress: item.poolAddress });
        }}
      >
        <div className="flex justify-start items-center gap-x-[9px] w-full">
          {item.isImportant && <img src={G6Icon} className="w-[40px] h-[40px] shrink-0" />}

          <div
            className={classNames('w-full', {
              '!w-[calc(100%_-_40px)]': item.isImportant,
            })}
          >
            <div className="text-[18px] leading-[18px] font-[ClashDisplay-Semibold] md:text-[15px]">
              {item.complete && <CheckCircleFilled />} {item.name}
            </div>
            <div className="w-full flex justify-between items-center text-[16px] leading-[16px] mt-[3px] opacity-50 md:text-[12px]">
              <span>{item.symbol}</span>

              <div className="flex justify-end items-center gap-x-[3px]">
                <img src={ShareIcon} className="w-[15px] h-[12px]" /> {item.replyCount || 0}
              </div>
            </div>
          </div>
        </div>

        <div className="mx-[3px] mt-[15px] mb-[14px] md:mx-0 md:mt-[9px]">
          <G6Image className="mx-auto h-[258px] object-contain md:h-[180px] sm:h-[145px]" src={item.imageUri}></G6Image>
        </div>

        <div className="flex flex-col max-h-[150px] md:max-h-[auto]">
          <div className="w-full flex justify-between items-start flex-wrap">
            <Field
              className="md:w-full md:flex md:flex-col-reverse md:mb-[9px]"
              label="Market Cap"
              value={<MarketCap unit="$" tonAmount={item?.marketCap} key={item?.marketCap} />}
            ></Field>
            <Field
              className="md:w-full md:flex md:flex-col-reverse"
              label="Created by"
              value={getDisplayName(item.creatorUsername || item.creator) || '-'}
            ></Field>
          </div>

          <div className="mt-[18px] md:mt-[12px]">
            <Paragraph
              ellipsis={{ rows: 3, expandable: false }}
              className="!mb-[0px] text-[16px] leading-[19.2px] md:text-[12px] md:leading-[14.4px]"
            >
              {item.description}
            </Paragraph>
          </div>
        </div>
      </div>
    </CoinCard>
  );
}
