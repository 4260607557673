import { tonClient } from '@/utils/tonClient';
import { DEX } from '@ston-fi/sdk';

export function useStonFiRouter() {
  const router = tonClient.open(new DEX.v1.Router());

  return {
    router,
  };
}
