import { toInternalAddress } from '@/hooks/useWallet';
import { fetchUserInfoByAddress } from '@/service/user';
import { useRequest } from 'ahooks';

export function useFetchUserInfoByAddress(address: any) {
  const hook = useRequest(
    () => {
      return fetchUserInfoByAddress(toInternalAddress(address));
    },
    {
      ready: !!address,
    },
  );

  return hook;
}
