import { Api, HttpClient } from 'tonapi-sdk-js';

const httpClient = new HttpClient({
  baseUrl: 'https://tonapi.io',
  baseApiParams: {
    headers: {
      Authorization: `Bearer AECQZ3NQWMBYGKYAAAAHEHJ6ARFZXICWMD3IRAV7UGOU2PDPSSFXRYOSPRI3O4Z3IRGX5YQ`,
      'Content-type': 'application/json',
    },
  },
});

export const tonApi = new Api(httpClient);
