
export const defaultConfig: any = {
  supported_resolutions: ['1', '5', '15', '30', '60', '240', 'D', 'W', 'M'] as any[],

  // supported_resolutions: ['1', '5', 'D'] as ResolutionString[],
};

export type SymbolConf = {
  symbol: string;
  symbolName: string;
  description: string;
  fullName: string;
  onReady?: (callback: any) => void;
};

export const getSymbolConfig = (payload: SymbolConf) => {
  const conf: any = {
    ticker: payload.symbol,
    name: payload.symbolName,
    full_name: payload.fullName || payload.symbol,
    description: payload.description,
    timezone: 'Etc/UTC',
    session: '24x7',
    minmov: 1,
    has_intraday: true,
    supported_resolutions: defaultConfig.supported_resolutions,
    intraday_multipliers: defaultConfig.supported_resolutions,
    type: '',
    listed_exchange: '',
    pricescale: 100,
    exchange: '',
    has_daily: true,
    format: 'price',
  };

  return conf;
};
