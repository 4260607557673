import { Button as AntButton, ConfigProvider, ButtonProps } from 'antd';

export function GreyButton(props: ButtonProps) {
  return (
    <ConfigProvider
      wave={{ disabled: true }}
      theme={{
        components: {
          Button: {
            colorPrimaryActive: '#C7C7C7',
            colorPrimary: '#EBEBEB',
            colorPrimaryHover: '#000',
            primaryColor: '#000',
            colorPrimaryTextHover: '#EBEBEB',
            colorTextLightSolid: '#EBEBEB',

            controlHeight: 48,
            borderRadius: 30,
            fontSize: 15,
            fontWeight: 600,
          },
        },
      }}
    >
      <AntButton {...props} type="primary"></AntButton>
    </ConfigProvider>
  );
}
