import { displayAddress } from '@/utils/ton';
import { G6Math } from '@/contract/utils/Math';
import { Address } from '@ton/core';
import { Skeleton } from 'antd';
import { sum } from 'lodash';
import { useCallback, useContext } from 'react';
import { CoinDetailContext } from '../context/CoinDetailContext';
import { useFetchHolderDistribution } from '../hooks/useFetchHolderDistribution';

export function HolderDistribution() {
  const context = useContext(CoinDetailContext);

  const { data, loading } = useFetchHolderDistribution({ address: context.detail?.address });

  const percentage = useCallback(
    (value: string) => {
      const list = data?.addresses.map((acc) => {
        return BigInt(acc.balance);
      });

      return (new G6Math(value).divide(sum(list)).toNumber() * 100).toFixed(2);
    },
    [data?.addresses],
  );

  function isBondingCurve(address: string) {
    try {
      return Address.parse(context.detail!.poolAddress).equals(Address.parse(address));
    } catch (e) {
      return false;
    }
  }

  function isDev(address: string) {
    try {
      return Address.parse(context.detail!.creator).equals(Address.parse(address));
    } catch (e) {
      return false;
    }
  }

  return (
    <Skeleton active loading={context.loading || loading}>
      <div>
        <div className="text-[16px] leading-4 mb-[15px] font-semibold">Holder distribution</div>
        {data?.addresses.map((item, i) => {
          return (
            <div key={item.address} className="flex justify-between items-start">
              <div className={`leading-[16.8px] ${i !== data?.addresses.length - 1 ? 'mb-[9px]' : ''}`}>
                <div className="flex justify-start items-center">
                  <div className="text-[#918C90] min-w-[30px]">{i + 1}.</div>

                  {displayAddress(item.owner.address)}

                  {isBondingCurve(item.owner.address) && (
                    <span className="ml-[10px] text-[#918C90]">Bonding curve</span>
                  )}

                  {isDev(item.owner.address) && <span className="ml-[10px] text-[#918C90]">Dev</span>}
                </div>
              </div>
              <div>{percentage(item.balance)}%</div>
            </div>
          );
        })}
      </div>
    </Skeleton>
  );
}
